import React from "react";
import Button from "@mui/material/Button";
import Row from "../layout/Row";
import Icon1 from "../icons/Icon";
import { useTheme } from "@mui/material/styles"; // Import useThem
import Space from "../layout/Space";

function NavBarButton({ icon, children, onClick, sx, className, actualpage }) {
  const [hover, setHover] = React.useState(false);
  const theme = useTheme(); // Access the theme
  const isActualPage = actualpage === icon;

  return (
    <Button
      variant="contained"
      color={hover ? "black" : theme.palette.shadow._50} // Fill changes on hover
      className={className}
      sx={{
        textTransform: "none",
        width: "100%",
        padding: "3px 0px 3px 0px",
        margin: "0px",
        border: "0px solid",
        borderRadius: "0px",
        fontWeight: 500,
        lineHeight: 1,
        boxShadow: "0 8px 16px rgba(0,0,0,0)", // Slight shadow on hover
        backgroundColor: hover ? theme.palette.shadow._50 : "white", // Use sx for dynamic background color
        color: isActualPage || hover ? "black" : theme.palette.shadow._50,
        "&:hover": {
          boxShadow: "0 8px 16px rgba(0,0,0,0)", // Slight shadow on hover
          backgroundColor: hover ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0)", // Change background on hover
          color: isActualPage || hover ? "black" : theme.palette.shadow._50,
        },
        ...sx,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <Row align="left" x={0}>
        <Icon1
          icon_name={icon}
          fill={isActualPage || hover ? "black" : theme.palette.shadow._50} // Fill changes on hover
        />
        <Space x={15} />
        <div className="text-h3">{children}</div>
      </Row>
    </Button>
  );
}

export default NavBarButton;
