import React from "react";
import TextField from "@mui/material/TextField";

function MLTF1({ label, sx, value, onChange, rows = 4 }) {
  return (
    <TextField
      value={value}
      onChange={onChange}
      label={label}
      variant="outlined"
      padding="0px"
      color="primary"
      fullWidth // Makes the TextField take all available width
      multiline // Enables multiline text field
      rows={rows} // Sets the minimum number of rows to display
      sx={{
        width: "100%", // Make it full width by default
        "& .MuiInputLabel-root": {
          fontSize: "14px", // Adjust label size
        },
        "& .MuiInputLabel-shrink": {
          fontSize: "14px", // Adjust the label size when it shrinks
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px", // Rounded corners
        },
        "& .MuiOutlinedInput-input": {
          padding: "0px", // Adjust input padding for multiline
          fontSize: "14px", // Text size
          height: "auto", // Allow for multiline text input
        },
        ...sx, // Custom styles passed via the `sx` prop
      }}
      InputProps={{
        style: {
          fontSize: "14px", // Input text font size
        },
      }}
    />
  );
}

export default MLTF1;
