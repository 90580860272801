import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // If modal is not open, return nothing

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the modal if clicked on the backdrop
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 cursor-default"
      onClick={handleBackdropClick}
    >
      <div
        className="bg-transparent rounded-lg flex-col shadow-lg w-fit p-0 flex align-middle items-center relative cursor-auto"
        onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing it
      >
        <div className=" items-center flex">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
