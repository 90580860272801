import { getAuth } from "firebase/auth";

// Utility function to retrieve the user's token
export const getFirebaseToken = async () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Check if the user is authenticated
  if (!currentUser) {
    throw new Error("User not authenticated");
  }

  // Retrieve the token
  const token = await currentUser.getIdToken(/* forceRefresh */ false);
  return token;
};

// Utility function to force refresh the user's token
export const refreshFirebaseToken = async () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Check if the user is authenticated
  if (!currentUser) {
    throw new Error("User not authenticated");
  }

  // Force refresh the token
  const refreshedToken = await currentUser.getIdToken(true);
  return refreshedToken;
};

// Combined function to handle token retrieval and refreshing if necessary
export const getOrRefreshFirebaseToken = async () => {
  try {
    // Try to get the token without forcing refresh
    return await getFirebaseToken();
  } catch (error) {
    console.warn("Token retrieval failed, refreshing token...");

    // If token retrieval fails, refresh the token
    return await refreshFirebaseToken();
  }
};
