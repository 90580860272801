import React, { useEffect, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth.js";
import { useNavigate, useParams } from "react-router-dom";
import { getUserById } from "../models/UserFunctions.js";
import {
  getOrgByRef,
  getExpertsByOrgRef,
  queryArticlesByOrgRef,
} from "../models/OrgFunctions.js";
import { transformArticlesToTable } from "../models/ArticleFunctions.js";
import Column from "../layout/Column.js";
import Row from "../layout/Row.js";
import Divider from "../layout/Divider.js";
import Icon1 from "../icons/Icon.js";
import Space from "../layout/Space.js";
import MemberManager from "../members/MemberManager.js";
import InfoCell from "../listviews/InfoCell.js";
import MLTF1 from "../textfields/MLTF1.js";
import SecondaryButton from "../buttons/SecondaryButton.js";
import LineChart1 from "../charts/LineChart1.js";
import PieChart1 from "../charts/PieChart1.js";
import PrimaryButton from "../buttons/PrimaryButton.js";
import ExpertsAM from "../actionmenus/ExpertsAM.js";
import { Skeleton } from "@mui/material";
import {
  createDoc,
  getDocDataById,
  getDocDataByRef,
  updateDocDataField,
} from "../models/DataFunctions.js";
import { sendSmsVerificationCall } from "../config/ApiCalls.js";
import { gatherDeviceInfo } from "../config/GatheringInfos.js";
import { useSnackbar } from "notistack";
import TextField2 from "../textfields/TextField2.js";
import { VerifySmsCodeCall } from "../config/ApiCalls.js";
import PDFSign from "../pdf/PDFSign.js";
import { useAppContext } from "../config/AppContext.js";
import getTemporaryUrl from "../api/getTemporaryUrl.js";
import PDF from "../pdf/PDF.js";
import Modal from "../modals/Modal.js";
import { set } from "date-fns";
import sendTamponDevis from "../api/sign/sendTamponDevis.js";
import signDevis from "../api/sign/signDevis.js";
import sendEmail from "../api/send/sendEmail.js";

function InvoiceSign() {
  const navigate = useNavigate(); // Hook to handle navigation
  const { id } = useParams(); // Access the `id` parameter from the URL
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [link_dead, setLink_dead] = useState(false);
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [sign_url, setSign_url] = useState("");

  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;
  const [conditions_open, setConditionsOpen] = useState(false);

  const generateKey = async () => {
    const data = {
      creation_date: new Date(),
    };
    const key = await createDoc("security_key", data);
    return key.id;
  };

  // Fetch user, org, clients, and article data
  const handleFetchData = async () => {
    try {
      let invoice = await getDocDataById("devis", id);
      if (!invoice) {
        setLink_dead(true);
      } else {
        if (invoice.state !== "wait") {
          setLink_dead(true);
        } else {
          console.log(invoice);
          let key = await generateKey();
          await getTemporaryUrl("vide", "devis", "", id, prefix, key);
          invoice = await getDocDataById("devis", id);
          setInvoiceData(invoice);
        }
      }
      setLoading(false);
      // Fetch user data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    try {
      let success = await VerifySmsCodeCall("devis", id, code);
      if (success) {
        handleClickVariant("Code SMS correct", "success"); // Success notification
        const data = {
          creation_date: new Date(),
        };

        setStep(3);
        setConditionsOpen(true);
      } else {
        handleClickVariant("Code SMS incorrect", "error"); // Error notification
      }
    } catch (error) {
      handleClickVariant("Code SMS incorrect", "error"); // Error notification
      console.error("Error logging in:", error.message);
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleSign = async () => {
    let key = await generateKey();
    await getTemporaryUrl("vide", "devis", "", id, prefix, key);
    let key2 = await generateKey();
    await signDevis(
      invoiceData.champs,
      invoiceData.file.url,
      key2,
      invoiceData.id,
      prefix
    );
    handleClickVariant("Devis signé", "success");
    setStep(4);
  };

  const handleAddSignature = async () => {
    let key = await generateKey();
    const url = await sendTamponDevis(invoiceData.org_ref.id, key, prefix);
    setSign_url(url);
    let champs = invoiceData.champs;
    console.log(invoiceData);
    champs[0].content = url;
    console.log("trying to update");
    await updateDocDataField(invoiceData.ref, "champs", champs);
    console.log("updated");
  };

  const handleRefuse = () => {
    setStep(1);
    setConditionsOpen(false);
  };

  const handleSendErrorEmail = async () => {
    setStep(-1);
    await updateDocDataField(invoiceData.ref, "state", "error");

    let client = await getDocDataByRef(invoiceData.client_o);
    let sujet = "Mauvais numéro rensigné pour signature de devis.";
    let date = new Date(invoiceData.creation_date.seconds * 1000);
    let message = `Le devis envoyé à ${invoiceData.nom_client} ${invoiceData.adresse_client}, le ${date.toLocaleDateString()} n'a pas pu être signé. Veuillez vérifier le numéro de téléphone et l'adresse email renseignés pour la signature.`;
    await sendEmail(message, client.email, sujet, "vide", prefix);
    handleClickVariant("Un email a été envoyé à votre partenaire", "success");
  };

  const handleSendSms = async () => {
    const infos = gatherDeviceInfo();
    sendSmsVerificationCall("devis", id, infos);
    handleClickVariant("Un SMS de vérification a été envoyé", "success");
    setStep(2);
  };

  useEffect(() => {
    handleFetchData(); // Fetch user data when currentUser is available
  }, []); // Dependency array ensures this runs when currentUser changes

  if (loading) {
    return (
      <div className="w-full h-full overflow-auto flex flex-row gap-2 mt-3">
        Loading ...
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full overflow-auto align-middle items-center justify-center">
      <Modal isOpen={conditions_open} onClose={() => setConditionsOpen(false)}>
        <div className="flex flex-col w-c500 h-fit bg-white rounded-3xl shadow-xl p-4 gap-2">
          <div className="text-h3 text-shadow_50">
            En signant électroniquement ce devis, vous acceptez les termes et
            conditions et reconnaissez que cette signature électronique a la
            même valeur juridique qu'une signature manuscrite. Vous confirmez
            également que vous êtes autorisé(e) à signer ce document au nom de
            la partie contractante, et que vous acceptez tous les termes et
            conditions stipulés dans ce devis.
          </div>
          <Space y={5}></Space>
          <div className="text-h3 text-shadow_50">
            Vous reconnaissez que cette signature électronique est utilisée pour
            authentifier l'identité du signataire et garantir l'intégrité du
            document. Ce devis, une fois signé, constitue un accord contraignant
            entre les parties.
          </div>
          <Space y={5}></Space>
          <div className="flex flex-row gap-2 w-full items-center justify-center">
            <SecondaryButton onClick={handleRefuse}>Je refuse</SecondaryButton>
            <PrimaryButton onClick={() => setConditionsOpen(false)}>
              J'accepte les conditions
            </PrimaryButton>
          </div>
          <Space y={5}></Space>
          <div className="flex flex-row gap-2 w-full h-4 items-center justify-center">
            <div className="text-shadow_50 text-h3">
              {" "}
              Propulsé par <span className="font-semibold">hexagone</span>
            </div>
            <Divider orientation="vertical"></Divider>
            <div className="text-shadow_50 text-h3"> Mentions légales</div>{" "}
            <Divider orientation="vertical"></Divider>{" "}
            <div className="text-shadow_50 text-h3"> Conditions</div>
          </div>
        </div>
      </Modal>
      {/* Left Column: Takes all available space */}
      {link_dead && (
        <div className="flex flex-col w-fit h-fit p-4 gap-2 bg-white rounded-3xl shadow-xl overflow-auto items-center">
          <Icon1 icon_name="warning" fill="" />
          <div className="text-h2 text-center">Ce lien a expiré</div>
        </div>
      )}
      {step === -1 && (
        <div className="flex flex-col w-fit h-fit p-4 gap-2 bg-white rounded-3xl shadow-xl overflow-auto items-center">
          <Icon1 icon_name="cloche" fill="" />
          <div className="text-h2 text-center">
            Votre partenaire a été notifié.
          </div>
          <div className="text-h2 text-shadow_50 text-center w-c250">
            Il va vous contacter pour récupérer le bon numéro de téléphone et
            relancer la procédure.
          </div>
          <div className="text-h2 text-shadow_50 text-center w-c250">
            Vous pouvez fermer cette page.
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="flex flex-col w-fit h-fit p-4 gap-2 bg-white rounded-3xl shadow-xl overflow-auto items-center">
          <Icon1 icon_name="smartphone" fill="" />
          <div className="text-h2 text-center w-c250">
            Vous allez recevoir un sms pour confirmer votre identité.
          </div>
          <div className="text-h2 text-center w-c250">
            Est-ce que ce numéro de téléphone est bien le vôtre ?
          </div>
          <div className=" rounded-xl py-0.5 text-shadow_50 bg-neutral-200 px-2 text-h2 font-semibold w-fit">
            {invoiceData.tel_client}
          </div>{" "}
          <div className="flex flex-row gap-2">
            <SecondaryButton onClick={handleSendErrorEmail}>
              Non
            </SecondaryButton>
            <PrimaryButton onClick={handleSendSms}>Oui</PrimaryButton>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="flex flex-col w-fit h-fit p-4 gap-2 bg-white rounded-3xl shadow-xl overflow-auto items-center">
          <Icon1 icon_name="smartphone" fill="" />
          <div className="text-h2 text-center w-c250">
            Entrez le code que vous avez reçu.
          </div>
          <TextField2
            label="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          ></TextField2>
          <div className="flex flex-row gap-2">
            <SecondaryButton onClick={() => setStep(1)}>Retour</SecondaryButton>
            <PrimaryButton onClick={handleVerifyCode}>Vérifier</PrimaryButton>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="flex flex-col w-full h-fit p-6 gap-3 bg-white rounded-3xl shadow-xl overflow-auto ">
          <div className="text-h2 text-left font-semibold w-c250">
            Signature du devis
          </div>
          <div className="text-h2 text-shadow_50 w-c400">
            Bienvenue dans l'interface de signature d'hexagone, lisez le devis
            puis procédez à l'ajout de votre signature pour finaliser la
            signature de ce devis.
          </div>

          <div className="flex flex-row  w-full">
            <div className="text-h2 text-shadow_50">Vidéo tutoriel </div>
            <Space x={5}></Space>
            <Icon1 icon_name="youtube" fill="grey"></Icon1>
          </div>
          <div className="flex flex-row gap-2 w-full">
            <div className="flex w-2/3">
              <PDF files={[invoiceData.presign_file]}></PDF>
            </div>
            <div className="flex flex-col w-1/3">
              <div className="flex flex-col bg-white p-4 gap-3  rounded-xl h-fit border-1 border-shadow_10">
                <div className="text-h2 text-left font-semibold w-c250">
                  Signature
                </div>
                <div className="text-h2 text-shadow_50 w-c400">
                  Après avoir lu le devis à gauche, veuillez ajouter votre
                  signature ci-dessous pour signer le devis.
                </div>
                <Divider orientation="horizontal"></Divider>
                <div className="text-h2 text-shadow_50 w-c400">
                  Cliquez pour ajouter votre signature.
                </div>
                <div
                  className="border-1 border-shadow_50  rounded-xl p-2 cursor-pointer"
                  onClick={handleAddSignature}
                >
                  <img
                    src={sign_url}
                    className="w-full h-c100 rounded-xl"
                  ></img>
                </div>
                <div className="flex flex-row w-full justify-end">
                  <PrimaryButton
                    disabled={sign_url ? false : true}
                    onClick={handleSign}
                    className="w-fit"
                  >
                    Signer le devis
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="flex flex-col w-fit h-fit p-4 gap-2 bg-white rounded-3xl shadow-xl overflow-auto items-center">
          <Icon1 icon_name="done" fill="" />
          <div className="text-h2 text-center w-c250">
            Le document a été signé.
          </div>
          <div className="text-h2 text-center w-c250 text-shadow_50">
            Vous allez recevoir la version signée par email. Vous pouvez fermer
            cette page.
          </div>
        </div>
      )}
    </div>
  );
}

export default InvoiceSign;
