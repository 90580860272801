import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../config/FirebaseConfig"; // Import the Firebase setup

// Save user data to Firestore
export async function saveUser(userData) {
  const userDocRef = doc(db, "user", userData.id);
  await setDoc(userDocRef, {
    display_name: userData.display_name,
    email: userData.email,
    org_ref: userData.org_ref,
    admin: userData.admin,
    ref: userDocRef, // Include the reference when saving the data
  });
}

// Fetch a user document from Firestore by reference
export async function getUserByRef(userRef) {
  const userSnapshot = await getDoc(userRef);
  if (userSnapshot.exists()) {
    return { id: userRef.id, ref: userRef, ...userSnapshot.data() }; // Include the reference and ID in the returned data
  } else {
    console.log("No such document!");
    return null;
  }
}

// Fetch a user document from Firestore by ID
export async function getUserById(userId) {
  const userDocRef = doc(db, "user", userId);
  const userSnapshot = await getDoc(userDocRef);
  if (userSnapshot.exists()) {
    return { id: userId, ref: userDocRef, ...userSnapshot.data() }; // Include the reference and ID in the returned data
  } else {
    console.log("No such document!");
    return null;
  }
}
