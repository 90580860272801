import { createTheme } from "@mui/material/styles";
import { dark, light } from "@mui/material/styles/createPalette";
import { frFR } from "@mui/material/locale"; // Import French locale

// Define the light theme
export const lightTheme = createTheme(
  {
    components: {
      // Name of the component
      MuiMenu: {
        // Here we target the MuiMenu component
        styleOverrides: {
          // We use styleOverrides to target the root of the component
          paper: {
            backgroundColor: "rgba(255,255,255)", // Change the background color
            color: "rgba(0,0,0)", // Change the text color
          },
        },
      },
      // Here we target the paper class
    },

    typography: {
      fontFamily: "Poppins, sans-serif", // Set Poppins as default font
      color: "rgba(0,0,0)", // Custom color for h1
      primary: {
        fontSize: 14,
        fontWeight: 300,
        color: "rgba(255,255,255)", // Custom color for h1
      },

      h1: {
        fontSize: 22,
        fontWeight: 600,
      },
      h2: {
        fontSize: 14,
        fontWeight: 300,
      },
      h3: {
        fontSize: 13,
        fontWeight: 300,
      },
    },
    palette: {
      mode: "light", // Set the mode to light
      primary: {
        main: "rgba(45,46,210,1)", //
        light: "rgba(81,83,242,1)",
        l2: "rgba(200,200,242,1)",
        l10: "rgb(217, 241, 254,1)",
      },
      secondary: {
        main: "rgba(255,255,255)", // Custom primary color for light mode
        dark: "rgba(230,230,230)", // Custom primary color for light mode
        contrastText: "rgba(0,0,0,0.5)",
      },
      chart: {
        line_blue: "rgba(100,100,255,1)", // Custom primary color for light mode
        fill_blue: "rgba(200,200,255,1)", // Custom primary color for light mode
        line_green: "rgba(0,255,100,1)", // Custom primary color for light mode
        fill_green: "rgba(200,255,200,1)", // Custom primary color for light mode
        line_light_blue: "rgba(200,200,255,1)", // Custom primary color for light mode
        fill_light_blue: "rgba(100,255,255,0.3)", // Custom primary color for light mode
      },
      background: {
        default: "rgba(255,255,255)", // Light background color
        paper: "rgba(230,230,230)", // Paper background
      },
      forever: {
        white: "rgba(255,255,255)", // Custom secondary color for light mode
        black: "rgba(0,0,0)", // Custom secondary color for light mode
      },
      shadow: {
        _50: "rgba(0,0,0,0.5)", // Custom secondary color for light mode
        _25: "rgba(0,0,0,0.25)", // Custom secondary color for light mode
        _10: "rgba(0,0,0,0.10)", // Custom secondary color for light mode
        _5: "rgba(0,0,0,0.05)", // Custom secondary color for light mode
        _2: "rgba(0,0,0,0.02)", // Custom secondary color for light mode
        _1: "rgba(0,0,0,0.01)", // Custom secondary color for light mode
      },
      accent1: {
        main: "rgba(0,230,106)", // Custom secondary color for light mode
        light: "rgba(220,255,220)",
      },
      accent2: {
        main: "rgba(230,50,50)", // Custom secondary color for light mode0
        light: "rgba(255,220,220)", // Custom secondary color for light mode
      },
      accent3: {
        main: "rgba(220,144,50)", // Custom secondary color for light mode0
        light: "rgba(255,230,180)", // Custom secondary color for light mode
      },

      success: {
        main: "rgba(0,255,106)",
      },
      error: {
        main: "rgba(255,50,50)",
      },

      white: {
        main: "rgba(255,255,255)", // Custom secondary color for light mode
        dark: "rgba(230,230,230)", // Custom secondary color for light mode
        contrastText: "rgba(0,0,0)",
      },
      black: {
        main: "rgba(0,0,0)", // Custom secondary color for light mode
        light: "rgba(20,20,20)", // Custom secondary color for light mode
        contrastText: "rgba(255,255,255)", // Custom secondary color for light mode
      },
    },
  },
  frFR
);

// Define the dark theme
export const darkTheme = createTheme(
  {
    typography: {
      fontFamily: "Poppins, sans-serif", // Set Poppins as default font
      color: "rgba(255,255,255)", // Custom color for h1
      primary: {
        fontSize: 14,
        fontWeight: 300,
        color: "rgba(255,255,255)", // Custom color for h1
      },

      h1: {
        fontSize: 22,
        fontWeight: 600,
      },
      h2: {
        fontSize: 14,
        fontWeight: 300,
      },
      h3: {
        fontSize: 13,
        fontWeight: 300,
      },
    },
    palette: {
      mode: "dark", // Set the mode to dark
      primary: {
        main: "rgba(0,6,255,1)", // Custom primary color for light mode
        light: "rgba(100,100,255,1)",
        contrastText: "rgba(255,255,255)",
      },

      secondary: {
        main: "rgba(50,50,50)", // Custom primary color for light mode
        dark: "rgba(100,100,100)", // Custom primary color for light mode
      },
      background: {
        default: "rgba(0,0,0)", // Light background color
        paper: "rgba(230,230,230)", // Paper background
      },
      text: {
        primary: "rgba(255,255,255)", // Text color in light mode
        secondary: "rgba(255,255,255,0.5)", // Text color in light mode
      },
      forever: {
        white: "rgba(255,255,255)", // Custom secondary color for light mode
        black: "rgba(0,0,0)", // Custom secondary color for light mode
      },
      shadow: {
        _50: "rgba(255,255,255,0.5)", // Custom secondary color for light mode
        _25: "rgba(255,255,255,0.25)", // Custom secondary color for light mode
        _10: "rgba(255,255,255,0.10)", // Custom secondary color for light mode
        _5: "rgba(255,255,255,0.05)", // Custom secondary color for light mode
      },

      accent1: {
        main: "rgba(0,255,106)", // Custom secondary color for light mode
        light: "rgba(150,255,150)",
      },
      accent2: {
        main: "rgba(255,50,50)", // Custom secondary color for light mode0
        light: "rgba(255,150,150)", // Custom secondary color for light mode
      },

      white: {
        main: "rgba(0,0,0)", // Custom secondary color for light mode
        dark: "rgba(20,20,20)", // Custom secondary color for light mode
        contrastText: "rgba(255,255,255)",
      },
      black: {
        main: "rgba(255,255,255)", // Custom secondary color for light mode
        dark: "rgba(230,230,230)", // Custom secondary color for light mode
        contrastText: "rgba(0,0,0)",
      },
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              borderRadius: "02px", // Apply border radius globally to all checkboxes
            },
          },
        },
      },
    },
  },
  frFR
);

export function flutterColorToHex(colorInt) {
  // Extract the ARGB components from the integer
  const alpha = (colorInt >> 24) & 0xFF;
  const red = (colorInt >> 16) & 0xFF;
  const green = (colorInt >> 8) & 0xFF;
  const blue = colorInt & 0xFF;

  // Convert each component to a two-digit hexadecimal string
  const hexAlpha = alpha.toString(16).padStart(2, '0');
  const hexRed = red.toString(16).padStart(2, '0');
  const hexGreen = green.toString(16).padStart(2, '0');
  const hexBlue = blue.toString(16).padStart(2, '0');

  // Return the color in CSS hex format (with alpha)
  return `#${hexAlpha}${hexRed}${hexGreen}${hexBlue}`;
}

export default lightTheme;
