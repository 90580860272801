import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "./addLogDocument"; // Custom log function

// Utility function to get the temporary URL
const getTemporaryUrl = async (
  token,
  type,
  documentId,
  folderId,
  prefix,
  key = null
) => {
  let url = "";

  // Determine the URL based on type
  switch (type) {
    case "all":
      url = prefix + `folders/${folderId}/all/presigned-urls`;
      break;
    case "doc":
      url = prefix + `document/${documentId}/files/presigned-urls`;
      break;
    case "thermal":
      url = prefix + `folders/${folderId}/thermal/presigned-urls`;
      break;
    case "modif_thermal":
      url = prefix + `folders/${folderId}/modif-thermal/presigned-urls`;
      break;
    case "rapport":
      url = prefix + `folders/${folderId}/rapport/presigned-urls`;
      break;
    case "rapport_architecte":
      url = prefix + `folders/${folderId}/rapport-architecte/presigned-urls`;
      break;
    case "xml":
      url = prefix + `folders/${folderId}/xml/presigned-urls`;
      break;
    case "photo":
      url = prefix + `document/${documentId}/photos/presigned-urls`;
      break;
    case "devis":
      url = prefix + `hexasign/devis/${folderId}/presigned-urls`;
      break;
    case "messages":
      url = prefix + `folders/${folderId}/messages/presigned-urls`;
      break;
    case "last_file_extracted":
      url = prefix + `folders/${folderId}/last-file-extracted/presigned-urls`;
      break;
    default:
      throw new Error("Unknown type");
  }

  // Define the request body
  const body = token === "vide" ? { keyid: key } : {};
  url = token === "vide" ? url + "/" + key : url;

  // Set up headers for the request
  const headers =
    token === "vide"
      ? { "Content-Type": "application/json" }
      : {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

  try {
    // Send POST request to the API
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });

    if (token === "vide") {
      return;
    }

    // Get the current Firebase user
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error("User not authenticated");
    }

    // Get the user's document reference from Firestore
    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      throw new Error("User document not found");
    }

    const orgRef = userSnapshot.get("org_ref");

    // Check if the request was successful and handle the response once
    const responseText = await response.clone().text(); // Use clone() to preserve the response stream

    if (response.ok) {
      const responseData = await response.json(); // Assuming the API returns JSON
      // Log the request and response
      await addLogDocument(
        url,
        userRef,
        orgRef,
        response.status.toString(),
        folderId || "",
        JSON.stringify(body), // Logs the request body
        responseText // Logs the response body
      );
      return responseData;
    } else {
      console.error(`Failed request: ${response.status}`);
      return `Failed request: ${response.status}`;
    }
  } catch (error) {
    console.error(`Exception occurred: ${error}`);
    return `Exception: ${error.message}`;
  }
};

export default getTemporaryUrl;
