import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import Icon1 from "../icons/Icon";
import { useTheme } from "@mui/material";

const ListCell = ({ user, removable }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        padding: "7px",
        borderBottom: "1px solid #e0e0e0", // Adds a separator between items
        width: "100%",
      }}
    >
      {/* User Avatar and Email */}
      <Box display="flex" alignItems="center">
        <Avatar
          sx={{
            bgcolor: "#3f51b5",
            marginRight: "10px",
            height: "20px",
            width: "20px",
            fontSize: "12px",
          }}
        >
          {user.email.charAt(0).toUpperCase()}
        </Avatar>
        <Typography fontSize={13}>{user.email}</Typography>
      </Box>

      {/* Close Icon (Removable functionality) */}
      {removable && (
        <Box
          className="cursor-pointer"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Icon1
            icon_name="close"
            height="16px"
            width="16px"
            fill={theme.palette.accent2.main}
          />
        </Box>
      )}
    </Box>
  );
};

export default ListCell;
