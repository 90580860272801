import React from "react";
import Typography from "@mui/material/Typography";

function HexagoneTitle() {
  return (
    <Typography
      variant="contained"
      color="black"
      sx={{
        textTransform: "none",
        padding: "5px 10px",
        border: "0px solid",
        borderRadius: "6px",
        fontSize: "20px",
        fontWeight: 800,
        lineHeight: 1,
      }}
    >
      hexagone
    </Typography>
  );
}

export default HexagoneTitle;
