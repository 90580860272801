import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming you have this utility for logging

const stripeCheckoutSession = async (oid, quantity, token, prefix) => {
  // Define the endpoint URL
  const url = prefix + "stripe/create-checkout-session";

  // Define the request body
  const body = {
    oid: oid,
    product_type: "signature",
    engagement: "none",
    quantity: quantity,
    mode: "payment",
  };

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request using fetch
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return "User not logged in";
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return;
    }

    const orgRef = userSnapshot.get("org_ref");

    const responseData = await response.json();

    // Add log document
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "",
      "",
      JSON.stringify(body),
      JSON.stringify(responseData) // log the response data as a string
    );

    // Check if the request was successful
    if (response.ok) {
      const checkoutUrl = responseData.url || "";
      return checkoutUrl;
    } else {
      console.error(`Failed to create checkout session: ${response.status}`);
      return "";
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return "";
  }
};

export default stripeCheckoutSession;
