import React, { useEffect, useState } from "react";
import Row from "../layout/Row";
import SRPrimaryButton from "../buttons/SRPrimaryButton";
import SRSIconButton from "../buttons/SRSIconButton";
import IconButton from "../buttons/IconButton";
import { logoutUser } from "../auth/FirebaseAuth";
import { useAuth } from "../auth/FirebaseAuth";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import SupportAM from "../actionmenus/SupportAM";

function HeaderBar() {
  const { currentUser, loading } = useAuth();
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook to handle navigation

  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);

  const handleFetchData = async () => {
    if (currentUser) {
      const user = await getUserById(currentUser.uid); // Fetch user data using function
      const org = await getOrgByRef(user.org_ref); // Fetch org data using function
      if (user && user !== userData) {
        setUserData(user);
        setOrgData(org);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData(); // Fetch user data when currentUser is available
    }
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logoutUser(); // Log out user
      navigate("/entree"); // Navigate to "entree" after logout
    } catch (error) {
      console.error("Error logging out:", error.message);
      setError(error.message); // Display error message if logout fails
    }
  };

  const goFullScreen = () => {
    const myDiv = document.getElementById("root");

    if (!fullScreen) {
      setFullScreen(true);
      if (myDiv.requestFullscreen) {
        myDiv.requestFullscreen();
      } else if (myDiv.mozRequestFullScreen) {
        myDiv.mozRequestFullScreen(); // For Firefox
      } else if (myDiv.webkitRequestFullscreen) {
        myDiv.webkitRequestFullscreen(); // For Chrome, Safari, and Opera
      } else if (myDiv.msRequestFullscreen) {
        myDiv.msRequestFullscreen(); // For IE/Edge
      }
    } else {
      setFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); // For Firefox
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // For Chrome, Safari, and Opera
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // For IE/Edge
      }
    }
  };

  return (
    <div className=" w-full flex-row-reverse flex">
      <div className=" px-3 py-2 border-solid border-b border-l rounded-bl-3xl bg-white shadow-sm">
        <Row x={5}>
          <div className="text-h3 px-2 text-center text-shadow_50">
            Rechargez la page
          </div>
          <SRPrimaryButton
            color="primary"
            sx={{ padding: "6px 10px 6px 10px" }}
          >
            <div className="text-f_white text-h3">Gérer mon plan</div>
          </SRPrimaryButton>
          <SupportAM></SupportAM>
          <SRSIconButton
            icon_name="settings"
            onClick={() => navigate("/parameters")}
          >
            Paramètres
          </SRSIconButton>
          <IconButton
            icon_name="full-screen"
            onClick={goFullScreen}
          ></IconButton>
          <IconButton icon_name="logout" onClick={handleLogout}></IconButton>
        </Row>
      </div>
    </div>
  );
}

export default HeaderBar;
