import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./FirebaseAuth"; // Assuming this is your custom auth hook
import { getDocDataById, getDocDataByRef } from "../models/DataFunctions"; // Import your Firestore function
import LogRocket from "logrocket";
import { or } from "firebase/firestore";
import { use } from "echarts";
const ProtectedRoute = ({ children, requiredRole, needs_org = true }) => {
  const { currentUser, loading } = useAuth(); // Assuming this comes from Firebase auth context
  const [userData, setUserData] = useState(null); // State for user data
  const [orgData, setOrgData] = useState(null); // State for org data
  const [loadingUserData, setLoadingUserData] = useState(true); // State for loading user data

  useEffect(() => {
    // Fetch user data from Firestore if currentUser exists
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const data = await getDocDataById("user", currentUser.uid);
          if (data.org_ref) {
            const orgData = await getDocDataByRef(data.org_ref);
            setOrgData(orgData);
          }
          setUserData(data);
          console.log("data", data);
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        } finally {
          setLoadingUserData(false);
        }
      } else {
      }
    };

    fetchUserData();
  }, [currentUser]);

  // Show loading state while authentication or user data is being fetched
  if (loading) {
    return <div></div>;
  }

  // Redirect to login if the user is not authenticated
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (loadingUserData) {
    return <div></div>;
  }

  if (!userData) {
    console.error("userData missing");
    return <Navigate to="/login" replace />;
  }

  if (needs_org) {
    if (!userData.org_ref) {
      console.error("org missing");
      return <Navigate to="/setup" replace />;
    }
  }

  if (orgData) {
    LogRocket.identify(currentUser.uid, {
      name: userData.display_name,
      email: userData.email,
      org: userData.org_ref.id,
      org_name: orgData.name,
      org_email: orgData.email,
      avatar: orgData.logo,
    });
  }

  // Check if the user has the required role
  if (requiredRole) {
    if (userData.role !== requiredRole) {
      console.error("role not allowed" + userData.role);

      return <Navigate to="/login" replace />;
    }
  }
  // Render the children if all checks pass

  return children;
};

export default ProtectedRoute;
