import React from "react";
import Box from "@mui/material/Box";
import { shadows } from "@mui/system";

function Box1({ children }) {
  return (
    <Box
      sx={{
        width: "fit-content",
        borderRadius: 1,
        bgcolor: "white.main",
        borderRadius: "30px",
        boxShadow: 3,
        boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.1)", // Custom shadow control
      }}
    >
      <div className="justify-center items-center flex-col flex p-4  space-y-3">
        {children}
      </div>
    </Box>
  );
}

export default Box1;
