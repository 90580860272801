import React, { useEffect, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth.js";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../models/UserFunctions.js";
import {
  getOrgByRef,
  getPacksByOrgRef,
  getConductorsByOrgRef,
} from "../models/OrgFunctions.js";
import Row from "../layout/Row.js";
import Column from "../layout/Column.js";
import Divider from "../layout/Divider.js";
import Space from "../layout/Space.js";
import Icon1 from "../icons/Icon.js";
import TextField2White from "../textfields/TextField2White.js";
import DropDown1 from "../dropdowns/DropDown1.js";
import CheckBoxGrey from "../textfields/CheckBoxGrey.js";
import PrimaryButton from "../buttons/PrimaryButton.js";
import { useSnackbar } from "notistack";
import { getClientsByOrgRef } from "../models/OrgFunctions.js";
import createNewFolder from "../api/create/createNewFolder.js";
import { getArrayDocDataByRef } from "../models/DataFunctions.js";
import { set } from "date-fns";
import SecondaryButton from "../buttons/SecondaryButton.js";
import { DatePicker } from "antd";
import "antd/dist/reset.css"; // Import Ant Design styles
import moment from "moment";
import frFR from "antd/es/locale/fr_FR";
import { useTheme } from "@mui/material";
import SmallSpinner from "../loading/SmallSpinner.js";
import { useAppContext } from "../config/AppContext.js";

function NewDemand() {
  const navigate = useNavigate(); // Hook to handle navigation
  const { currentUser, loading } = useAuth();

  const intervention_options = [
    { value: false, label: "Intervention à programmer" },
    { value: true, label: "Intervention réalisée" },
  ];

  const [step, setStep] = useState(0);

  const [pack_options, setPack_options] = useState([]);
  const [client_conductors, setClient_conductors] = useState([]);

  const [masters, setMasters] = useState([]);
  const [masters_name_options, setMasters_name_options] = useState([]);
  const [master, setMaster] = useState("");
  const [master_selected, setMaster_selected] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  // State variables to store the input values
  const [nom, setNom] = useState("");
  const [ville, setVille] = useState("");
  const [postal, setPostal] = useState("");
  const [intervention, setIntervention] = useState("");
  const [client, setClient] = useState("");

  const [mission, setMission] = useState("");
  const [conductor, setConductor] = useState("");

  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [date, setDate] = useState("../../....");
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);

  const [loadingData, setLoadingData] = useState(false);

  const handleFetchClientConductors = async () => {
    if (currentUser) {
      try {
        const users = (await getArrayDocDataByRef(client.users)) || [];
        let client_conductors = users.filter(
          (user) => user.role === "conductor"
        );
        let client_conductors_options = client_conductors.map((user) => {
          return { value: user.id, label: user.display_name };
        });
        setClient_conductors(client_conductors_options);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const [dateRange, setDateRange] = useState(null);

  // Handle the time range change
  const onChange = (dates, dateStrings) => {
    setDateRange(dateStrings); // Store the selected date range
  };

  // Fetch user, org, clients, and article data
  const handleFetchData = async () => {
    if (currentUser) {
      try {
        // Fetch user data
        const user = await getUserById(currentUser.uid);

        // Fetch organization data using user's org reference
        const org = await getOrgByRef(user.org_ref);
        const client = org;
        setClient(client);
        console;
        const masters = await getArrayDocDataByRef(org.masters);
        const master = masters[0];
        const masters_options = masters.map((master) => {
          return { value: master.id, label: master.name };
        }); // Fetch clients associated with the user's organization

        setMasters(masters);
        setMasters_name_options(masters_options);
        setMaster(master);
        setMaster_selected({
          value: master.id,
          label: master.name,
        });

        // ORG IS CLIENT
        const packs = await getPacksByOrgRef(master.ref);

        const packs_name = packs.map((pack) => {
          return { value: pack.id, label: pack.pack_name };
        });

        // Update state with fetched data
        setUserData(user);
        setOrgData(org);
        handleFetchClientConductors(client.id);

        setPack_options(packs_name);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleSetMaster = (value) => {
    if (value) {
      const master = masters.find((master) => master.id === value);
      setMaster_selected({ value: master.id, label: master.nom });
      setMaster(master);
    } else {
      setMaster_selected({});
    }
  };
  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const { RangePicker } = DatePicker;

  const theme = useTheme();

  useEffect(() => {
    if (currentUser) {
      handleFetchData(); // Fetch user data when currentUser is available
    }
  }, [currentUser]); // Dependency array ensures this runs when currentUser changes

  // Function to create a new client
  const createFolder = async () => {
    if (!userData || !orgData) return;

    try {
      if (nom === "") {
        handleClickVariant(
          "Veuillez saisir le nom du maître d'ouvrage",
          "error"
        );
        return;
      }
      if (ville === "") {
        handleClickVariant("Veuillez saisir la ville", "error");
        return;
      }
      if (postal === "") {
        handleClickVariant("Veuillez saisir le code postal", "error");
        return;
      }
      if (dateRange === null) {
        handleClickVariant("Veuillez sélectionner une date", "error");
        return;
      }
      if (intervention === "") {
        handleClickVariant("Veuillez sélectionner une intervention", "error");
        return;
      }
      if (master === "") {
        handleClickVariant(
          "Veuillez sélectionner un bureau de contrôle",
          "error"
        );
        return;
      }
      if (mission === "") {
        handleClickVariant("Veuillez sélectionner une mission", "error");
        return;
      }

      setLoadingData(true);
      const oid = orgData.id; // Assuming orgData has the organization id
      const startdate = dateRange[0];
      const enddate = dateRange[1];
      // Get the user's Firebase authentication token
      const token = await currentUser.getIdToken(); // Fetch token from Firebas
      const packid = mission;
      const clientmemberid = conductor;
      await createNewFolder(
        intervention,
        postal,
        mission,
        ville,
        nom,
        "",
        "",
        "",
        clientmemberid,
        date,
        hour.toString(),
        minute,
        startdate,
        enddate,
        client.id,
        packid,
        master.id,
        oid,
        token,
        prefix
      );
      setLoadingData(false);
      navigate("/folders"); // Navigate to the clients page
      handleClickVariant("Dossier créé avec succès", "success");
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  if (loading || !currentUser || !userData) {
    return <div>Loading...</div>; // Show loading state while checking auth or if currentUser is null
  }

  return (
    <Row className="w-full h-full overflow-auto flex">
      {/* Left Column: Takes all available space */}

      {/* Right Column: Fixed width */}
      {step === 0 && (
        <Column align="left" className="h-full max-w-c400 pl-4">
          <Space y={20} />
          <div className="text-h1 font-semibold">Nouveau Dossier</div>
          <Divider orientation="horizontal" />
          <Space y={5} />
          <Column align="left" className="w-full pr-2" y={10}>
            <TextField2White
              className="w-full"
              label="Nom du maître d'ouvrage"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
            ></TextField2White>
            <TextField2White
              label="Ville"
              value={ville}
              onChange={(e) => setVille(e.target.value)}
            ></TextField2White>
            <TextField2White
              label="Code Postal"
              value={postal}
              onChange={(e) => setPostal(e.target.value)}
            ></TextField2White>
            <div className="h-fit w-full">
              <RangePicker
                format="DD/MM/YYYY" // Format for the date
                onChange={onChange}
                defaultValue={[]} // Default date range
              />
            </div>
            <DropDown1
              items={intervention_options}
              className="w-full"
              label="Intervention à programmer"
              value={intervention} // Controlled component
              onChange={(e) => setIntervention(e.target.value)} // Update state on change
            ></DropDown1>
            <DropDown1
              items={masters_name_options}
              className="w-full"
              label="Bureau de contrôle"
              value={master_selected.value} // Controlled component
              onChange={(e) => handleSetMaster(e.target.value)} // Update state on change
            ></DropDown1>
          </Column>
          <Space y={10} />

          <div className="w-full flex flex-row-reverse">
            <PrimaryButton onClick={() => setStep(1)}>
              <div className="flex flex-row content-center">
                Continuer <Space x={5} />
                <Icon1
                  icon_name="droite"
                  height="16px"
                  width="16px"
                  fill="white"
                ></Icon1>
              </div>
            </PrimaryButton>
          </div>
        </Column>
      )}
      {step === 1 && (
        <Column align="left" className="h-full max-w-c400 pl-4">
          <Space y={20} />
          <div className="text-h1 font-semibold">Nouveau Dossier</div>
          <Divider orientation="horizontal" />
          <Space y={5} />
          <Column align="left" className="w-full pr-2" y={10}>
            <SecondaryButton onClick={() => setStep(0)}>
              Annuler
            </SecondaryButton>
            <DropDown1
              items={pack_options}
              className="w-full"
              label="Mission"
              value={mission} // Controlled component
              onChange={(e) => setMission(e.target.value)} // Update state on change
            ></DropDown1>
            <DropDown1
              items={client_conductors}
              className="w-full"
              label="Conducteur de chantier (optionel)"
              value={conductor} // Controlled component
              onChange={(e) => setConductor(e.target.value)} // Update state on change
            ></DropDown1>
          </Column>
          <Space y={10} />

          <div className="w-full flex flex-row justify-end">
            <PrimaryButton onClick={createFolder} disabled={loadingData}>
              <div className="flex flex-row content-center align-middle items-center">
                Générer <Space x={5} />
                <SmallSpinner loading={loadingData} margin={0} size={20} />
              </div>
            </PrimaryButton>
          </div>
        </Column>
      )}
    </Row>
  );
}

export default NewDemand;
