import React from "react";
import Box from "@mui/material/Box"; // Material UI Box component

function Row({
  id,
  children,
  x = 10,
  sx,
  className = "",
  align = "center",
  onClick,
}) {
  let spacingClass = "";
  let justifyContent = "center"; // Default is center alignment

  if (align === "left") {
    justifyContent = "flex-start";
  } else if (align === "center") {
    justifyContent = "center";
  }

  // Choose spacing based on the provided `spacing` prop

  return (
    <Box
      id={id}
      onClick={onClick}
      className={`flex  ${justifyContent} items-center ${className}`} // Proper concatenation with space
      sx={{
        width: "100%", // Makes it full width by default, or you can modify as needed
        gap: `${x}px`, // Adjust spacing between items
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default Row;
