import React from "react";
import Typography from "@mui/material/Typography";
import Icon from "../icons/Icon";
import HexagoneTitle from "./HexagoneTitle";

function HexagoneHeader() {
  return (
    <div className="flex justify-center items-center">
      <Icon icon_name="hexagone" width="30px" height="40px" />
      <HexagoneTitle />
    </div>
  );
}

export default HexagoneHeader;
