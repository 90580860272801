import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming you have a logging function

const sendRequest = async (oid, message, email, token, prefix) => {
  const url = prefix + `support/${oid}/request`;
  const urlNoAuth = prefix + `support/${oid}/request-noauth`;

  // Define the request body
  let body = {
    oid: "NOT CONNECTED",
    message: message,
    email: email,
  };

  if (oid !== "01" && oid !== null) {
    body = {
      oid: oid,
      message: message,
      email: email,
    };
  }

  let headers = {
    "Content-Type": "application/json",
  };

  if (token !== "01") {
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User not logged in");
      return null;
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return null;
    }

    const orgRef = userSnapshot.get("org_ref");

    // Make the POST request using fetch instead of axios
    const response = await fetch(token !== "01" ? url : urlNoAuth, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    const responseData = await response.json();

    // Log the request and response
    await addLogDocument(
      token !== "01" ? url : urlNoAuth,
      userRef.id,
      orgRef ? orgRef.id : null,
      response.status.toString(),
      "", // folderid
      "", // docid
      JSON.stringify(body), // request body
      JSON.stringify(responseData) // response body
    );

    // Check if the request was successful
    if (response.ok) {
      console.log("Request sent successfully");
    } else {
      console.error(`Failed to send request: ${response.status}`);
    }
  } catch (error) {
    console.error(`Exception occurred: ${error.message}`);
  }
};

export default sendRequest;
