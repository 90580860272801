import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import axios from "axios"; // Use axios for HTTP requests
import addLogDocument from "../addLogDocument"; // Custom logging function

const extractXml = async (
  url,
  type,
  token,
  orgRef,
  folderId,
  phone,
  oid,
  email,
  address,
  name,
  prefix
) => {
  try {
    let result;

    console.log("Extracting XML from URL:", url);
    console.log("folderid", folderId);

    // Download the file from the URL
    const response = await axios.get(url, { responseType: "arraybuffer" });
    if (response.status === 200) {
      const uint8Array = new Uint8Array(response.data); // Directly get Uint8Array from arraybuffer

      result = {
        files: [
          {
            name: "downloaded_file.xml",
            bytes: uint8Array, // Uint8Array for file content
          },
        ],
      };
    } else {
      console.log("Error downloading file:", response.status);
      return { list: [], path: "" };
    }

    // Proceed if a file has been selected
    if (result && result.files.length > 0) {
      const file = result.files[0];

      // Create multipart request for XML extraction
      const extractUrl = prefix + `folders/${folderId}/xml/extract`;

      const formData = new FormData();
      formData.append("file", new Blob([file.bytes]), file.name); // Convert Uint8Array back to Blob for upload
      formData.append("type", type);

      // Use axios to POST the formData
      const extractResponse = await axios.post(extractUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Ensure correct content type
        },
      });

      const currentUser = getAuth().currentUser;
      if (!currentUser) {
        return null;
      }

      const userRef = doc(getFirestore(), "user", currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const orgRefFromUser = userSnapshot.get("org_ref");

      // Log the event
      await addLogDocument(
        url,
        userRef,
        orgRefFromUser,
        extractResponse.status.toString(),
        "",
        "",
        formData.get("type"),
        extractResponse.statusText
      );

      // Handle successful response
      if (extractResponse.status === 200) {
        const jsonResponse = extractResponse.data;

        if (type === "carbon" || type === "both") {
          const dictString = jsonResponse.dict;
          const path = jsonResponse.path;

          const myList = dictString.split("#").map((lotString) => {
            const myFields = lotString.split("@");
            return {
              X: myFields[0],
              num: myFields[1],
              nom: myFields[2],
              id: myFields[3],
              qt: myFields[4],
              un: myFields[5],
            };
          });

          return { list: myList, path: path };
        } else {
          const path = jsonResponse.path;
          const pdfUrl = prefix + `folders/${folderId}/xml/generate_pdf`;

          const orgDoc = await getDoc(orgRef);
          const orgData = orgDoc.data();

          // Define the request body for PDF generation
          const body = {
            footer: `${name} - ${address}\nTéléphone : ${phone} - Mail : ${email}`,
            type: type,
            oid: oid,
            batch: null,
            path: path,
            logo: orgData.logo || "https://i.ibb.co/8Nc2tS4/Placeholder.png",
          };

          const pdfResponse = await axios.post(pdfUrl, body, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          // Log PDF generation request
          await addLogDocument(
            pdfUrl,
            userRef,
            orgRefFromUser,
            pdfResponse.status.toString(),
            "",
            "",
            JSON.stringify(body),
            pdfResponse.data
          );

          // Handle PDF response
          if (pdfResponse.status === 200) {
            const responseBody = pdfResponse.data;
            const clean = responseBody.trim().replace(/"/g, "");
            return { list: [], path: clean };
          } else {
            console.log("Failed to create PDF:", pdfResponse.status);
            return { list: [], path: "" };
          }
        }
      } else {
        console.log("Error response:", extractResponse.status);
        return { list: [], path: "" };
      }
    } else {
      console.log("File selection canceled.");
      return { list: [], path: "" };
    }
  } catch (error) {
    console.log("Error during upload:", error);
    return { list: [], path: "" };
  }
};

export default extractXml;
