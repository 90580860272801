import React from "react";
import Button from "@mui/material/Button";

function GreyButton({ className, children, onClick, sx }) {
  return (
    <Button
      variant="contained"
      color="black"
      className={className}
      sx={{
        backgroundColor: "shadow._10",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0)", // Custom shadow control
        textTransform: "none",
        padding: "10px 15px",
        border: "0px solid",
        borderRadius: "10px",
        fontSize: "14px",
        fontWeight: "light",
        lineHeight: 1,
        "&:hover": {
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0)", // Custom shadow control
          backgroundColor: "shadow._25",
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default GreyButton;
