import { getAuth } from "firebase/auth"; // Firebase Authentication
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Firebase Firestore
import addLogDocument from "../addLogDocument.js"; // Custom log function

const unlockRapport = async (folderid, token, prefix) => {
  // Define the endpoint URL
  const url = prefix + "actions/unlock-rapport";

  // Define the request body
  const body = {
    folderid: folderid,
  };

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request using native fetch API
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    // Firebase Authentication to get the current user
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User not logged in");
      return "User not logged in";
    }

    // Firebase Firestore: Access user document
    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return "User document not found";
    }

    // Get organization reference from user document
    const orgRef = userSnapshot.data().org_ref;

    // Log the request and response (assuming log function is implemented)
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "", // Optional additional fields
      "",
      JSON.stringify(body), // Request body as string
      await response.text() // Response body as text
    );

    // Check if the request was successful
    if (response.ok) {
      return "Rapport unlocked successfully";
    } else {
      console.error(`Failed to unlock rapport: ${response.status}`);
      return `Failed to unlock rapport: ${response.status}`;
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return `Exception occurred: ${error.message}`;
  }
};

export default unlockRapport;
