import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

function RoundedButton({
  className,
  loading,
  type,
  children,
  color = "primary",
  onClick,
  sx,
}) {
  const theme = useTheme();
  const buttonColor = theme.palette[color]?.main;
  function handleClick() {
    if (type !== "submit") {
      if (typeof onClick === "function") {
        onClick();
      } else {
        console.error("onClick is not a function");
      }
    }
  }

  return (
    <LoadingButton
      className={className}
      variant="contained"
      type={type}
      size="small"
      onClick={handleClick}
      loading={loading}
      sx={{
        textTransform: "none",
        padding: "15px 22px",
        border: "0px solid",
        borderRadius: "60px",
        fontSize: "16px",
        lineHeight: 1,
        backgroundColor: buttonColor,
        color: theme.palette[color]?.contrastText,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Custom shadow control
        "&:hover": {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Custom shadow control
        },
        ...sx,
      }}
    >
      {children}
    </LoadingButton>
  );
}

export default RoundedButton;
