// src/config/CustomSnackbarStyles.js

import { styled } from "@mui/material/styles";
import { MaterialDesignContent } from "notistack";

const NotificationStyle = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "rgba(200, 255, 200, 0.8)", // Semi-transparent white
    borderRadius: "20px",
    color: "rgba(0, 0, 0,0.5)",
    backdropFilter: "blur(10px)", // Blurry background
    boxShadow: "0 0 12px rgba(0, 0, 0, 0.1)", // Nice
    border: "1px solid rgba(255, 255, 255, 0.3)", // Optional border for more contrast
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "rgba(255, 200, 200,0.8)", // Semi-transparent white
    borderRadius: "20px",
    color: "rgba(0, 0, 0,0.5)",
    backdropFilter: "blur(10px)", // Blurry background
    boxShadow: "0 0 12px rgba(0, 0, 0, 0.1)", // Nice
    border: "1px solid rgba(255, 255, 255, 0.3)", // Optional border for more contrast
  },
}));

export { NotificationStyle };
