import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import { se } from "date-fns/locale";

function TextFieldSearch({
  options = [],
  placeholder,
  value,
  setValue,
  onAction,
}) {
  const theme = useTheme();

  // Function to handle when the user presses the "Enter" key
  const handleKeyDown = (event, filteredOptions = []) => {
    if (event.key === "Enter") {
      if (Array.isArray(filteredOptions) && filteredOptions.length > 0) {
        //setValue(filteredOptions[0]); // Set the first filtered option
        console.log("Filtered options:", filteredOptions);
        onAction(filteredOptions[0]); // Trigger the action with the selected value
      } else {
        console.log("No options found");
        handleReset(); // Reset the input value
        onAction("");
      }
    }
  };

  useEffect(() => {}, [value]);

  const handleReset = (e) => {
    e && e.stopPropagation(); // Prevent the event from bubbling up
    console.log("Resetting value");
    setValue(""); // Reset the input value
    onAction(""); // Trigger the action with an empty value
  };

  // Ensure that options are handled properly and always return an array
  const filteredOptions = (Array.isArray(options) ? options : []).filter(
    (option) => {
      if (typeof option === "string" && value) {
        return option.toLowerCase().includes(value.toLowerCase());
      }
      return false;
    }
  );

  return (
    <>
      {Array.isArray(options) && options.length !== 0 && (
        <Autocomplete
          freeSolo
          options={options.map((option, index) => ({
            label: option,
            id: `${option}-${index}`, // Generate a unique key using the index
          }))}
          getOptionLabel={(option) => option?.label || ""} // Safe access to label
          inputValue={value}
          filterOptions={(options = [], state) =>
            (Array.isArray(options) ? options : []).filter((option) =>
              option.label
                .toLowerCase()
                .includes(state.inputValue.toLowerCase())
            )
          }
          onInputChange={(event, newValue) => {
            setValue(newValue); // Update the input value
          }}
          onChange={(event, newValue) => {
            if (newValue) {
              //setValue(newValue.label); // Set value when the user selects an option
              //onAction(newValue.label); // Trigger action with the selected option
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant="standard"
              style={{
                border: "1px solid #E0E0E0",
                borderRadius: "25px",
              }}
              fullWidth
              onKeyDown={(e) => handleKeyDown(e, filteredOptions)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                startAdornment: (
                  <>
                    <span style={{ marginRight: "8px" }}></span>
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    {value && <></>}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  transition: "background-color 0.3s ease",
                  borderRadius: "25px",
                  padding: "0px 12px",
                  width: "300px",
                  fontSize: "14px",
                },
                "& .MuiAutocomplete-input": {
                  padding: "10px 0px",
                  fontSize: "14px",
                },
                "&:hover .MuiInputBase-root": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            />
          )}
          ListboxProps={{
            sx: {
              "& .MuiAutocomplete-option": {
                fontSize: "12px",
                padding: "8px 10px",
              },
            },
          }}
        />
      )}
    </>
  );
}

export default TextFieldSearch;
