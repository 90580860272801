import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument.js"; // Assuming this is a custom logging function

const changeFolderState = async (orgid, folderid, state, token, prefix) => {
  // Define the endpoint URL
  const url = prefix + "actions/change-state-folder";

  // Define the request body
  const body = {
    org_id: orgid,
    folder_id: folderid,
    state: state,
  };

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    // Firebase authentication
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return "User not logged in";
    }

    // Firestore access
    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      return "User document not found";
    }

    const orgRef = userSnapshot.get("org_ref");

    // Log the request and response
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "", // Optional additional fields
      "",
      JSON.stringify(body), // Request body as string
      await response.text() // Response body as text
    );

    // Check if the request was successful
    if (response.ok) {
      return "Folder state changed successfully"; // Success message or further processing
    } else {
      return `Failed to change folder state: ${response.status}`;
    }
  } catch (error) {
    // Handle exceptions
    return `Exception occurred: ${error.message}`;
  }
};

export default changeFolderState;
