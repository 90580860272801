import { postData } from "./ApiService";

export function sendSmsVerificationCall(type, id, infos) {
  const data = {
    type: type,
    infos: JSON.stringify(infos),
    id: id,
  };

  postData("https://api.hexagone.expert/auth/sms", data)
    .then((response) => {
      console.log("Sms sent:", response);
    })
    .catch((error) => {
      console.error("Error sending SMS:", error);
    });
}

export async function VerifySmsCodeCall(type, id, code) {
  const data = {
    type: type,
    id: id,
    code_entered: parseInt(code),
  };

  try {
    const response = await postData(
      "https://api.hexagone.expert/auth/sms-verify",
      data
    );
    console.log("Code checked:", response);

    console.log("response.data.success:", response["success"]);

    console.log("response.data.success:", response["success"] === "true");

    // Check if the response was successful
    if (response["success"] === "true") {
      return true; // SMS verification succeeded
    } else {
      return false; // SMS verification failed
    }
  } catch (error) {
    console.error("Error verifying SMS code:", error);
    return false; // Return false on any error
  }
}
