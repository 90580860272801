import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming you have a similar function to log actions

const sendEmail = async (message, email, sujet, token, prefix) => {
  const emails = [email];

  // Define the endpoint URL
  let url = prefix + `notification/smart-email/folder/none`;
  if (token === "vide") {
    url = prefix + "notification/smart-email-noauth";
  }

  // Define the request body
  const body = {
    emails: emails,
    message: message,
    sujet: sujet,
    folder_id: "none",
  };

  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  if (token === "vide") {
    headers = {
      "Content-Type": "application/json",
    };
  }

  try {
    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("User not logged in");
      return "";
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return "";
    }

    const orgRef = userSnapshot.get("org_ref");
    const responseText = await response.text();

    // Extract only necessary info (like the IDs) from userRef and orgRef for logging

    // Add log document (avoid passing full document references)
    await addLogDocument(
      url,
      userRef, // Only log the userRef ID
      orgRef, // Only log the orgRef ID
      response.status.toString(),
      "", // folderid
      "", // docid
      JSON.stringify(body), // request body as string
      responseText,
      "POST"
    );

    // Check if the request was successful
    if (response.ok) {
      // Handle successful response
      return "User created successfully";
    } else {
      // Handle error response
      console.error(`Failed to send email: ${response.status}`);
      return `Failed to create user: ${response.status}`;
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return `Exception occurred: ${error.message}`;
  }
};

export default sendEmail;
