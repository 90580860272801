import React, { useEffect, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth.js";
import { useNavigate, useParams } from "react-router-dom";
import { getUserById } from "../models/UserFunctions.js";
import {
  getOrgByRef,
  getExpertsByOrgRef,
  queryArticlesByOrgRef,
} from "../models/OrgFunctions.js";
import { transformArticlesToTable } from "../models/ArticleFunctions.js";
import Column from "../layout/Column.js";
import Row from "../layout/Row.js";
import Divider from "../layout/Divider.js";
import Icon1 from "../icons/Icon.js";
import Space from "../layout/Space.js";
import MemberManager from "../members/MemberManager.js";
import InfoCell from "../listviews/InfoCell.js";
import MLTF1 from "../textfields/MLTF1.js";
import SecondaryButton from "../buttons/SecondaryButton.js";
import LineChart1 from "../charts/LineChart1.js";
import PieChart1 from "../charts/PieChart1.js";
import PrimaryButton from "../buttons/PrimaryButton.js";
import ExpertsAM from "../actionmenus/ExpertsAM.js";
import { Skeleton } from "@mui/material";
import Image1 from "../images/Image1.js";
import MenuButton from "../buttons/MenuButton.js";
import SetupButton from "../buttons/SetupButton.js";
import { set } from "date-fns";
import SearchBar1 from "../textfields/SearchBar1.js";
import TextFieldBorderLess from "../textfields/TextFieldBorderLess.js";
import Spinner from "../loading/Spinner.js";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken.js";
import scrapeQualibat from "../api/scrapeQualibat.js";
import { useAppContext } from "../config/AppContext.js";
import { useSnackbar } from "notistack";
import TextField2 from "../textfields/TextField2.js";
import CheckBoxGrey from "../textfields/CheckBoxGrey.js";
import { Spa } from "@mui/icons-material";
import Modal from "../modals/Modal.js";
import {
  sendSmsVerificationCall,
  VerifySmsCodeCall,
} from "../config/ApiCalls.js";
import { gatherDeviceInfo } from "../config/GatheringInfos.js";
import createNewMaster from "../api/create/createNewMaster.js";

function Setup() {
  const navigate = useNavigate(); // Hook to handle navigation
  const { id } = useParams(); // Access the `id` parameter from the URL
  const { currentUser, loading } = useAuth();

  const [userData, setUserData] = useState(null);
  const [steps, setSteps] = useState([]);
  const [step, setStep] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [qualibat_res, setQualibat_res] = useState(null);
  const [value, setValue] = useState(null);
  const [informations, setInformations] = useState(null);
  const [form, setForm] = useState(null);
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const functions = [
    "Gestionnaire de dossiers",
    "Collaboration avec les partenaires",
    "Signature électronique",
    "Planning des interventions",
    "Application mobile pour les techniciens",
    "Générateur d'attestation, devis, factures",
  ];
  const [selectedFunctionnalities, setSelectedFunctionnalities] = useState([]);
  const [secure_open, setSecureOpen] = useState(false);
  const [secure_step, setSecureStep] = useState(0);
  const users = [{ email: "dds@gmail.com" }, { email: "dsdds@gmail.com" }];

  // Fetch user, org, clients, and article data
  const handleFetchData = async () => {
    if (currentUser) {
      try {
        if (!currentUser) {
          navigate("/login");
        }
        // Fetch user data
        const user = await getUserById(currentUser.uid);
        if (user.org_ref && user.email !== "william.bresso@gmail.com") {
          navigate("/home");
        }

        // Fetch organization data using user's org reference

        // Update state with fetched data
        setUserData(user);
        setStep(0);
        getSteps(0);

        let informations = {
          siret: "",
          nom: "",
          adresse: "",
          code_postal: "",
          dirigeant: "",
          ville: "",
          logo: "",
          dob: "",
        };
        setInformations(informations);

        let form = {
          role: "",
          challenges: "",
          kpi: "",
          functionnalities: [],
        };
        setForm(form);
      } catch (error) {
        handleClickVariant(
          "Erreur lors de la récupération des données",
          "error"
        );
        console.error("Error fetching data:", error);
      }
    }
  };

  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const handleFetchQualibat = async (e) => {
    e.preventDefault();
    setLoadingData(true);
    const token = await getOrRefreshFirebaseToken();
    const res = await scrapeQualibat(value, token, prefix);
    if (res) {
      if (res[0] === "true") {
        handleClickVariant("Entreprise trouvée", "success");
        setQualibat_res(res);
        const informations = {
          siret: res[0],
          nom: res[1],
          adresse: res[6],
          code_postal: res[7],
          dirigeant: res[5],
          ville: res[2],
          logo: res[4],
        };
        setInformations(informations);
      } else {
        handleClickVariant("Entreprise non trouvée", "error");
        setValue("");
      }
    }
    setLoadingData(false);
  };

  function isDateFormat(date) {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(date);
  }

  function forceDobFormat(e) {
    let value = e.target.value;

    // Remove all non-digit and non-slash characters
    value = value.replace(/[^\d\/]/g, "");

    // Remove any extra slashes
    value = value.replace(/\/+/g, "/");

    // Automatically insert slashes at the right positions
    if (value.length >= 3 && value[2] !== "/") {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    if (value.length >= 6 && value[5] !== "/") {
      value = value.slice(0, 5) + "/" + value.slice(5);
    }

    // Limit the length to MM/DD/YYYY (10 characters)
    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    e.target.value = value;
    return value;
  }

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleDobChange = (e) => {
    let value = forceDobFormat(e);
    setInformations({
      ...informations,
      dob: value,
    });
  };

  const handleValidateForm = () => {
    if (form["role"] != "" && form["challenges"] != "" && form["kpi"] != "") {
      hanldeChangeStep(4);
      handleClickVariant("Formulaire validé", "success");
    } else {
      handleClickVariant("Veuillez remplir tous les champs", "error");
    }
  };

  const handleValidateInformations = () => {
    if (
      informations["dirigeant"] &&
      informations["dob"] &&
      informations["nom"] &&
      informations["adresse"] &&
      informations["ville"] &&
      informations["code_postal"] &&
      informations["dob"].length === 10 &&
      isDateFormat(informations["dob"])
    ) {
      setStep(3);
      handleClickVariant("Informations validées", "success");
    } else {
      handleClickVariant("Veuillez remplir tous les champs", "error");
    }
  };

  const hanldeChangeStep = (step) => {
    setStep(step);
    getSteps(step);
  };

  const handleSendSms = async () => {
    const infos = gatherDeviceInfo();
    await sendSmsVerificationCall("setup", phone, infos);
    setSecureStep(1);
  };

  const handleCreateOrg = async () => {
    const token = await getOrRefreshFirebaseToken();
    const res = await createNewMaster(
      userData.id,
      informations.nom,
      informations.adresse,
      informations.dob,
      phone,
      informations.ville,
      informations.code_postal,
      informations.dirigeant,
      informations.siret,
      form.role,
      form.challenges,
      form.kpi,
      selectedFunctionnalities,
      prefix,
      token
    );
    handleClickVariant("Organisation créée", "success");
    //navigate("/home");
  };

  const handleCloseModal = () => {
    setSecureOpen(false);
    setSecureStep(0);
  };
  const handleCheckCode = async () => {
    let success = await VerifySmsCodeCall("setup", phone, code);
    if (success) {
      handleCreateOrg();
    } else {
      handleClickVariant("Code SMS incorrect", "error"); // Error notification
      setCode("");
      setPhone("");
    }
  };

  const getSteps = (step) => {
    let steps = [6, 6, 6, 6, 6];
    steps[step] = 100;
    setSteps(steps);
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData(); // Fetch user data when currentUser is available
    }
  }, [currentUser]); // Dependency array ensures this runs when currentUser changes

  if (loading || !currentUser || !userData) {
    return (
      <div className="w-full h-full overflow-auto flex flex-row gap-2 mt-3">
        {/* Left Column Skeleton */}
      </div>
    );
  }

  return (
    <div className="w-full h-full overflow-auto flex flex-col p-5 items-center">
      <Modal isOpen={secure_open} onClose={handleCloseModal}>
        <div className="bg-white rounded-3xl w-c300 flex flex-col p-4 gap-2">
          {secure_step === 0 && (
            <>
              <div className="text-h2"> Entrez votre numéro de téléphone</div>
              <TextField2
                label="Téléphone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></TextField2>
              <div className="flex flex-row gap-2 items-center">
                <SecondaryButton onClick={handleCloseModal}>
                  Annuler
                </SecondaryButton>
                <PrimaryButton onClick={handleSendSms} className="flex-grow">
                  Envoyer code SMS
                </PrimaryButton>
              </div>
            </>
          )}
          {secure_step === 1 && (
            <>
              <div className="text-h2"> Entrez le code reçu par SMS</div>
              <TextField2
                label="Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              ></TextField2>
              <div className="flex flex-row gap-2 items-center">
                <PrimaryButton onClick={handleCheckCode} className="flex-grow">
                  Vérifier
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
      </Modal>
      {/* Left Column: Takes all available space */}
      <div className="flex flex-row w-full items-start">
        <div className="flex flex-row border-1 border-shadow_10 rounded-full shadow-md gap-2 w-fit p-2">
          {steps.map((step, index) => (
            <div
              key={index}
              className=" bg-black rounded-full"
              style={{ width: `${step}px`, height: "6px" }}
            ></div>
          ))}
        </div>
      </div>
      <Space y={15} />
      {step !== 0 && (
        <div className="flex flex-row w-full items-start">
          <div
            className="flex flex-row border-1 border-shadow_25 rounded-full text-shadow_50 text-h2 gap-2 w-fit px-2 cursor-pointer"
            onClick={() => hanldeChangeStep(step - 1)}
          >
            Retour
          </div>
        </div>
      )}

      <div className="flex flex-col h-full  justify-center">
        {step === 0 && (
          <div className="bg-white rounded-3xl p-4 w-c600  shadow-xl flex flex-col">
            <div className="text-hx3 font-semibold text-center">Bonjour</div>{" "}
            <Space y={10} />
            <img
              src="https://picsum.photos/seed/816/600"
              className="object-cover w-full h-c300 object-top     rounded-3xl      
            "
            ></img>{" "}
            <Space y={10} />
            <div className="text-h3 text-shadow_50 text-center">
              Durée setup - 3 min
            </div>
            <Space y={10} />
            <div className="items-center w-full justify-center flex flex-row gap-2">
              {" "}
              <SetupButton
                onClick={() => hanldeChangeStep(1)}
                className="w-fit"
              >
                Continuer
              </SetupButton>
            </div>
          </div>
        )}
        {step === 1 && (
          <div className="bg-white rounded-3xl p-4 w-fit  shadow-xl flex flex-col">
            <div className="flex flex-col items-center">
              <div className="flex flex-row gap-2 items-center w-full justify-center">
                <Icon1
                  icon_name="search"
                  fill="#000"
                  height="25px"
                  width="25px"
                ></Icon1>
                <div className="text-hx font-semibold text-center">
                  Business Link
                </div>{" "}
              </div>
              <Space y={10} />
              <div className="text-h3 text-shadow_50 text-center">
                Entrez votre SIRET ci-dessous
              </div>
              <Space y={20} />
              <div className="flex flex-row bg-white rounded-3xl shadow-lg gap-2 w-c250 items-center px-2">
                <Icon1 icon_name="search" fill="rgba(0,0,0,0.5)"></Icon1>{" "}
                <TextFieldBorderLess
                  placeholder="SIRET ..."
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onEnter={handleFetchQualibat}
                ></TextFieldBorderLess>
              </div>
              <Space y={20} />

              {!loadingData && !qualibat_res && (
                <div className="text-h3 text-shadow_50 text-center">
                  +10 000 entreprises
                </div>
              )}

              {loadingData && (
                <div className="flex flex-row text-h3 text-shadow_50 text-center gap-2 items-center">
                  <Spinner loading={true}></Spinner>
                  Recherche de l'entreprise
                </div>
              )}
              {qualibat_res && (
                <>
                  <div className="flex flex-row gap-2 items-center border-1 border-shadow_10 rounded-2xl px-2 w-full justify-between">
                    <img
                      src={qualibat_res[4]}
                      className="object-contain w-20 h-20 rounded-xl "
                    ></img>
                    <div className="flex flex-col">
                      <div className="text-h3 font-semibold">
                        {qualibat_res[1]}
                      </div>
                      <div className="text-h3">{qualibat_res[2]}</div>
                      <div className="text-h3">{qualibat_res[5]}</div>
                    </div>
                  </div>
                </>
              )}
              <Space y={10} />
              {value && (
                <div className="items-center w-full justify-center flex flex-row gap-2">
                  {" "}
                  <SetupButton
                    onClick={() => hanldeChangeStep(2)}
                    className="w-fit"
                  >
                    Sélectionner
                  </SetupButton>
                </div>
              )}
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="bg-white rounded-3xl p-4 w-fit  shadow-xl flex flex-col">
            <div className="flex flex-col items-center">
              <div className="flex flex-row gap-2 items-center w-full justify-center">
                <div className="text-hx font-semibold text-center">
                  Informations
                </div>{" "}
              </div>
              <Space y={10} />
              <div className="text-h3 text-shadow_50 text-center w-c250">
                Entrez les informations manquantes de votre entreprise
                ci-dessous
              </div>
              <Space y={20} />
              <div className="flex flex-col gap-3 w-full">
                <TextField2
                  label="Prénom Nom"
                  value={informations["dirigeant"]}
                  onChange={(e) =>
                    setInformations({
                      ...informations,
                      dirigeant: e.target.value,
                    })
                  }
                ></TextField2>
                <TextField2
                  label="Date de naissance"
                  value={informations["dob"]}
                  onChange={handleDobChange}
                ></TextField2>
                <TextField2
                  label="Entreprise"
                  value={informations["nom"]}
                  onChange={(e) =>
                    setInformations({
                      ...informations,
                      nom: e.target.value,
                    })
                  }
                ></TextField2>
                <TextField2
                  label="Adresse"
                  value={informations["adresse"]}
                  onChange={(e) =>
                    setInformations({
                      ...informations,
                      adresse: e.target.value,
                    })
                  }
                ></TextField2>
                <TextField2
                  label="Ville"
                  value={informations["ville"]}
                  onChange={(e) =>
                    setInformations({
                      ...informations,
                      ville: e.target.value,
                    })
                  }
                ></TextField2>
                <TextField2
                  label="Code Postal"
                  value={informations["code_postal"]}
                  onChange={(e) =>
                    setInformations({
                      ...informations,
                      code_postal: e.target.value,
                    })
                  }
                ></TextField2>
              </div>

              <Space y={20} />

              <div className="items-center w-full justify-center flex flex-row gap-2">
                {" "}
                <SetupButton
                  onClick={handleValidateInformations}
                  className="w-fit"
                >
                  Continuer
                </SetupButton>
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="bg-white rounded-3xl p-4 w-fit  shadow-xl flex flex-col">
            <div className="flex flex-col items-center">
              <div className="flex flex-row gap-2 items-center w-full justify-center">
                <div className="text-hx font-semibold text-center">
                  Questions
                </div>{" "}
              </div>
              <Space y={20} />
              <div className="text-h3 text-shadow_50 w-c350">
                Répondez aux questions suivantes
              </div>
              <Space y={10} />
              <div className="flex flex-col gap-3 w-full">
                <TextField2
                  label="Rôle dans votre organisation ?"
                  value={form["role"]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      role: e.target.value,
                    })
                  }
                ></TextField2>
                <TextField2
                  label="Défis rencontrés ?"
                  value={form["challenges"]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      challenges: e.target.value,
                    })
                  }
                ></TextField2>
                <TextField2
                  label="Comment allez vous mesurer le succès ?"
                  value={form["kpi"]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      kpi: e.target.value,
                    })
                  }
                ></TextField2>
                <Space y={10} />
                <div className="flex flex-col gap-0">
                  <div className="text-h3 text-shadow_50">
                    Fonctionnalités souhaitées
                  </div>
                  <Space y={5} />
                  {functions.map((functionnality) => (
                    <div className="flex flex-row items-center text-h3 h-7">
                      <CheckBoxGrey
                        checked={selectedFunctionnalities.includes(
                          functionnality
                        )}
                        handleChange={() => {
                          if (
                            selectedFunctionnalities.includes(functionnality)
                          ) {
                            setSelectedFunctionnalities(
                              selectedFunctionnalities.filter(
                                (item) => item !== functionnality
                              )
                            );
                          } else {
                            setSelectedFunctionnalities([
                              ...selectedFunctionnalities,
                              functionnality,
                            ]);
                          }
                        }}
                      ></CheckBoxGrey>
                      {functionnality}
                    </div>
                  ))}
                </div>
              </div>

              <Space y={20} />

              <div className="items-center w-full justify-center flex flex-row gap-2">
                {" "}
                <SetupButton onClick={handleValidateForm} className="w-fit">
                  Continuer
                </SetupButton>
              </div>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className="bg-white rounded-3xl p-4 w-fit  shadow-xl flex flex-col">
            <div className="flex flex-col items-center">
              <div className="flex flex-row gap-2 items-center w-full justify-center">
                <div className="text-hx font-semibold text-center">
                  Sécurité
                </div>{" "}
              </div>
              <Space y={20} />
              <div className="text-h3 text-shadow_50 w-c250 text-center">
                Suivez la procédure pour sécuriser votre compte avec votre
                numéro de téléphone
              </div>

              <Space y={20} />

              <div className="items-center w-full justify-center flex flex-row gap-2">
                {" "}
                <SetupButton
                  onClick={() => setSecureOpen(true)}
                  className="w-fit flex flex-row items-center gap-2"
                >
                  {" "}
                  <Icon1
                    icon_name="clé"
                    fill="#fff"
                    height="20px"
                    width="20px"
                  ></Icon1>
                  Sécuriser
                </SetupButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Setup;
