import React from "react";
import { Box } from "@mui/material";
import ListCell from "./ListCell";

const ListView = ({ users, removable }) => {
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "8px",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: "1.5px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0)",
        overflow: "hidden",
      }}
    >
      {/* Map through users and render ListCell for each */}
      {users.map((user) => (
        <ListCell key={user.uid} user={user} removable={removable} />
      ))}
    </Box>
  );
};

export default ListView;
