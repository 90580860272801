import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument.js"; // Assuming a custom log function

async function createNewMaster(
  uid,
  entreprise,
  adresse,
  dob,
  phone,
  ville,
  postal,
  fullname,
  siret,
  role,
  problem,
  kpi,
  fonctionnalites,
  prefix,
  token
) {
  // Define the endpoint URL
  const url = prefix + "organisations/master";

  let day = 0;
  let month = 0;
  let year = 0;

  // Parse date of birth
  const dobArray = dob.split("/");
  if (dobArray.length === 3) {
    day = parseInt(dobArray[0], 10);
    month = parseInt(dobArray[1], 10);
    year = parseInt(dobArray[2], 10);
  }

  // Define the request body
  const body = {
    uid: uid,
    entreprise: entreprise,
    adresse: adresse,
    dob: {
      day: day,
      month: month,
      year: year,
    },
    phone: phone,
    ville: ville,
    postal: postal,
    fullname: fullname,
    siret: siret,
    role: role,
    problem: problem,
    kpi: kpi,
    fonctionnalites: fonctionnalites,
  };

  // Define headers, including authorization token
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    // Get the current user
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User not logged in");
      return null;
    }

    // Get Firestore instance
    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return null;
    }

    const orgRef = userSnapshot.get("org_ref");
    const responseText = await response.text();

    // Log the request and response using addLogDocument
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "", // Additional fields if necessary
      "",
      JSON.stringify(body), // Request body as a string
      responseText,
      "POST"
    );

    // Check if the request was successful
    if (response.ok) {
      const dict = JSON.parse(responseText);
      const documentId = dict["id"];

      // Return the Firestore document reference for the created master
      return doc(db, "organisations", documentId);
    } else {
      console.error(`Error: ${response.status}`);
      return null;
    }
  } catch (error) {
    // Handle exceptions
    console.error("Error:", error);
    return null;
  }
}

export default createNewMaster;
