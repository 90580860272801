import React, { createContext, useContext, useState, useEffect } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  // Define initial state
  const initialState = {
    user: null,
    theme: "light",
    dev: false,
    dev_url: "http://127.0.0.1:8000/",
    prod_url: "https://api.hexagone.expert/",
    // Add other state variables as needed
  };

  // Load the initial state from localStorage or set default values
  const [appState, setAppState] = useState(() => {
    const savedState = localStorage.getItem("appState");
    return savedState ? JSON.parse(savedState) : initialState;
  });

  // Save appState to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("appState", JSON.stringify(appState));
  }, [appState]);

  // Function to update the prod_url in appState
  const updateProdUrl = (newUrl) => {
    setAppState((prevState) => ({
      ...prevState,
      prod_url: newUrl,
    }));
  };

  // Reset function to restore appState to initial values
  const resetAppState = () => {
    setAppState(initialState);
  };

  return (
    <AppContext.Provider
      value={{ appState, setAppState, updateProdUrl, resetAppState }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
