import addLogDocument from "../addLogDocument.js"; // Assuming a custom log function

const createNewUser = async (email, password, key, prefix) => {
  // Define the endpoint URL
  const url = prefix + "signin";

  // Define the request body
  const body = {
    email: email,
    password: password,
    key: key,
  };

  // Define headers
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    // Make the POST request using the built-in fetch API
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    const responseText = await response.text();

    // Check if the request was successful
    if (response.ok) {
      const responseData = JSON.parse(responseText);
      console.log(responseData);
      return responseData;
    } else {
      console.log(`Failed to create user: ${response.status}`);
      return "";
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return "";
  }
};

export default createNewUser;
