import React from "react";
import Row from "../layout/Row";

function InfoCell({ title, data }) {
  return (
    <Row>
      <div className="text-h3 font-medium w-c100">{title}</div>
      <div className="text-h3 rounded-md py-1 h-7 px-2 mr-2  flex-grow   font-light text-pr bg-shadow_4">
        {data}
      </div>
    </Row>
  );
}

export default InfoCell;
