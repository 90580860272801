import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming you have this function for logging
import axios from "axios";
import { fileOpen } from "browser-fs-access"; // Used for file picker

const sendTamponDevis = async (oid, keyid, prefix) => {
  try {
    // Select image file using file picker
    const file = await fileOpen({
      description: "Select an image",
      mimeTypes: ["image/*"], // Only allow images
      multiple: false, // Only one file
    });

    if (file) {
      const fileBytes = await file.arrayBuffer(); // Read file as bytes
      const fileName = file.name;

      const url = prefix + "upload/hexasign-tampon-devis";

      // Create a new FormData object
      const formData = new FormData();
      formData.append("file", new Blob([fileBytes]), fileName); // Append the file
      formData.append("oid", oid); // Append oid field
      formData.append("keyid", keyid); // Append keyid field

      // Send the POST request using axios
      const response = await axios.post(url, formData);

      // Check response status
      if (response.status === 200) {
        const jsonResponse = response.data;

        // Extract and return the URL from the response
        const returnedUrl = jsonResponse.url;
        return returnedUrl;
      } else {
        console.error(`Error response: ${response.status}`);
        return "";
      }
    } else {
      // File selection was canceled
      console.log("File selection canceled.");
      return "";
    }
  } catch (error) {
    console.error(`Error selecting/uploading file: ${error.message}`);
    return "";
  }
};

export default sendTamponDevis;
