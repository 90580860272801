import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming a custom log function

const validateDocument = async (
  docid = null,
  folderid,
  type,
  token,
  prefix
) => {
  // Define the endpoint URL
  let url = "";
  if (type === "doc") {
    url = prefix + `folders/${folderid}/documents/${docid}/validate`;
  } else {
    url = prefix + `folders/${folderid}/${type}/validate`;
  }

  // Define the request body
  const body = {
    type: type,
  };

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    // Check for user authentication using Firebase
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return "User not logged in";
    }

    // Access Firestore and get user details
    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      return "User document not found";
    }

    const orgRef = userSnapshot.get("org_ref");

    // Log the request and response
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "", // Optional: additional field if necessary
      "",
      JSON.stringify(body), // Logs the request body as string
      await response.text() // Logs the response body as string
    );

    // Check if the request was successful
    if (response.ok) {
      return "Document validated successfully";
    } else {
      console.error(`Failed to validate document: ${response.status}`);
      return `Failed to validate document: ${response.status}`;
    }
  } catch (error) {
    // Handle any error that occurs during the fetch process
    console.error(`Exception occurred: ${error.message}`);
    return `Exception: ${error.message}`;
  }
};

export default validateDocument;
