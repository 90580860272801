import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import SRSIconButton from "../buttons/SRSIconButton";
import validateDocument from "../api/folder/validateDocument";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken";
import refuseFolder from "../api/folder/refuseFolder";
import PrimaryButton from "../buttons/PrimaryButton";
import { TruncatedString } from "../models/DataFunctions";
import SecondaryButton from "../buttons/SecondaryButton";
import { BackgroundColor } from "devextreme-react/cjs/chart";

// Worker URL should match the version of the pdfjs-dist package
const workerUrl =
  "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

const PDFSign = ({ files = [], onClose, prefix }) => {
  const [selectedPdf, setSelectedPdf] = useState(files[1].url);

  // Refresh the PDF URLs

  return (
    <div className="flex flex-row w-full h-full justify-between rounded-xl">
      {/* PDF Buttons Section */}
      <div className="flex flex-col w-full p-4">
        {/* Full PDF Viewer Section */}
        <Box
          flex={2}
          p={2}
          sx={{
            height: "100%",
            overflowY: "auto", // Only make this section scrollable
          }}
        >
          {selectedPdf ? (
            <Worker workerUrl={workerUrl}>
              <Box
                sx={{
                  height: "calc(100vh - 300px)", // Ensure the viewer is scrollable
                  overflow: "auto", // Make the PDF viewer itself scrollable
                }}
              >
                {/* Render the selected PDF */}
                <Viewer fileUrl={selectedPdf} defaultScale={1.3} />
              </Box>
            </Worker>
          ) : (
            <Typography variant="body1">
              Veuillez sélectionner un PDF
            </Typography>
          )}
        </Box>
      </div>

      {/* Actions Sidebar */}
    </div>
  );
};

export default PDFSign;
