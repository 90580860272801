import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming you have a function for logging

const sendInfiltreaCreateOperation = async (
  folderid,
  memberid,
  token,
  prefix
) => {
  // Define the endpoint URL
  const url = prefix + "infiltrea/create-operation";

  // Define the request body
  const body = {
    folderid: folderid,
    memberid: memberid,
  };

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request using fetch API
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Convert body to JSON string
    });

    // Check if the response is okay
    if (!response.ok) {
      console.error(`Failed to create operation: ${response.status}`);
      return `Failed to create operation: ${response.status}`;
    }

    // Parse the response data
    const responseData = await response.json();

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User not logged in");
      return "User not logged in";
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return "User document not found";
    }

    const orgRef = userSnapshot.get("org_ref");

    // Add log document
    await addLogDocument(
      url,
      userRef.id, // Log the userRef ID
      orgRef ? orgRef.id : null, // Log orgRef ID if available
      response.status.toString(), // Response status
      "", // folderid (if needed for logging)
      "", // docid (if needed for logging)
      JSON.stringify(body), // Request body as string,
      "POST"
      // Response body as string
    );

    // Return the operation ID if the response is successful
    const { detail } = responseData;
    const id = detail?.id || "";
    return id;
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return `Exception occurred: ${error.message}`;
  }
};

export default sendInfiltreaCreateOperation;
