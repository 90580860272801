import React from "react";
import PlanMarket from "./PlanMarket";
import SignMarket from "./SignMarket";
import AppMarket from "./AppMarket";
import Support from "./Support";
import Tarifs from "./Tarifs";

const SuperModal = ({ isOpen, onClose, modal_name, extra }) => {
  if (!isOpen) return null; // If modal is not open, return nothing

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the modal if clicked on the backdrop
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 cursor-default"
      onClick={handleBackdropClick}
    >
      <div
        className="bg-transparent rounded-lg flex-col shadow-lg w-fit p-0 flex align-middle items-center relative cursor-auto"
        onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing it
      >
        <div className=" items-center flex">
          {modal_name === "plan_market" && (
            <PlanMarket onClose={onClose}></PlanMarket>
          )}
          {modal_name === "sign_market" && (
            <SignMarket onClose={onClose}></SignMarket>
          )}
          {modal_name === "app_market" && (
            <AppMarket onClose={onClose}></AppMarket>
          )}
          {modal_name === "support" && <Support onClose={onClose}></Support>}
          {modal_name === "tarifs_negoced" && (
            <Tarifs onClose={onClose} extra={extra}></Tarifs>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperModal;
