import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextField1 from "../textfields/TextField1";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import HexagoneTitle from "../hexagone/HexagoneTitle";
import Icon from "../icons/Icon";
import Box1 from "../boxs/Box1";
import Row from "../layout/Row";
import MenuButton from "../buttons/MenuButton";
import StackBottomRight from "../layout/StackBottomRight";
import { useNavigate } from "react-router-dom";
import RoundedButton from "../buttons/RoundedButton";
import HexagoneHeader from "../hexagone/HexagoneHeader";
import Space from "../layout/Space";

function Entree({ text, action }) {
  const navigate = useNavigate(); // Hook to handle navigation

  const goLogin = () => {
    navigate("/login"); // Navigate to the next page
  };

  const goSignin = () => {
    navigate("/signin"); // Navigate to the next page
  };

  return (
    <div className="h-screen">
      <div className="h-full bg-slate-50 items-center flex justify-center flex-col">
        <Box1>
          <HexagoneHeader />
          <Space y={5} />
          <Row x={10}>
            <RoundedButton color="secondary" onClick={goSignin}>
              Commencer
            </RoundedButton>
            <RoundedButton color="black" onClick={goLogin}>
              Se Connecter
            </RoundedButton>
          </Row>
        </Box1>
        <StackBottomRight>
          <div className="bg-black px-3 py-1 rounded-full text-f_white text-sm">
            Beta
          </div>
        </StackBottomRight>
      </div>
    </div>
  );
}

export default Entree;
