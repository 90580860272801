import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css"; // Optional if you have some styles to include
import App from "./config/App";
import { frFR } from "@mui/material/locale"; // Import French locale
import app from "./config/FirebaseConfig";
import { getAuth } from "firebase/auth";
import { useAuth } from "./auth/FirebaseAuth";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
const auth = getAuth(app);

// Import Material UI components
import { lightTheme, darkTheme } from "./config/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { NotificationStyle } from "./notification/NotificationStyle";
import { ConfigProvider } from "antd";

import frFR2 from "antd/es/locale/fr_FR";
import { AppProvider } from "./config/AppContext";

import LogRocket from "logrocket";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Wrap the application with ThemeProvider
LogRocket.init("fnyltv/hexagone");

root.render(
  <ThemeProvider theme={lightTheme}>
    {/* CssBaseline resets default browser styles for consistency */}
    <CssBaseline />
    <SnackbarProvider
      maxSnack={8} // Wrap App with SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // Customize position
      locale={frFR} // Set French locale
      Components={{
        success: NotificationStyle,
        error: NotificationStyle,
      }}
    >
      <AppProvider>
        <ConfigProvider locale={frFR2}>
          <Router>
            <App />
          </Router>
        </ConfigProvider>
      </AppProvider>
    </SnackbarProvider>
  </ThemeProvider>
);
