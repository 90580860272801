import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, collection } from "firebase/firestore";
import addLogDocument from "../addLogDocument.js"; // Assuming you have a similar function to log actions

async function createNewFolder(
  interventiondone,
  postal,
  pack,
  ville,
  nom,
  comments,
  emailclient,
  phoneclient,
  clientmemberid,
  date,
  heure,
  minute,
  startdate,
  enddate,
  clientId,
  packid,
  master_id,
  author_id,
  token,
  prefix
) {
  // Define the endpoint URL
  const url = prefix + "folders";

  // Define the request body
  const body = {
    postal: postal,
    pack: pack,
    packid: packid,
    ville: ville,
    nom: nom,
    comments: comments,
    date: date,
    heure: heure,
    minute: minute,
    client: clientId,
    oid: master_id,
    interventiondone: interventiondone || false,
    emailclient: emailclient,
    phoneclient: phoneclient,
    clientmemberid: clientmemberid || "vide",
    startdate: startdate || "../../....",
    enddate: enddate || "../../....",
    author_id: author_id,
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    // Get the current user
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User not logged in");
      return null;
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return null;
    }

    const orgRef = userSnapshot.get("org_ref");
    const responseText = await response.text();

    // Add log document (assuming addLogDocument is a utility function you have)
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "",
      "",
      JSON.stringify(body),
      responseText,
      "POST"
    );

    // Check if the request was successful
    if (response.ok) {
      const dict = JSON.parse(responseText);
      const documentId = dict["id"];

      // Return the Firestore document reference
      return doc(db, "folder", documentId);
    } else {
      console.error(`Error: ${response.status}`);
      return null;
    }
  } catch (error) {
    // Handle exceptions
    console.error("Error:", error);
    return null;
  }
}

export default createNewFolder;
