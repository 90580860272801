import React from "react";
import Box from "@mui/material/Box";

function Column({ className, children, align = "center", y = 2, sx }) {
  let justifyContent = "center"; // Default is center alignment

  if (align === "left") {
    justifyContent = "flex-start";
  } else if (align === "center") {
    justifyContent = "center";
  }

  return (
    <Box
      className={className}
      sx={{
        display: "flex",

        flexDirection: "column",
        alignItems: justifyContent,
        gap: `${y}px`, // Adjust spacing between items
        width: "100%", // Ensures it takes full width if needed
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default Column;
