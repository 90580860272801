import React, { useState, useEffect, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Entree from "../pages/Entree";
import Bordel from "../pages/Bordel";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ProtectedRoute from "../auth/ProtectedRoute";
import Redirect from "../pages/Redirect";
import NavBar from "../hexagone/NavBar";
import { useAuth } from "../auth/FirebaseAuth";
import { Divider } from "@mui/material";
import Row from "../layout/Row";
import ConiditionColumn from "../layout/ConditionColumn";
import HeaderBar from "../hexagone/HeaderBar";
import ParametersNavBar from "../hexagone/ParametersNavBar";
import NewDemand from "../pages/NewDemand";
import Setup from "../pages/Setup";
import InvoiceSign from "../pages/InvoiceSign";
import Signin from "../pages/Signin";

// Lazy-loaded components
const Clients = React.lazy(() => import("../pages/Clients"));
const Client = React.lazy(() => import("../pages/Client"));
const Experts = React.lazy(() => import("../pages/Experts"));
const Expert = React.lazy(() => import("../pages/Expert"));
const NewClient = React.lazy(() => import("../pages/NewClient"));
const NewExpert = React.lazy(() => import("../pages/NewExpert"));
const ModifyClient = React.lazy(() => import("../pages/ModifyClient"));
const ModifyExpert = React.lazy(() => import("../pages/ModifyExpert"));
const Folders = React.lazy(() => import("../pages/Folders"));
const Folder = React.lazy(() => import("../pages/Folder"));
const NewFolder = React.lazy(() => import("../pages/NewFolder"));
const ModifyFolder = React.lazy(() => import("../pages/ModifyFolder"));
const Planning = React.lazy(() => import("../pages/Planning"));
const Parameters = React.lazy(() => import("../pages/parameters/Parameters"));
const Users = React.lazy(() => import("../pages/Users"));
const User = React.lazy(() => import("../pages/User"));
const Logs = React.lazy(() => import("../pages/Logs"));
const Invoices = React.lazy(() => import("../pages/Invoices"));
const Invoice = React.lazy(() => import("../pages/Invoice"));
const NewInvoice = React.lazy(() => import("../pages/NewInvoice"));

const x_pages = [
  "home",
  "client",
  "expert",
  "folder",
  "planning",
  "user",
  "logs",
  "parameters",
  "devis",
  "facturation",
]; // Array of pages to show the NavBar

const getPageFromPath = (pathname) => {
  if (pathname === "/home") return "home";
  if (pathname === "/bordel") return "bordel";
  if (pathname === "/entree") return "entree";
  if (pathname === "/newclient") return "client";
  if (pathname === "/newexpert") return "expert";
  if (pathname === "/clients") return "client";
  if (pathname === "/login") return "login";
  if (pathname === "/experts") return "expert";
  if (pathname === "/folders") return "folder";
  if (pathname === "/newfolder") return "folder";
  if (pathname === "/parameters") return "parameters";
  if (pathname === "/organisation") return "parameters";
  if (pathname === "/account") return "parameters";
  if (pathname === "/facturation") return "parameters";
  if (pathname === "/boutique") return "parameters";
  if (pathname === "/infiltrea") return "parameters";
  if (pathname === "/devisparams") return "parameters";
  if (pathname === "/products") return "parameters";
  if (pathname === "/email") return "parameters";
  if (pathname === "/tags") return "parameters";

  // Use a regular expression to match dynamic paths like /client/:id
  if (/^\/client\/[a-zA-Z0-9]+$/.test(pathname)) {
    return "client";
  }
  if (/^\/modifyclient\/[a-zA-Z0-9]+$/.test(pathname)) {
    return "client";
  }
  if (/^\/expert\/[a-zA-Z0-9]+$/.test(pathname)) {
    return "expert";
  }
  if (/^\/modifyexpert\/[a-zA-Z0-9]+$/.test(pathname)) {
    return "expert";
  }
  if (/^\/folder\/[a-zA-Z0-9]+$/.test(pathname)) {
    return "folder";
  }
  if (/^\/modifyfolder\/[a-zA-Z0-9]+$/.test(pathname)) {
    return "folder";
  }
  if (pathname === "/planning") return "planning";
  if (pathname === "/users") return "user";
  if (/^\/user\/[a-zA-Z0-9]+$/.test(pathname)) {
    return "user";
  }
  if (pathname === "/logs") return "logs";
  if (pathname === "/invoices") return "devis";
  if (pathname === "/newinvoice") return "devis";
  if (/^\/invoice\/[a-zA-Z0-9]+$/.test(pathname)) {
    return "devis";
  }
  if (pathname === "/newdemand") return "folder";
  if (pathname === "/tempo-parameters") return "facturation";

  return ""; // Default value for unknown pages
};

// Array of pages to show the NavBar

function App() {
  const location = useLocation();
  const actualpage = getPageFromPath(location.pathname);
  let isinside = x_pages.includes(actualpage);
  let isinparameters = actualpage === "parameters";

  return (
    <div className="bg-slate-50 w-full h-full">
      <Row
        x={0}
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "hidden",
          width: "100%",
          alignItems: "stretch",
        }}
        id="MyDiv"
      >
        {isinside && <NavBar actualpage={actualpage} />}
        {isinside && <Divider orientation="vertical" />}
        {isinparameters && <ParametersNavBar actualpage={actualpage} />}
        {isinparameters && <Divider orientation="vertical" />}

        <ConiditionColumn isinside={isinside}>
          {isinside && <HeaderBar />}

          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Redirect />} />
              <Route path="/bordel" element={<Bordel />} />
              <Route path="/entree" element={<Entree />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signin" element={<Signin />} />
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/clients"
                element={
                  <ProtectedRoute>
                    <Clients />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newclient"
                element={
                  <ProtectedRoute>
                    <NewClient />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/modifyclient/:id"
                element={
                  <ProtectedRoute>
                    <ModifyClient />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/client/:id"
                element={
                  <ProtectedRoute>
                    <Client />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/experts"
                element={
                  <ProtectedRoute>
                    <Experts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newexpert"
                element={
                  <ProtectedRoute>
                    <NewExpert />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/modifyexpert/:id"
                element={
                  <ProtectedRoute>
                    <ModifyExpert />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/expert/:id"
                element={
                  <ProtectedRoute>
                    <Expert />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/folders"
                element={
                  <ProtectedRoute>
                    <Folders />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newfolder"
                element={
                  <ProtectedRoute>
                    <NewFolder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newdemand"
                element={
                  <ProtectedRoute>
                    <NewDemand />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/modifyfolder/:id"
                element={
                  <ProtectedRoute>
                    <ModifyFolder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/folder/:id"
                element={
                  <ProtectedRoute>
                    <Folder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/planning"
                element={
                  <ProtectedRoute>
                    <Planning />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute requiredRole={"admin"}>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user/:id"
                element={
                  <ProtectedRoute requiredRole={"admin"}>
                    <User />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/logs"
                element={
                  <ProtectedRoute requiredRole={"admin"}>
                    <Logs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/parameters"
                element={
                  <ProtectedRoute>
                    <Parameters page="Account" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <Parameters page="Account" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/organisation"
                element={
                  <ProtectedRoute>
                    <Parameters page="Organisation" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/devisparams"
                element={
                  <ProtectedRoute>
                    <Parameters page="DevisParams" />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/facturation"
                element={
                  <ProtectedRoute>
                    <Parameters page="Facturation" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/boutique"
                element={
                  <ProtectedRoute>
                    <Parameters page="Boutique" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/infiltrea"
                element={
                  <ProtectedRoute>
                    <Parameters page="Infiltrea" />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/products"
                element={
                  <ProtectedRoute>
                    <Parameters page="Products" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/email"
                element={
                  <ProtectedRoute>
                    <Parameters page="Email" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tags"
                element={
                  <ProtectedRoute>
                    <Parameters page="Tags" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/invoices"
                element={
                  <ProtectedRoute>
                    <Invoices />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/newinvoice"
                element={
                  <ProtectedRoute>
                    <NewInvoice />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/invoice/:id"
                element={
                  <ProtectedRoute>
                    <Invoice />
                  </ProtectedRoute>
                }
              />
              <Route path="/invoice-sign/:id" element={<InvoiceSign />} />

              <Route
                path="/setup"
                element={
                  <ProtectedRoute needs_org={false}>
                    <Setup />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Suspense>
        </ConiditionColumn>
      </Row>
    </div>
  );
}

export default App;
