import React, { useState, useImperativeHandle, forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import Icon1 from "../icons/Icon";

const Accordion2 = forwardRef(({ title, children }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const theme = useTheme();

  // Expose the toggleAccordion method to parent components
  useImperativeHandle(ref, () => ({
    toggleAccordion,
    openAccordion: () => setIsOpen(true), // Optional: Open the accordion externally
    closeAccordion: () => setIsOpen(false), // Optional: Close the accordion externally
  }));

  return (
    <div className="h-fit w-full">
      <div
        style={{
          width: "100%",
          border: "1px solid",
          borderColor: isOpen
            ? theme.palette.primary.light
            : theme.palette.shadow._25,
          borderRadius: "12px",
          overflow: "hidden",
          margin: "0px",
          padding: isOpen ? "8px" : "2px",
          backgroundColor: isOpen ? "#fff" : isHovered ? "#fff" : "transparent",
          boxSizing: "border-box",
          boxShadow: "#fff",
        }}
      >
        {/* Accordion Header */}
        <div
          style={{
            backgroundColor: isHovered ? "rgba(0,0,0,0.02)" : "transparent",
            padding: "5px",
            cursor: "pointer",
            fontWeight: 500,
          }}
          onClick={toggleAccordion}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="flex flex-row items-center justify-between">
            {isOpen ? (
              <div className="text-primary_l">{title}</div>
            ) : (
              <div className="text-shadow_50">{title}</div>
            )}
            {isOpen ? (
              <Icon1
                icon_name="up"
                fill={theme.palette.shadow._25}
                height="20px"
                width="20px"
              ></Icon1>
            ) : (
              <Icon1
                icon_name="down"
                fill={theme.palette.shadow._25}
                height="20px"
                width="20px"
              ></Icon1>
            )}
          </div>
        </div>

        {/* Accordion Content */}
        <div
          style={{
            display: isOpen ? "block" : "none",
            padding: "5px",
            backgroundColor: "transparent",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
});

export default Accordion2;
