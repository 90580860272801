import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument.js"; // Assuming a custom log function similar to FlutterFlow

const stripeMobileSubManager = async (number, token, prefix) => {
  // Define the endpoint URL
  const url = prefix + `stripe/mobile-subscription/${number}`;

  // Define the request body
  const body = {};

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return "User not logged in";
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return "";
    }

    const orgRef = userSnapshot.get("org_ref");

    const responseText = await response.text();

    // Add log document
    await addLogDocument(
      url,
      userRef, // Only log the userRef ID
      orgRef, // Only log the orgRef ID
      response.status.toString(),
      "", // folderid
      "", // docid
      JSON.stringify(body), // request body as string
      responseText
    );

    // Check if the request was successful
    if (response.ok) {
      const responseData = JSON.parse(responseText);
      const url = responseData.url ?? "done";
      return url; // Handle successful response
    } else {
      // Handle error response
      console.error(`Failed to manage subscription: ${response.status}`);
      return "";
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return "";
  }
};

export default stripeMobileSubManager;
