import { getAuth } from "firebase/auth"; // Firebase Authentication
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Firebase Firestore

const modifThermal = async (oid, token, folderid, prefix) => {
  // Define the endpoint URL
  const url = prefix + "modify/thermique";

  // Define the request body
  const body = JSON.stringify({
    docs: "",
    oid: oid,
    folderid: folderid,
  });

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request using native fetch API
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });

    // Firebase Authentication to get the current user
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User not logged in");
      return "";
    }

    // Firebase Firestore: Access user document
    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return "";
    }

    // Get organization reference from user document
    const orgRef = userSnapshot.data().org_ref;

    // Log the request and response (assuming log function is implemented)
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      folderid,
      "",
      body,
      await response.text(),
      "POST"
    );

    // Check if the request was successful
    if (response.ok) {
      return "Nice";
    } else {
      return `Failed to modify thermal data: ${response.status}`;
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return `Exception occurred: ${error.message}`;
  }
};

export default modifThermal;
