import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "./addLogDocument.js"; // Assuming a custom log function

// Define the structure for FAQ questions
class FaqQuestionStruct {
  constructor(question, answer) {
    this.question = question;
    this.answer = answer;
  }
}

const getFAQ = async (token, prefix) => {
  // Define the endpoint URL
  const url = prefix + "actions/get-faq";

  // Define the request body
  const body = {};

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request using the browser's native fetch API
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return null;
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      return null;
    }

    const orgRef = userSnapshot.get("org_ref");
    const responseText = await response.text();

    // Add log document
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "", // Optional additional fields
      "",
      JSON.stringify(body), // Request body as string
      responseText
    );

    // Check if the request was successful
    if (response.ok) {
      // Handle successful response
      const responseData = JSON.parse(responseText);
      const questions = responseData.questions;

      // Map the questions to the structure
      const allQuestions = questions.map(
        (que) => new FaqQuestionStruct(que.question, que.answer)
      );

      return allQuestions;
    } else {
      // Handle error response
      console.log(`Failed to get FAQ: ${response.status}`);
      return null;
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return null;
  }
};

export default getFAQ;
