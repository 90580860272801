import React from "react";
import ReactECharts from "echarts-for-react"; // Import ECharts wrapper for React
import { useTheme } from "@mui/material/styles";

export default function LineChart1({
  width,
  height,
  className,
  current_values = [],
  finalised_values = [],
  x_values = [],
}) {
  const theme = useTheme();

  const options = {
    grid: {
      left: 30,
      right: 20,
      top: 20,
      bottom: 20,
    },
    xAxis: {
      type: "category",
      data: x_values,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "En cours",
        data: current_values,
        type: "line",
        areaStyle: {}, // Enable area under the line
        lineStyle: {
          color: theme.palette.chart.line_blue, // Line color from theme
        },
        itemStyle: {
          color: theme.palette.chart.line_blue, // Item (point) color
        },
        areaStyle: {
          color: theme.palette.chart.fill_blue, // Area fill color from theme
        },
      },
      {
        name: "Finalisés",
        data: finalised_values,
        type: "line",
        areaStyle: {}, // Enable area under the line
        lineStyle: {
          color: theme.palette.chart.line_green, // Line color from theme
        },
        itemStyle: {
          color: theme.palette.chart.line_green, // Item (point) color
        },
        areaStyle: {
          color: theme.palette.chart.fill_green, // Area fill color from theme
        },
      },
    ],
    tooltip: {
      trigger: "axis", // Tooltip for axis-based hover
    },
  };

  return (
    <ReactECharts
      option={options}
      style={{
        width: width || "100%",
        height: height || "300px",
      }}
      className={className}
    />
  );
}
