import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "./addLogDocument.js"; // Assuming a custom log function

const getGraph = async (token, since, prefix) => {
  // Define the endpoint URL
  const url = `${prefix}actions/get-graph`;

  // Define the request body
  const body = {
    since: since,
  };

  // Define headers
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request using native fetch
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return "User not logged in";
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      return "User document not found";
    }

    const orgRef = userSnapshot.get("org_ref");
    const responseText = await response.text();

    // Log the request and response details
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "", // Additional optional fields
      "",
      JSON.stringify(body), // Request body as string
      responseText
    );

    // Check if the request was successful
    if (response.ok) {
      const responseData = JSON.parse(responseText);
      return responseData;
    } else {
      console.log(`Failed to get graph data: ${response.status}`);
      return "";
    }
  } catch (error) {
    console.error(`Exception occurred: ${error.message}`);
    return "";
  }
};

export default getGraph;
