// firebaseAuth.js
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { useState, useEffect } from "react";
import app from "../config/FirebaseConfig";
const auth = getAuth(app);

// Initialize Firebase Auth instance

// Function to log in the user with email and password
export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    return userCredential.user;
  } catch (error) {
    console.error("Error logging in:", error.message);
    throw error; // Throw the error so it can be handled in the component
  }
};

export const logoutUser = async () => {
  try {
    await signOut(auth); // Log out the user
    console.log("User successfully logged out");
  } catch (error) {
    console.error("Error logging out:", error.message);
    throw error; // Handle the error wherever this function is called
  }
};

// Custom hook to track auth state
export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Loading indicator

  useEffect(() => {
    const auth = getAuth();

    // Set up the listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in
        setCurrentUser(user);
      } else {
        // User is logged out
        setCurrentUser(null);
      }
      setLoading(false); // Loading finished
    });

    // Clean up the listener when the component unmounts
    return unsubscribe;
  }, []);

  return { currentUser, loading };
};
