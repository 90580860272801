import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

function PrimaryButton({ type, disabled, children, onClick, sx, className }) {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      color="primary"
      className={className}
      disabled={disabled}
      type={type}
      sx={{
        textTransform: "none",
        padding: "6px 12px",
        border: "0px solid",
        borderRadius: "10px",
        fontSize: "13px",
        fontWeight: 500,
        lineHeight: 1,
        alignItems: "center",
        boxShadow: "0 0 0 0.2rem black_10",
        "&:hover": {
          boxShadow: "0 0 0 0.2rem black_20",

          backgroundColor: theme.palette.primary.light,
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
