import React from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/FirebaseAuth";

function Redirect({ text, action }) {
  const navigate = useNavigate(); // Hook to handle navigation
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div>Redirecting...</div>; // Optional loading state
  }

  return currentUser ? <Navigate to="/home" /> : <Navigate to="/entree" />; // Redirect if not logged in
}

export default Redirect;
