import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming you have this utility for logging

const generatePDF = async (
  listlots,
  type,
  oid,
  path,
  token,
  orgData,
  folderid,
  phone,
  email,
  address,
  name,
  prefix
) => {
  // Define the endpoint URL
  const url = prefix + `folders/${folderid}/xml/generate_pdf`;

  // Get organization data from Firestore

  // Define the request body
  const body = {
    footer: `${name} - ${address}\nTéléphone : ${phone} - Mail : ${email}`,
    type: type,
    oid: oid,
    batch: listlots,
    path: path,
    logo: orgData.entreprise_logo || "https://i.ibb.co/8Nc2tS4/Placeholder.png",
  };

  try {
    // Make the POST request using fetch
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("User not logged in");
      return "";
    }

    const userRef = doc(getFirestore(), "user", currentUser.uid);
    const userDoc = await getDoc(userRef);
    const orgRefFromUser = userDoc.get("org_ref");

    // Add log document
    await addLogDocument(
      url,
      userRef.id,
      orgRefFromUser?.id || "",
      response.status.toString(),
      "",
      "",
      JSON.stringify(body),
      await response.text()
    );

    // Check if the request was successful
    if (response.ok) {
      const cleanResponse = await response.text();
      const cleaned = cleanResponse.trim().replace(/"/g, "");
      console.log("Response cleaned:", cleaned);
      return cleaned;
    } else {
      console.error(`Failed to send request: ${response.status}`);
      return "";
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return "";
  }
};

export default generatePDF;
