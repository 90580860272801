import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import { Divider, Switch } from "@mui/material";
import Space from "../layout/Space";
import MenuButton from "../buttons/MenuButton";
import RoundedButton from "../buttons/RoundedButton";
import RoundedButton2 from "../buttons/RoundedButton2";
import Icon1 from "../icons/Icon";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken";
import stripeSubscriptionManager from "../api/stripe/stripeSubscriptionManager";
import { useAppContext } from "../config/AppContext";
import DropDown1 from "../dropdowns/DropDown1";
import stripeCheckoutSession from "../api/stripe/stripeCheckoutSession";
import stripeMobileSubManager from "../api/stripe/stripeMobileSubManager";

const PlanLine = ({ title }) => {
  return (
    <div className="flex flex-row gap-2">
      <Icon1 icon_name="done" height="16px" width="16px" />
      <div className="text-h3">{title}</div>
    </div>
  );
};

const AppMarket = ({ onClose }) => {
  const [plan, setPlan] = useState("free_tier");
  const [engagement, setEngagement] = useState(true);
  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const { currentUser, loading } = useAuth();

  const options = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 8, label: "8" },
    { value: 10, label: "10" },
    { value: 12, label: "12" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
  ];

  const [number, SetNumber] = useState(1);

  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handlePlanChange = async (plan, engagement) => {
    const token = await getOrRefreshFirebaseToken();
    const url = await stripeSubscriptionManager(
      plan,
      engagement,
      token,
      prefix
    );
    if (url === "done") {
      handleClickVariant("Plan changé avec succès", "success");
      handleFetchData();
    } else {
      // open new window and launch url
      window.open(url, "_blank");
    }
  };

  const handleAppSubscription = async () => {
    const token = await getOrRefreshFirebaseToken();
    const url = await stripeMobileSubManager(number, token, prefix);
    if (url === "done") {
      handleClickVariant("Nombre de comptes mobiles mis à jour", "success");
      handleFetchData();
    } else {
      // open new window and launch url
      window.open(url, "_blank");
    }
  };

  const handleFetchData = async () => {
    if (currentUser) {
      try {
        const user = await getUserById(currentUser.uid);
        const org = await getOrgByRef(user.org_ref);
        console.log("org:", org);

        console.log("org:", org.facturation.plan);

        setUserData(user);
        setOrgData(org);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData();
    }
  }, [currentUser]);

  if (loading || !currentUser || !userData) {
    return <> Loading ..</>;
  }

  return (
    <div className="bg-white flex flex-col p-4 w-fit rounded-3xl ">
      <div className="flex flex-row justify-end items-center w-full">
        <SecondaryButton onClick={onClose}>Fermer</SecondaryButton>
      </div>
      <Space y={10} />

      <div className="flex flex-row gap-4 ">
        <div className="border-1 border-shadow_10 rounded-3xl flex flex-col h-fit p-3  max-w-c400">
          <div className="text-hx2 font-bold">Application terrain</div>
          <Space y={5} />
          <div className="text-h3 ">Connectez vos opérateurs</div>
          <Space y={10} />
          <div className="text-hx2 ">40€/mois/appareil</div>

          <Space y={30} />
          <div className="flex flex-col gap-1">
            <Space y={0} />

            <Divider />
            <Space y={0} />

            <PlanLine title="Partenaires illimités" />
            <PlanLine title="Secrétaires illimités" />
            <PlanLine title="Stockage cloud illimité" />
            <PlanLine title="Devis illimités" />
            <PlanLine title="Attestations illimitées" />
            <PlanLine title="Intégration Infiltrea" />
            <PlanLine title="Rappels automatiques" />
            <PlanLine title="Emails pré-remplis" />
            <PlanLine title="Paiement particulier" />
          </div>
        </div>
        <div className="flex flex-col gap-2 w-c300 h-fit border-1 border-shadow_10 rounded-3xl p-4">
          <div className="flex flex-row justify-between w-full">
            <div className="text-h2">Application mobile</div>
          </div>
          <div className="text-h3 text-shadow_50 w-full">
            Choisissez le nombre de membres de votre équipe qui auront accès à
            l'application mobile.
          </div>
          <Space y={10} />
          <div className="flex flex-row justify-between items-center gap-2 w-full">
            <div className="w-1/3">
              <DropDown1
                items={options}
                value={number}
                onChange={(e) => SetNumber(e.target.value)}
                sx={{
                  borderRadius: "30px",
                }}
              ></DropDown1>
            </div>
            <div
              className="flex flex-row w-2/3 border-2 border-primary rounded-full cursor-pointer hover:shadow-md"
              onClick={handleAppSubscription}
            >
              <div className="bg-primary px-2 text-center py-1 rounded-l-full text-white text-h2 w-1/2">
                Acheter
              </div>
              <div className="bg-white py-1 px-2 text-center rounded-r-full text-primary text-h2 w-1/2">
                {number * 40}€ HT
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppMarket;
