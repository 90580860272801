// Image.js
import React from "react";
import { Box } from "@mui/material";

const Image1 = ({
  src,
  alt,
  width = "100%",
  height = "auto",
  borderRadius = 0,
  className = "",
  sx = {},
  ...rest
}) => {
  return (
    <Box
      component="img"
      className={className}
      src={src}
      alt={alt}
      sx={{
        width: width,
        height: height,
        borderRadius: borderRadius,
        objectPosition: "left", // You can adjust this to fit your use case
        objectFit: "contain", // You can adjust this to fit your use case (cover, contain, e
        ...sx, // Add custom styles
      }}
      {...rest} // Spread any additional props
    />
  );
};

export default Image1;
