import React, { useState, useEffect } from "react";
import Row from "../layout/Row";
import Column from "../layout/Column";

function Horloge() {
  const [heure, setHeure] = useState(new Date().toLocaleTimeString());
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setHeure(new Date().toLocaleTimeString());
      setDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Function to format the day of the week and the date
  const daysOfWeek = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  const formattedDay = daysOfWeek[date.getDay()];
  const formattedDate = `${date.getDate()} ${date.toLocaleString("default", { month: "long" })}`;

  return (
    <div className="bg-white p-3 rounded-xl border-1 border-shadow_10 w-c400">
      <Row x={0} className="w-fit">
        <Column className="grow" y={2} align="left">
          <div className="text-h1 font-medium">{formattedDay}</div>
          <div className="text-h2">{formattedDate}</div>
        </Column>
        <div className="text-hx3 font-medium">{heure}</div>
      </Row>
    </div>
  );
}

export default Horloge;
