import React from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

function TextFieldBorderLess({
  placeholder,
  label,
  sx,
  value,
  onChange,
  onEnter,
}) {
  const theme = useTheme();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (onEnter) {
        onEnter(event); // Trigger the action passed as a parameter when "Enter" is pressed
      }
    }
  };

  return (
    <TextField
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      variant="standard" // Change the variant to borderless
      color="primary"
      fullWidth // Makes the TextField take all available width
      InputProps={{
        disableUnderline: true, // Disable the bottom bar (underline)
      }}
      sx={{
        "& .MuiInputBase-root": {
          backgroundColor: "white", // Initial background color
          transition: "background-color 0.3s ease",
        },
        "&:hover .MuiInputBase-root": {
          backgroundColor: theme.palette.action.hover, // Change background color on hover
        },
        ...sx, // Add custom styles if needed
      }}
      onKeyDown={handleKeyDown}
    />
  );
}

export default TextFieldBorderLess;
