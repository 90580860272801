import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBarButton from "../buttons/NavBarButton";
import Column from "../layout/Column";
import Space from "../layout/Space";
import Image1 from "../images/Image1";
import { useAuth } from "../auth/FirebaseAuth";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import PrimaryButton from "../buttons/PrimaryButton";
import Icon1 from "../icons/Icon";

function ParametersNavBar({ actualpage }) {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate(); // Hook to handle navigation

  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);

  const handleFetchData = async () => {
    if (currentUser) {
      const user = await getUserById(currentUser.uid); // Fetch user by ID using function
      const org = await getOrgByRef(user.org_ref); // Fetch org by ref using function
      if (user && user !== userData) {
        setUserData(user);
        setOrgData(org);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData(); // Fetch user data when currentUser is available
    }
  }, [currentUser]); // Dependency array ensures this runs when currentUser changes

  if (loading || !currentUser || !userData) {
    return <div>Loading...</div>; // Show loading state while checking auth or if currentUser is null
  }

  return (
    <Column
      spacing={0}
      align="left"
      className="shrink-0 grow-0 max-w-48  pl-5 bg-white"
    >
      <Space y={40} />
      <div className="text-h1 font-semibold">Paramètres</div>
      <Space y={27} />

      {/* Navigation buttons */}
      <NavBarButton
        icon="client"
        actualpage={actualpage}
        onClick={() => navigate("/account")}
      >
        Compte
      </NavBarButton>
      <NavBarButton
        icon="organisation"
        actualpage={actualpage}
        onClick={() => navigate("/organisation")}
      >
        Entreprise
      </NavBarButton>
      <NavBarButton
        icon="test-results"
        actualpage={actualpage}
        onClick={() => navigate("/products")}
      >
        Produits
      </NavBarButton>
      <NavBarButton
        icon="devis"
        actualpage={actualpage}
        onClick={() => navigate("/devisparams")}
      >
        Devis
      </NavBarButton>
      <Space y={27} />
      <NavBarButton
        icon="facturation"
        actualpage={actualpage}
        onClick={() => navigate("/facturation")}
      >
        Facturation
      </NavBarButton>
      <NavBarButton
        icon="boutique"
        actualpage={actualpage}
        onClick={() => navigate("/boutique")}
      >
        Boutique
      </NavBarButton>
      <NavBarButton
        icon="integration"
        actualpage={actualpage}
        onClick={() => navigate("/infiltrea")}
      >
        Infiltrea
      </NavBarButton>

      <Space y={30} />
      <NavBarButton icon="nouveau-message" onClick={() => navigate("/email")}>
        Modèles emails
      </NavBarButton>

      {/* Admin-specific buttons */}
      <div>
        <NavBarButton icon="tag" onClick={() => navigate("/tags")}>
          Tags
        </NavBarButton>
        <NavBarButton
          icon="pinceau"
          actualpage={actualpage}
          onClick={() => navigate("/home")}
        >
          Personnalisation
        </NavBarButton>
        <NavBarButton
          icon="cloche"
          actualpage={actualpage}
          onClick={() => navigate("/entree")}
        >
          Notifications
        </NavBarButton>
      </div>
    </Column>
  );
}

export default ParametersNavBar;
