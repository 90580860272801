import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import SecondaryButton from "../buttons/SecondaryButton";
import CloseIcon from "@mui/icons-material/Close"; // Optional close icon
import { useTheme } from "@emotion/react";
import NewMission from "./NewMission";

const NewMissionModal = ({
  children,
  folder,
  orgData,
  setModalOpen,
  open,
  setOpen,
}) => {
  const theme = useTheme();

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    setModalOpen(true); // Notify parent that modal is open
  };

  const handleFetchAgain = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    document && (
      <div className="w-0 h-0 justify-center items-center align-middle bg-transparent">
        {/* Button to open the modal */}
        <div className="h-fit w-fit cursor-pointer bg-transparent">
          {folder.last_file_extracted && document.state === "done" && (
            <div
              className=" px-2 py-0 w-24 min-w-24 text-center border-1 text-h3 rounded-md text-shadow_50"
              style={{
                backgroundColor: theme.palette.accent1.light,
                color: theme.palette.accent1.main,
              }}
            >
              Extrait
            </div>
          )}

          {!folder.last_file_extracted &&
            document.state === "done" &&
            orgData.type === "master" && (
              <SecondaryButton
                sx={{ fontSize: "13px", borderRadius: "8px", minWidth: "80px" }}
                onClick={handleOpen}
              >
                Extraire
              </SecondaryButton>
            )}

          {children}
        </div>

        {/* Modal dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth // Optional: makes the dialog take full width
          maxWidth="md" // Optional: control max width, options: 'xs', 'sm', 'md', 'lg', 'xl'
          PaperProps={{
            sx: {
              width: "fit-content",
              borderRadius: "20px",
              backgroundColor: "#f0f0f0", // Customize background color
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Add a shadow
            },
          }}
        >
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="text-h1">Nouvelle Mission Infiltrea</div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: "#888", // Customize the close button color
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              backgroundColor: "transparent", // Change background color for the content
              padding: "5px", // Add padding
              borderRadius: "10px", // Rounded corners for content
              width: "fit-content", // Optional: set width to fit content
            }}
          >
            {/* Event Content */}
            <NewMission
              document={document}
              folder={folder}
              orgData={orgData}
              onClose={handleClose}
              onUpdate={handleFetchAgain}
            />
          </DialogContent>
        </Dialog>
      </div>
    )
  );
};

export default NewMissionModal;
