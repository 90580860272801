import {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import sendErrorDiscord from "./sendErrorDiscord.js"; // Assuming a custom log function similar to FlutterFlow
import { useState } from "react";
import { getDoc, doc } from "firebase/firestore";

const addLogDocument = async (
  url,
  userref,
  orgref,
  status,
  folderid,
  docid,
  request,
  response,
  type = "POST"
) => {
  const db = getFirestore(); // Initialize Firestore

  let email = "";
  if (orgref) {
    const orgSnapshot = await getDoc(orgref);
    email = orgSnapshot.get("email");
  }

  // Create the log document object
  const logDocument = {
    url: url,
    userref: userref ? userref : "",
    orgref: orgref ? orgref : "",
    type: type,
    email: email,
    status: status,
    date: Timestamp.now(), // Firestore timestamp
    folderid: folderid || "",
    docid: docid || "",
    request: request || "",
    response: response || "",
    resolved: false, // Default resolved state
  };

  try {
    // Send error to Discord if status is not 200

    if (status.toString() !== "200") {
      await sendErrorDiscord(
        `ERROR ${status} on endpoint: ${url}, response: ${response || ""}`
      );
      // Assuming you have an app state management system
      // FFAppState().error = true; can be translated to your React state management system
    }

    // Add the log document to the Firestore collection 'log'
    await addDoc(collection(db, "log"), logDocument);

    return "Log document added successfully";
  } catch (error) {
    console.error("Error adding log document:", error);
    return `Error adding log document: ${error.message}`;
  }
};

export default addLogDocument;
