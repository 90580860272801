import React from "react";
import Row from "../layout/Row";
import Icon1 from "../icons/Icon";
import TextFieldBorderLess from "../textfields/TextFieldBorderLess";
import { useState } from "react";
import { useTheme } from "@mui/material";
import TextFieldSearch from "../textfields/TextFieldSearch";

function SearchBar1({ options, value, setValue, onAction }) {
  const theme = useTheme();

  return (
    <div className="bg-white rounded-3xl border-1 border-shadow_25 w-c400 px-3 py-0 h-fit">
      <Row x={10} align="left" className="w-full">
        <Icon1 icon_name="search" fill={theme.palette.shadow._50}></Icon1>
        <TextFieldSearch
          options={options}
          placeholder="Chercher"
          value={value}
          setValue={setValue}
          onAction={onAction}
          className="w-full"
        ></TextFieldSearch>
      </Row>
    </div>
  );
}

export default SearchBar1;
