import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextField1 from "../textfields/TextField1";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import HexagoneTitle from "../hexagone/HexagoneTitle";
import Icon from "../icons/Icon";
import Box1 from "../boxs/Box1";
import Row from "../layout/Row";
import MenuButton from "../buttons/MenuButton";
import StackBottomRight from "../layout/StackBottomRight";

import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordField from "../textfields/PasswordField";
import EmailField from "../textfields/EmailField";

import GreyButton from "../buttons/GreyButton";
import RoundedButton from "../buttons/RoundedButton";
import Space from "../layout/Space";
import HexagoneHeader from "../hexagone/HexagoneHeader";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Column from "../layout/Column";
import { loginUser } from "../auth/FirebaseAuth";
import { useSnackbar } from "notistack";
import ConfirmationSmsModal from "../modals/ConfirmationSmsModal";
import { sendSmsVerificationCall } from "../config/ApiCalls";
import { VerifySmsCodeCall } from "../config/ApiCalls";
import { gatherDeviceInfo } from "../config/GatheringInfos";
import CheckBoxGrey from "../textfields/CheckBoxGrey";
import createNewUser from "../api/create/createNewUser";
import { useAppContext } from "../config/AppContext";

function Signin({ text, action }) {
  const navigate = useNavigate(); // Hook to handle navigation

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [beta_key, setBetaKey] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const handleSignin = async (event) => {
    event.preventDefault();
    try {
      if (
        email === "" ||
        password === "" ||
        confirm_password === "" ||
        beta_key === ""
      ) {
        handleClickVariant("Veuillez remplir tous les champs", "error");
        return;
      }
      if (password !== confirm_password) {
        handleClickVariant("Les mots de passe ne correspondent pas", "error");
        return;
      } else {
        const responseData = await createNewUser(
          email,
          password,
          beta_key,
          prefix
        );
        if (responseData.success == "true") {
          handleClickVariant("Compte créé avec succès", "success");
          await loginUser(email, password); // Call Firebase login function
          navigate("/setup");
        } else {
          handleClickVariant(responseData.data, "error");
          if (
            responseData.data ==
            "Un compte avec cet email existe deja, connectez-vous directement."
          ) {
            navigate("/login");
          }
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setBetaKey("");
        }
      }
    } catch (error) {
      handleClickVariant("Erreur lors de la création du compte", "error");
      console.error("Error signing in:", error.message + " " + error);
    }
  };

  const goPage = (page) => {
    navigate(page);
  };

  return (
    <div className="h-screen">
      <div className="h-full bg-slate-50 items-center flex justify-center flex-col">
        <Box1>
          <HexagoneHeader />

          <Space y={10} />

          <form onSubmit={handleSignin}>
            <div className="flex flex-col items-center">
              <EmailField
                label={"Email"}
                sx={{ width: "300px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Space y={10} />
              <PasswordField
                sx={{ width: "300px" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Space y={10} />
              <PasswordField
                label="Confirmer le mot de passe"
                sx={{ width: "300px" }}
                value={confirm_password}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />{" "}
              <Space y={10} />
              <EmailField
                label={"Clé de Beta"}
                sx={{ width: "300px" }}
                value={beta_key}
                onChange={(e) => setBetaKey(e.target.value)}
                autoComplete="beta"
              />
              <Space y={20} />
              {/* Submit the form by pressing this button or pressing "Enter" */}
              <RoundedButton
                type="submit"
                color="black"
                loading={loading}
                className="w-fit "
              >
                Commencer
              </RoundedButton>
            </div>
          </form>
          <Space y={10} />
        </Box1>
        <StackBottomRight>
          <div className="bg-black px-3 py-1 rounded-full text-f_white text-sm">
            Beta
          </div>
        </StackBottomRight>
      </div>
    </div>
  );
}

export default Signin;
