import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument.js"; // Assuming a custom log function

const setFolderIntervention = async (
  folderid,
  intervention_time_int,
  interventiondone,
  token,
  prefix
) => {
  // Define the endpoint URL
  const url = prefix + `folders/${folderid}/intervention`;

  // Define the request body
  const body = {
    intervention_time_int: intervention_time_int,
    interventiondone: interventiondone,
  };

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the PUT request
    const response = await fetch(url, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    // Get current user using Firebase authentication
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User not logged in");
      return "User not logged in";
    }

    // Access Firestore to get the current user document
    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return "User document not found";
    }

    const orgRef = userSnapshot.get("org_ref");

    // Log the request and response
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "", // Optional additional fields
      "",
      JSON.stringify(body), // Request body as string
      await response.text(), // Response body as text
      "POST"
    );

    // Check if the request was successful
    if (response.ok) {
      console.log("Folder intervention updated successfully");
      return "Folder intervention updated successfully";
    } else {
      console.error(`Failed to modify folder intervention: ${response.status}`);
      return `Failed to modify folder intervention: ${response.status}`;
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return `Exception occurred: ${error.message}`;
  }
};

export default setFolderIntervention;
