import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

function DropDown1({ label, items = [], sx, value, onChange, className }) {
  return (
    <div className={className}>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            // Vertically center the label initially
            top: "-38%",
            left: "0px",
            fontSize: "14px", // Adjust label size when focused

            color: "rgba(0, 0, 0, 0.54)", // Adjust label color
            "&.Mui-focused": {
              top: "0%", // Move the label on top when focused
              fontSize: "14px", // Adjust label size when focused
            },
            "&.MuiInputLabel-shrink": {
              top: "0%", // Keep label on top when shrinking
              fontSize: "14px", // Adjust the label size when it shrinks
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          value={value}
          onChange={onChange}
          label={label}
          variant="outlined"
          fullWidth
          sx={{
            fontSize: "14px", // Set font size for the select
            // Main Select styling
            height: "30px",
            borderRadius: "12px",

            backgroundColor: "#fff", // Set background to white
            "& .MuiOutlinedInput-input": {
              padding: "10px", // Adjust padding for the input field
            },
            // Override dropdown list background (Menu)
            "& .MuiPaper-root": {
              backgroundColor: "#fff", // Ensure dropdown menu is white
            },
            "& .MuiSelect-select": {
              paddingLeft: "16px", // Add left padding to the selected item
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#3f51b5", // Hover border color
              },
              "&.Mui-focused fieldset": {
                borderColor: "#3f51b5", // Focus border color
              },
            },
            ...sx, // Additional styles passed via props
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  fontSize: "14px", // Set global font size for all MenuItems
                },

                backgroundColor: "#fff", // Set dropdown menu background color to white
              },
            },
          }}
        >
          <MenuItem value="">
            <em>Annuler</em>
          </MenuItem>
          {items.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropDown1;
