import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument.js"; // Custom logging function

const generateBoth = async (
  oid,
  listlots,
  path,
  urlinsulator,
  token,
  orgRef,
  phone,
  folderId,
  email,
  address,
  name,
  prefix
) => {
  const url = prefix + `folders/${folderId}/xml/generate_pdf`;

  try {
    // Fetch organization data from Firestore
    const orgDoc = await getDoc(orgRef);
    const userData = orgDoc.exists() ? orgDoc.data() : {};

    // Prepare the request body for the PDF generation
    const body = {
      oid: oid,
      footer: `${name} - ${address}\nTéléphone : ${phone} - Mail : ${email}`,
      type: "carbon",
      batch: listlots,
      path: path,
      logo: userData.logo || "https://i.ibb.co/8Nc2tS4/Placeholder.png",
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    // Send the request to generate the carbon PDF
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const clean = (await response.text()).trim().replace(/"/g, "");
      const urlcarbon = clean;

      // Prepare the request to merge the two PDFs
      const mergeUrl = prefix + `folders/${folderId}/xml/merge`;
      const mergeBody = {
        pdf1: urlinsulator,
        pdf2: urlcarbon,
      };

      // Make the POST request to merge PDFs
      const mergeResponse = await fetch(mergeUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(mergeBody),
      });

      const currentUser = getAuth().currentUser;
      if (!currentUser) {
        return "";
      }

      const userRef = doc(getFirestore(), "user", currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const orgRefFromUser = userSnapshot.get("org_ref");

      const responseText = await mergeResponse.text();

      // Log the action
      await addLogDocument(
        mergeUrl,
        userRef,
        orgRefFromUser,
        mergeResponse.status.toString(),
        "",
        "",
        JSON.stringify(mergeBody),
        responseText,
        "POST"
      );

      if (mergeResponse.ok) {
        const responseBody = responseText;
        const cleanMerge = responseBody.trim().replace(/"/g, "");
        return cleanMerge;
      } else {
        console.log("Failed to merge PDFs:", mergeResponse.status);
        return "";
      }
    } else {
      console.log("Failed to create carbon PDF:", response.status);
      return "";
    }
  } catch (error) {
    console.error("Exception occurred:", error);
    return "";
  }
};

export default generateBoth;
