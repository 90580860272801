import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import { Divider, Switch } from "@mui/material";
import Space from "../layout/Space";
import MenuButton from "../buttons/MenuButton";
import RoundedButton from "../buttons/RoundedButton";
import RoundedButton2 from "../buttons/RoundedButton2";
import Icon1 from "../icons/Icon";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken";
import stripeSubscriptionManager from "../api/stripe/stripeSubscriptionManager";
import { useAppContext } from "../config/AppContext";
import getFAQ from "../api/getFAQ";
import { set } from "date-fns";
import CustomAccordion from "../layout/CustomAccordion";
import Accordion2 from "../layout/Accordion2";

const PlanLine = ({ title }) => {
  return (
    <div className="flex flex-row gap-2">
      <Icon1 icon_name="done" height="16px" width="16px" />
      <div className="text-h3">{title}</div>
    </div>
  );
};

function getPrice(plan, engagement) {
  if (plan === "free_tier") {
    return "0€/mois";
  } else if (plan === "solo_tier" && engagement === "month") {
    return "110€/mois HT";
  } else if (plan === "solo_tier" && engagement === "year") {
    return "90€/mois HT";
  } else if (plan === "pro_tier" && engagement === "month") {
    return "180€/mois HT";
  } else if (plan === "pro_tier" && engagement === "year") {
    return "150€/mois HT";
  } else if (plan === "expert_tier" && engagement === "month") {
    return "350€/mois HT";
  } else if (plan === "expert_tier" && engagement === "year") {
    return "280€/mois HT";
  }
}

const PlanComponent = ({
  title,
  subtitle,
  price,
  features,
  action_type,
  action,
}) => {
  return (
    <div className="bg-white border-1 border-shadow_10 rounded-3xl flex flex-col h-fit p-3 w-full">
      <div className="text-hx2 font-bold">{title}</div>
      <Space y={5} />
      <div className="text-h3 ">{subtitle}</div>
      <Space y={10} />
      <div className="text-hx2 ">{price}</div>
      <Space y={10} />
      {action_type === "manage" && (
        <RoundedButton2 onClick={action}>Gérer mon plan</RoundedButton2>
      )}
      {action_type === "upgrade" && (
        <RoundedButton2 onClick={action}>Améliorer</RoundedButton2>
      )}
      {action_type === "downgrade" && (
        <RoundedButton2 onClick={action}>Diminuer</RoundedButton2>
      )}

      <Space y={30} />
      <div className="flex flex-col gap-1">
        {features.map((feature) => (
          <PlanLine title={feature}></PlanLine>
        ))}

        <Space y={0} />

        <Divider />
        <Space y={0} />

        <PlanLine title="Partenaires illimités" />
        <PlanLine title="Secrétaires illimités" />
        <PlanLine title="Stockage cloud illimité" />
        <PlanLine title="Devis illimités" />
        <PlanLine title="Attestations illimitées" />
        <PlanLine title="Intégration Infiltrea" />
        <PlanLine title="Rappels automatiques" />
        <PlanLine title="Emails pré-remplis" />
        <PlanLine title="Paiement particulier" />
      </div>
    </div>
  );
};

const PlanComponent2 = ({
  title,
  subtitle,
  price,
  features,
  action_type,
  action,
}) => {
  return (
    <div className="bg-white border-1 border-primary rounded-3xl flex flex-col h-fit py-3 px-5 w-full m-2 shadow-lg">
      <div className="relative flex -top-6 items-center justify-center rounded-full px-2 py-0.5 w-full">
        <div className=" bg-primary rounded-full flex px-2 py-0.5 w-fit">
          <div className="text-h5 text-f_white">Recommandé</div>
        </div>
      </div>
      <div className="text-hx2 font-bold">{title}</div>
      <Space y={5} />
      <div className="text-h3 ">{subtitle}</div>
      <Space y={10} />
      <div className="text-hx2 ">{price}</div>
      <Space y={10} />
      {action_type === "manage" && (
        <RoundedButton2 onClick={action}>Gérer mon plan</RoundedButton2>
      )}
      {action_type === "upgrade" && (
        <RoundedButton2 onClick={action}>Améliorer</RoundedButton2>
      )}
      {action_type === "downgrade" && (
        <RoundedButton2 onClick={action}>Diminuer</RoundedButton2>
      )}
      <Space y={30} />
      <div className="flex flex-col gap-1">
        {features.map((feature) => (
          <PlanLine title={feature}></PlanLine>
        ))}

        <Space y={0} />

        <Divider />
        <Space y={0} />

        <PlanLine title="Partenaires illimités" />
        <PlanLine title="Secrétaires illimités" />
        <PlanLine title="Stockage cloud illimité" />
        <PlanLine title="Devis illimités" />
        <PlanLine title="Attestations illimitées" />
        <PlanLine title="Intégration Infiltrea" />
        <PlanLine title="Rappels automatiques" />
        <PlanLine title="Emails pré-remplis" />
        <PlanLine title="Paiement particulier" />
      </div>
    </div>
  );
};

const PlanMarket = ({ onClose }) => {
  const [plan, setPlan] = useState("free_tier");
  const [engagement, setEngagement] = useState(true);
  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const { currentUser, loading } = useAuth();

  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;
  const [questions, setQuestions] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  const handlePlanChange = async (plan, engagement) => {
    const token = await getOrRefreshFirebaseToken();
    const url = await stripeSubscriptionManager(
      plan,
      engagement,
      token,
      prefix
    );
    if (url === "done") {
      handleClickVariant("Plan changé avec succès", "success");
      handleFetchData();
    } else {
      // open new window and launch url
      window.open(url, "_blank");
    }
  };

  const handleGetFaq = async () => {
    const token = await getOrRefreshFirebaseToken();
    const questions = await getFAQ(token, prefix);
    setQuestions(questions);
  };

  const handleFetchData = async () => {
    if (currentUser) {
      try {
        const user = await getUserById(currentUser.uid);
        const org = await getOrgByRef(user.org_ref);

        setUserData(user);
        setOrgData(org);
        handleGetFaq();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData();
    }
  }, [currentUser]);

  if (loading || !currentUser || !userData) {
    return <> Loading ..</>;
  }

  return (
    <div className="bg-slate-100 w-screen h-screen flex flex-col p-4 overflow-auto items-center ">
      <div className="flex flex-row justify-end w-full">
        <SecondaryButton onClick={onClose}>Fermer</SecondaryButton>
      </div>
      <div className="text-h1 font-semibold text-center">
        Plan de facturation
      </div>
      <Space y={10} />
      <div className="border-1 border-shadow_10 rounded-3xl px-2 pr-4 py-1 flex flex-row justify-center items-center w-fit">
        <Switch
          checked={engagement}
          onChange={() => setEngagement(!engagement)}
        />
        <div className="text-h3">Annuel (-20%) </div>
      </div>
      <Space y={10} />
      <div className="flex flex-row justify-center items-center gap-4 w-c1k">
        <PlanComponent
          key="free_tier"
          title={"Démarrage"}
          subtitle={"Essayez gratuitement"}
          price={getPrice("free_tier", engagement ? "year" : "month")}
          features={["1 Opérateur", "50 Dossiers"]}
          action_type={
            orgData.facturation.plan === "free_tier" ? "manage" : "downgrade"
          }
          action={() =>
            handlePlanChange("free_tier", engagement ? "year" : "month")
          }
        ></PlanComponent>
        <PlanComponent
          key="solo_tier"
          title={"Solo"}
          subtitle={"Décollez en solo"}
          price={getPrice("solo_tier", engagement ? "year" : "month")}
          features={["1 Opérateur", "Dossiers illimités"]}
          orgData={orgData}
          action_type={
            orgData.facturation.plan === "solo_tier"
              ? "manage"
              : orgData.facturation.plan === "free_tier"
                ? "upgrade"
                : "downgrade"
          }
          action={() =>
            handlePlanChange("solo_tier", engagement ? "year" : "month")
          }
        ></PlanComponent>
        <PlanComponent2
          title={"Pro"}
          subtitle={"Gagnez en efficacité"}
          price={getPrice("pro_tier", engagement ? "year" : "month")}
          features={["3 Opérateurs", "Dossiers illimités"]}
          orgData={orgData}
          action_type={
            orgData.facturation.plan === "pro_tier"
              ? "manage"
              : orgData.facturation.plan === "expert_tier"
                ? "downgrade"
                : "upgrade"
          }
          action={() =>
            handlePlanChange("pro_tier", engagement ? "year" : "month")
          }
        ></PlanComponent2>
        <PlanComponent
          key="expert_tier"
          title={"Expert"}
          subtitle={"Boostez vos secrétaires"}
          price={getPrice("expert_tier", engagement ? "year" : "month")}
          features={["Opérateurs illimités", "Dossiers illimités"]}
          orgData={orgData}
          action_type={
            orgData.facturation.plan === "expert_tier" ? "manage" : "upgrade"
          }
          action={() =>
            handlePlanChange("expert_tier", engagement ? "year" : "month")
          }
        ></PlanComponent>
      </div>
      <Space y={50} />
      <div className=" flex-row flex w-c1k  gap-2">
        <div className=" flex-col flex w-1/2 gap-2">
          {questions &&
            questions.map(
              (question, index) =>
                index % 2 === 0 && (
                  <div key={index} className="text-h3 flex">
                    <Accordion2 title={question.question}>
                      <div className="text-h3 text-shadow_50">
                        {question.answer}
                      </div>
                    </Accordion2>
                  </div>
                )
            )}
        </div>{" "}
        <div className=" flex-col flex w-1/2 gap-2">
          {questions &&
            questions.map(
              (question, index) =>
                index % 2 !== 0 && (
                  <div key={index} className="text-h3 flex">
                    <Accordion2 title={question.question}>
                      <div className="text-h3 text-shadow_50 ">
                        {question.answer}
                      </div>
                    </Accordion2>
                  </div>
                )
            )}
        </div>{" "}
      </div>
      <Space y={50} />
    </div>
  );
};

export default PlanMarket;
