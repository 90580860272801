import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming a custom log function

const refuseFolder = async (folderid, docid = null, token, type, prefix) => {
  let url = "";
  const body = {
    type: type,
  };

  // Define the endpoint URL based on type
  if (type === "thermal" || type === "modif_thermal") {
    url = prefix + `folders/${folderid}/${type}/refuse`;
  } else if (type === "doc") {
    url = prefix + `folders/${folderid}/documents/${docid}/refuse`;
  }

  // Define headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the PUT request
    const response = await fetch(url, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return "User not logged in";
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      return "User document not found";
    }

    const orgRef = userSnapshot.get("org_ref");

    // Add log document
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "", // Optional additional fields
      "",
      JSON.stringify(body), // Request body as string
      await response.text() // Response body as text
    );

    // Check if the request was successful
    if (response.ok) {
      return ""; // Success message or further processing
    } else {
      console.log(`Failed to refuse folder part: ${response.status}`);
      return "";
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
    return "";
  }
};

export default refuseFolder;
