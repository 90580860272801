import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "./addLogDocument"; // Assuming you have a similar function to log actions

const scrapeQualibat = async (siret, token, prefix) => {
  // Define the endpoint URL
  const url = prefix + "signin/scrape";

  // Clean up the SIRET format (remove spaces)
  const cleanedSiret = siret.replace(/\s+/g, "");

  if (cleanedSiret.length !== 14) {
    return ["error", "Le SIRET n'a pas le bon format"];
  }

  // Define the request body
  const body = {
    SIRET: cleanedSiret,
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body), // Encode the body as JSON
    });

    // Check if the user is authenticated
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("User not logged in");
      return ["error", "User not logged in"];
    }

    // Get Firestore references for the user
    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return ["error", "User document not found"];
    }

    const orgRef = userSnapshot.get("org_ref");
    const responseText = await response.text();
    // Add log document (avoid passing full document references)
    await addLogDocument(
      url,
      userRef, // Only log the userRef ID
      orgRef, // Only log the orgRef ID
      response.status.toString(),
      "", // folderid
      "", // docid
      JSON.stringify(body), // request body as string
      responseText
    );

    // Check if the request was successful
    if (response.ok) {
      // Parse and return the response body
      const responseData = JSON.parse(responseText);
      const {
        success,
        name,
        city,
        phone,
        logo,
        dirigeant,
        adresse,
        postal,
        error_msg,
      } = responseData;

      if (success === "true") {
        return ["true", name, city, phone, logo, dirigeant, adresse, postal];
      } else {
        return ["false", error_msg];
      }
    } else {
      console.error(`Failed to scrape data: ${response.status}`);
      return ["false", "Erreur"];
    }
  } catch (error) {
    console.error(`Exception occurred: ${error.message}`);
    return ["false", "Erreur"];
  }
};

export default scrapeQualibat;
