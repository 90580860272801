import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

// Function to send error message to Discord
const sendErrorDiscord = async (message) => {
  // Define the endpoint URL
  const url = "https://api.hexagone.expert/send/react-error";

  // Define the request body
  const body = {
    message: message,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  try {
    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      return;
    }

    const db = getFirestore();
    const userRef = doc(db, "user", currentUser.uid);

    // Retrieve the organization reference from the user document
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      console.error("User document not found");
      return;
    }

    const orgRef = userSnapshot.get("org_ref");

    // Add log document (Assuming you have a similar function for adding logs in JavaScript)
    await addLogDocument(
      url,
      userRef,
      orgRef,
      response.status.toString(),
      "",
      "",
      JSON.stringify(body), // Log the request body
      await response.text(), // Log the response body as text
    );

    // Check if the request was successful
    if (response.ok) {
      console.log("Error sent to Discord successfully");
    } else {
      console.error(`Failed to send error: ${response.status}`);
    }
  } catch (error) {
    // Handle exceptions
    console.error(`Exception occurred: ${error.message}`);
  }
};

export default sendErrorDiscord;
