import {
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../config/FirebaseConfig.js"; // Import the Firebase setup
import { useTheme } from "@mui/material/styles";
import Icon1 from "../icons/Icon.js";
import { useState } from "react";
import { Hidden } from "@mui/material";

// Save organization data to Firestore
export async function saveOrg(orgData) {
  const docRef = doc(db, "org", orgData.id);
  await setDoc(docRef, orgData); // Save the plain object directly
}

// Get organization by ID
export async function getOrgById(id) {
  const docRef = doc(db, "org", id);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    return { id: id, ref: docRef, ...docSnapshot.data() }; // Return plain object with document reference and ID
  }
  return null; // Return null if no document exists
}

export async function getOrgByRef(docRef) {
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    return { id: docRef.id, ref: docRef, ...docSnapshot.data() }; // Return plain object with document reference and ID
  }
  return null; // Return null if no document exists
}

// Fetch clients for the organization
export async function getClientsByOrgRef(orgRef) {
  const docSnapshot = await getDoc(orgRef);
  const docData = docSnapshot.data();
  const clients = [];
  for (const clientRef of docData.clients) {
    const clientSnapshot = await getDoc(clientRef);
    if (clientSnapshot.exists()) {
      clients.push({
        id: clientRef.id,
        ref: clientRef,
        ...clientSnapshot.data(),
      }); // Include document reference and ID
    }
  }
  return clients;
}

export async function getUsersByOrgRef(orgRef) {
  const docSnapshot = await getDoc(orgRef);
  const docData = docSnapshot.data();
  const clients = [];
  for (const clientRef of docData.users) {
    const clientSnapshot = await getDoc(clientRef);
    if (clientSnapshot.exists()) {
      clients.push({
        id: clientRef.id,
        ref: clientRef,
        ...clientSnapshot.data(),
      }); // Include document reference and ID
    }
  }
  return clients;
}

export async function getFoldersByOrgRef(orgRef, type) {
  // Define the collection and query for documents where master_o equals orgRef
  let foldersQuery = [];
  if (type === "master") {
    foldersQuery = query(
      collection(db, "folder"),
      where("master_o", "==", orgRef)
    );
  } else if (type === "client") {
    foldersQuery = query(
      collection(db, "folder"),
      where("client_o", "==", orgRef)
    );
  } else if (type === "expert") {
    foldersQuery = query(
      collection(db, "folder"),
      where("experts_o", "array-contains", orgRef)
    );
  }

  // Fetch the documents that match the query
  const querySnapshot = await getDocs(foldersQuery);

  // Map the documents to an array of folder data with document ID and reference
  const folders = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  return folders;
}

export async function getInvoicesByOrgRef(orgRef, type) {
  // Define the collection and query for documents where master_o equals orgRef
  let foldersQuery = [];
  if (type === "master") {
    foldersQuery = query(
      collection(db, "devis"),
      where("org_ref", "==", orgRef)
    );
  } else if (type === "client") {
    foldersQuery = query(
      collection(db, "devis"),
      where("client_o", "==", orgRef)
    );
  }

  // Fetch the documents that match the query
  const querySnapshot = await getDocs(foldersQuery);

  // Map the documents to an array of folder data with document ID and reference
  const folders = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  return folders;
}

export async function getEventsByOrgRef(orgRef, type) {
  // Define the collection and query for documents where master_o equals orgRef
  let foldersQuery = [];
  if (type === "master") {
    foldersQuery = query(
      collection(db, "event"),
      where("master_o", "==", orgRef)
    );
  } else if (type === "client") {
    foldersQuery = query(
      collection(db, "event"),
      where("client_o", "==", orgRef)
    );
  }

  // Fetch the documents that match the query
  const querySnapshot = await getDocs(foldersQuery);

  // Map the documents to an array of folder data with document ID and reference
  const folders = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));
  return folders;
}

export async function getPacksByOrgRef(orgRef) {
  // Define the collection and query for documents where master_o equals orgRef
  const foldersQuery = query(
    collection(db, "pack"),
    where("org_ref", "==", orgRef)
  );

  // Fetch the documents that match the query
  const querySnapshot = await getDocs(foldersQuery);

  // Map the documents to an array of folder data with document ID and reference
  const folders = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  return folders;
}

export async function getConductorsByOrgRef(orgRef) {
  // Define the collection and query for documents where master_o equals orgRef
  const foldersQuery = query(
    collection(db, "user"),
    where("org_ref", "==", orgRef) && where("user_type", "==", "conductor")
  );

  // Fetch the documents that match the query
  const querySnapshot = await getDocs(foldersQuery);

  // Map the documents to an array of folder data with document ID and reference
  const folders = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  return folders;
}

export async function getExpertsByOrgRef(orgRef) {
  const docSnapshot = await getDoc(orgRef);
  const docData = docSnapshot.data();
  const experts = [];
  for (const docRef of docData.experts) {
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      experts.push({
        id: docRef.id,
        ref: docRef,
        ...docSnapshot.data(),
      }); // Include document reference and ID
    }
  }
  return experts;
}

// Fetch articles for the organization by org_ref
export async function queryArticlesByOrgRef(orgRef) {
  const articlesRef = collection(db, "article");

  // Create the query to fetch articles that belong to the organization
  const q = query(articlesRef, where("org_ref", "==", orgRef));

  // Execute the query and get the documents
  const querySnapshot = await getDocs(q);

  const articles = [];

  // Loop through the documents in the querySnapshot
  querySnapshot.forEach((snap) => {
    const articleData = snap.data();
    articles.push({
      id: snap.id,
      ref: snap.ref, // Include the document reference in the article object
      ...articleData, // Push article data directly as a plain object
    });
  });

  return articles; // Return list of article objects
}

// Transform clients into a DataGrid-friendly format
export function transformClientsToTable(clients) {
  // Define columns for DataGrid, customize based on your data structure
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },

    { field: "name", headerName: "Nom", flex: 1 },
    { field: "client_type", headerName: "Type", flex: 1 },
    { field: "ville", headerName: "Ville", flex: 1 },
    {
      field: "folders",
      headerName: "Dossiers",
      width: 100,
      renderCell: (params) => {
        let value = params.value;
        return (
          <div className="flex flex-row gap-2 items-center justify-center">
            {value}
            <Icon1 icon_name="folder" fill="grey"></Icon1>
          </div>
        );
      },
    },
    // Add more columns as needed
  ];

  // Create rows from the clients array
  const rows = clients.map((client, index) => ({
    id: client.id || index + 1, // Use client's ID or default index
    ref: client.ref || null, // Include client's document reference
    name: client.name || "", // Use client's name or default 'Unknown'
    client_type: client.client_type || "", // Use client's type or default
    ville: client.ville || "", // Use client's city or default
    folders: client.folders?.length || 0, // Use client's city or default
  }));

  return { rows, columns }; // Return rows and columns for the DataGrid
}

// Transform clients into a DataGrid-friendly format
export function transformOrgsToTable(orgs) {
  // Define columns for DataGrid, customize based on your data structure
  const columns = [
    { field: "id", headerName: "ID", width: 300 },

    { field: "email", headerName: "Email", width: 250 },
    { field: "name", headerName: "Nom", width: 200 },

    { field: "type", headerName: "Type", width: 250 },
    { field: "ville", headerName: "Ville", width: 150 },
    // Add more columns as needed
  ];

  // Create rows from the clients array
  const rows = orgs.map((org, index) => ({
    id: org.id || index + 1, // Use client's ID or default index
    ref: org.ref || null, // Include client's document reference
    name: org.name || "", // Use client's name or default 'Unknown'
    email: org.email || "", // Use client's name or default 'Unknown'

    type: org.type || "", // Use client's type or default
    ville: org.ville || "", // Use client's city or default
  }));

  return { rows, columns }; // Return rows and columns for the DataGrid
}

// Transform clients into a DataGrid-friendly format
export function transformLogsToTable(logs, theme) {
  // Define columns for DataGrid, customize based on your data structure
  const columns = [
    { field: "date", headerName: "Date", width: 150 },
    { field: "realdate", headerName: "Time", width: 80 },

    { field: "type", headerName: "Type", width: 80 },

    { field: "url", headerName: "Url", width: 250 },

    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        // Customize color based on status value
        const status = params.value;
        let textColor;
        let backgroundColor;

        if (status >= 500) {
          textColor = theme.palette.accent2.main; // Error status text color
          backgroundColor = theme.palette.accent2.light; // Error status background
        } else if (status >= 400) {
          textColor = theme.palette.accent3.main; // Warning status text color
          backgroundColor = theme.palette.accent3.light; // Warning status background
        } else {
          textColor = theme.palette.accent1.main; // Success status text color
          backgroundColor = theme.palette.accent1.light; // Success status background
        }

        return (
          <div
            style={{
              backgroundColor: backgroundColor, // Set background color
              color: textColor, // Set text color
              borderRadius: "4px", // Add rounded corners
              padding: "2px 8px", // Add padding
            }}
          >
            <span>{status}</span>
          </div>
        );
      },
    },

    { field: "email", headerName: "Email", width: 250 },

    { field: "org", headerName: "Org", width: 150 },

    // Add more columns as needed
  ];

  // Create rows from the clients array
  const rows = logs.map((log, index) => ({
    id: log.id || index + 1, // Use client's ID or default index
    ref: log.ref || null, // Include client's document reference
    realdate: log.date.seconds * 1000 || 0,
    date:
      new Date(log.date.seconds * 1000).toLocaleString("fr-FR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }) || "", // Keep as Firebase Timestamp, will be transformed by valueGetter

    url:
      typeof log.url === "string"
        ? log.url.replace("https://api.hexagone.expert/", "")
        : "",
    type: log.type || "", // Use client's type or default

    status: log.status || "", // Use client's type or default
    org: log.orgref?.id || "", // Use client's city or default
    email: log.email || "", // Use client's city or default
  }));

  return { rows, columns }; // Return rows and columns for the DataGrid
}

// Transform clients into a DataGrid-friendly format
export function transformExpertsToTable(experts) {
  // Define columns for DataGrid, customize based on your data structure
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },

    { field: "name", headerName: "Nom", flex: 1 },
    { field: "ville", headerName: "Ville", flex: 1 },
    {
      field: "folders",
      headerName: "Dossiers",
      width: 100,
      renderCell: (params) => {
        let value = params.value;
        return (
          <div className="flex flex-row gap-2 items-center justify-center">
            {value}
            <Icon1 icon_name="folder" fill="grey"></Icon1>
          </div>
        );
      },
    },
    // Add more columns as needed
  ];

  // Create rows from the clients array
  const rows = experts.map((expert, index) => ({
    id: expert.id || index + 1, // Use client's ID or default index
    ref: expert.ref || null, // Include client's document reference
    name: expert.name || "", // Use client's name or default 'Unknown'
    ville: expert.ville || "", // Use client's city or default$
    folders: expert.folders?.length || 0, // Use client's city or default
  }));

  return { rows, columns }; // Return rows and columns for the DataGrid
}

// Transform articles into a DataGrid-friendly format
export function transformArticlesToTable(articles, orgData, clientRef) {
  const tarifsDict = {};

  // Process the articles and organization data to create the tarifs dictionary
  if (!orgData.negoces) {
    articles.forEach((article) => {
      tarifsDict[article.id] = {
        tarif_ht: article.base_price,
        tarif_ttc: article.base_price * 1.2,
      };
    });
  } else {
    articles.forEach((article) => {
      tarifsDict[article.id] = {
        tarif_ht: article.base_price,
        tarif_ttc: article.base_price * 1.2,
      };

      orgData.negoces.forEach((negoce) => {
        if (
          negoce.client_o === clientRef &&
          negoce.article_ref === article.ref
        ) {
          tarifsDict[article.id] = {
            tarif_ht: negoce.price,
            tarif_ttc: negoce.price_ttc,
          };
        }
      });
    });
  }

  // Define columns for DataGrid, customize based on your data structure
  const columns = [
    { field: "name", headerName: "Nom", width: 200 },
    { field: "base_price", headerName: "Prix de base", width: 250 },
    { field: "ht_price", headerName: "Tarif HT", width: 150 },
    { field: "ttc_price", headerName: "Tarif TTC", width: 150 },
  ];

  // Create rows from the articles array
  const rows = articles.map((article, index) => ({
    id: article.id || index + 1,
    ref: article.ref || null, // Include article document reference
    name: article.name || "",
    base_price: article.base_price || 0,
    ht_price: tarifsDict[article.id].tarif_ht || 0,
    ttc_price: tarifsDict[article.id].tarif_ttc || 0,
    action: 0,
  }));

  return { rows, columns }; // Return rows and columns for the DataGrid
}

function isArchived(folder, orgData) {
  if (!orgData) {
    console.error("isArchived: orgData is null");
    return false;
  } else if (!folder.visions) {
    console.error("isArchived: visions is null");
    return false;
  } else {
    const vision = folder.visions.find(
      (vision) => vision.org_ref.id === orgData.id
    );
    if (vision) {
      if (vision.state === "archived") {
        return true;
      } else {
        return false;
      }
    } else {
      console.error("isArchived: vision is null " + folder.nom);
      console.error(folder.visions);
    }
  }
}

function intToHexColor(intColor) {
  return `#${(intColor >>> 0).toString(16).slice(-6)}`;
}

export function transformFoldersToTable(
  folders,
  tabindex,
  orgData,
  theme,
  openTag,
  openTag2,
  searchValue,
  month,
  year,
  searchFolders
) {
  if (searchValue !== "" && searchFolders) {
    folders = folders.filter((folder) => folder.nom === searchValue);
  } else if (searchValue !== "" && !searchFolders) {
    console.log("search by client name" + searchValue);
    // search by client name
    folders = folders.filter((folder) => {
      return folder.folder_entreprise_name === searchValue;
    });
  } else {
    if (tabindex === 0) {
      // intervention à programmer
      folders = folders.filter(
        (folder) => folder.intervention_realisee === false
      );
      folders = folders.filter(
        (folder) => isArchived(folder, orgData) === false
      );
    } else if (tabindex === 1) {
      // intervention réalisée
      folders = folders.filter(
        (folder) => folder.intervention_realisee === true
      );
      folders = folders.filter(
        (folder) => isArchived(folder, orgData) === false
      );
    } else if (tabindex === 2) {
      folders = folders.filter(
        (folder) => isArchived(folder, orgData) === true
      );
    }
  }

  if (month && year) {
    folders = folders.filter((folder) => {
      const date = new Date(folder.created_time?.seconds * 1000);
      console.log(date.getMonth());
      console.log(date.getFullYear());
      return date.getMonth() === month && date.getFullYear() === year;
    });
  }

  // Define columns for DataGrid, customize based on your data structure
  const columns = [
    { field: "id", headerName: "ID", width: 300 },
    { field: "nom", headerName: "Nom", width: 200 },
    { field: "ville", headerName: "Ville", width: 150 },
    { field: "entreprise_name", headerName: "Client", width: 140 },
    { field: "date", headerName: "Intervention", width: 140, type: "date" },
    { field: "pack_name", headerName: "Prestation", width: 140 },
    {
      field: "tags",
      headerName: "Tag 1",
      flex: 1,

      renderCell: (params) => {
        // Customize color based on status value
        const tags = params.value;
        const handleOpenTag = (e) => {
          e.stopPropagation();
          openTag(params.row.id);
        };
        const [isHovered, setIsHovered] = useState(false);
        if (tags) {
          const tag = tags.find((tag) => tag.org_ref.id === orgData.id) || null;
          if (tag) {
            const nom = tag.nom;
            const colorint = tag.colorint;
            const color = intToHexColor(colorint);

            return (
              <div
                className="h-5"
                onClick={handleOpenTag}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div
                  className="inline-flex items-center rounded-full h-full px-2 py-1"
                  style={{
                    backgroundColor: isHovered
                      ? theme.palette.shadow._10
                      : color,
                    color: isHovered ? theme.palette.shadow._25 : "black",
                  }}
                >
                  {nom}
                </div>
              </div>
            );
          }
        }

        return (
          <div
            className="h-5"
            onClick={handleOpenTag}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              className="inline-flex items-center rounded-full h-full px-2 py-1"
              style={{
                backgroundColor: isHovered
                  ? theme.palette.shadow._10
                  : "transparent",
                color: isHovered
                  ? theme.palette.shadow._25
                  : theme.palette.shadow._25,
              }}
            >
              Pas de tag
            </div>
          </div>
        );
      },
      sortComparator: (v1, v2, params1, params2) => {
        const tag1 =
          params1.value.find((tag) => tag.org_ref.id === orgData.id)?.nom ||
          "z";
        const tag2 =
          params2.value.find((tag) => tag.org_ref.id === orgData.id)?.nom ||
          "z";
        return tag1.localeCompare(tag2); // Sorting alphabetically by the tag name
      },
    },
    {
      field: "tags2",
      headerName: "Tag 2",
      flex: 1,

      renderCell: (params) => {
        // Customize color based on status value
        const tags = params.value;
        const handleOpenTag2 = (e) => {
          e.stopPropagation();
          openTag2(params.row.id);
        };
        const [isHovered, setIsHovered] = useState(false);
        if (tags) {
          const tag = tags.find((tag) => tag.org_ref.id === orgData.id) || null;
          if (tag) {
            const nom = tag.nom;
            const colorint = tag.colorint;
            const color = intToHexColor(colorint);

            return (
              <div
                className="h-5"
                onClick={handleOpenTag2}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div
                  className="inline-flex items-center rounded-full h-full px-2 py-1"
                  style={{
                    backgroundColor: isHovered
                      ? theme.palette.shadow._10
                      : color,
                    color: isHovered ? theme.palette.shadow._25 : "black",
                  }}
                >
                  {nom}
                </div>
              </div>
            );
          }
        }

        return (
          <div
            className="h-5"
            onClick={handleOpenTag2}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              className="inline-flex items-center rounded-full h-full px-2 py-1"
              style={{
                backgroundColor: isHovered
                  ? theme.palette.shadow._10
                  : "transparent",
                color: isHovered
                  ? theme.palette.shadow._25
                  : theme.palette.shadow._25,
              }}
            >
              Pas de tag
            </div>
          </div>
        );
      },
      sortComparator: (v1, v2, params1, params2) => {
        const tag1 =
          params1.value.find((tag) => tag.org_ref.id === orgData.id)?.nom ||
          "z";
        const tag2 =
          params2.value.find((tag) => tag.org_ref.id === orgData.id)?.nom ||
          "z";
        return tag1.localeCompare(tag2); // Sorting alphabetically by the tag name
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        // Customize color based on status value

        const actions = params.value;
        if (actions == -10) {
          return (
            <div className="h-5">
              <div
                className="inline-flex items-center rounded-full h-full px-2 py-1 text-accent1"
                style={{
                  backgroundColor: theme.palette.accent1.light, // dynamically set the background color
                }}
              >
                {"#finalisé"}
              </div>
            </div>
          );
        } else if (actions === 0) {
          return <> </>;
        }

        return (
          <div className="h-5">
            <div
              className="inline-flex items-center rounded-full h-full px-2 py-1 text-white"
              style={{
                backgroundColor: "rgba(255, 100, 100, 1)", // dynamically set the background color
              }}
            >
              {actions + " actions"}
            </div>
          </div>
        );
      },
    },

    {
      field: "notifs",
      headerName: "Msg",
      width: 50,
      renderCell: (params) => {
        // Customize color based on status value

        if (params.value) {
          return (
            <div className="items-center align-middle h-full inline-flex">
              <Icon1 icon_name="message"></Icon1>
            </div>
          );
        } else {
          return <></>;
        }
      },
    },

    // Add more columns as needed
  ];

  // Create rows from the clients array
  const rows = folders.map((folder, index) => ({
    id: folder.id || index + 1, // Use client's ID or default index
    ref: folder.ref || null, // Include client's document reference
    nom: folder.nom || "", // Use client's name or default 'Unknown'
    ville: folder.ville || "", // Use client's type or default
    entreprise_name: folder.folder_entreprise_name || "", // Use client's type or default
    date: folder.intervention_time_int
      ? new Date(folder.intervention_time_int * 1000)
      : null, // Use client's city or default
    pack_name: folder.pack_name || "", // Use client's city or default
    tags: folder.tags || [],
    tags2: folder.tags2 || [],
    actions:
      folder.reglement_state == "done"
        ? -10
        : folder.counters.find((counter) => counter.org_ref.id == orgData.id)
            .counter || 0,
    notifs: folder.notified_orgs?.map((org) => org.id).includes(orgData.id)
      ? true
      : false || false,
  }));

  return { rows, columns }; // Return rows and columns for the DataGrid
}

export function transformInvoicesToTable(invoices, theme) {
  // Define columns for DataGrid, customize based on your data structure
  const columns = [
    { field: "nom", headerName: "Nom", flex: 2 },
    { field: "entreprise_name", headerName: "Client", flex: 1 },
    { field: "date", headerName: "Date", flex: 1, type: "date" },
    { field: "phone", headerName: "Téléphone", flex: 1 },
    { field: "email", headerName: "Email", flex: 2 },

    {
      field: "state",
      headerName: "Etat",
      width: 120,
      renderCell: (params) => {
        let state = params.value;
        // Customize color based on status value

        return (
          (state === "wait" && (
            <div
              className="flex flex-row px-2 py-0.5 items-center"
              style={{
                backgroundColor: theme.palette.accent3.light,
                color: theme.palette.accent3.main,
              }}
            >
              En attente
            </div>
          )) ||
          (state === "done" && (
            <div
              className="flex flex-row px-2 py-0.5 items-center"
              style={{
                backgroundColor: theme.palette.accent1.light,
                color: theme.palette.accent1.main,
              }}
            >
              Signé
            </div>
          )) ||
          (state === "error" && (
            <div
              className="flex flex-row px-2 py-0.5 items-center"
              style={{
                backgroundColor: theme.palette.accent2.light,
                color: theme.palette.accent2.main,
              }}
            >
              Erreur
            </div>
          ))
        );
      },
    },

    // Add more columns as needed
  ];

  // Create rows from the clients array
  const rows = invoices.map((invoice, index) => ({
    id: invoice.id || index + 1, // Use client's ID or default index
    ref: invoice.ref || null, // Include client's document reference
    nom: invoice.nom || "", // Use client's name or default 'Unknown'
    entreprise_name: invoice.mandataire_name || "", // Use client's type or default
    date: invoice.creation_date
      ? new Date(invoice.creation_date.seconds * 1000)
      : null, // Use client's city or default
    phone: invoice.tel_client || "", // Use client's name or default 'Unknown'

    email: invoice.email_client || "", // Use client's name or default 'Unknown'

    state: invoice.state || "", // Use client's city or default
  }));

  return { rows, columns }; // Return rows and columns for the DataGrid
}
