import React, { useState } from "react";
import Icon1 from "../icons/Icon.js";
import SecondaryButton from "../buttons/SecondaryButton.js";
import extractXml from "../api/xml/extractXml.js";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken.js";
import { set } from "date-fns";
import PrimaryButton from "../buttons/PrimaryButton.js";
import generatePDF from "../api/xml/generatePDF.js";
import extractXmlBoth from "../api/xml/extractXmlBoth.js";
import generateBoth from "../api/xml/generateBoth.js";
import TextField2 from "../textfields/TextField2.js";
import DropDown1 from "../dropdowns/DropDown1.js";
import Space from "../layout/Space.js";
import { useEffect } from "react";
import {
  getArrayDocDataByRef,
  updateDocDataField,
} from "../models/DataFunctions.js";
import sendInfiltreaCreateEnveloppeMission from "../api/infiltrea/sendInfiltreaCreateEnveloppeMission.js";
import sendInfiltreaCreateVentilationMission from "../api/infiltrea/sendInfiltreaCreateVentilationMission.js";
import sendInfiltreaCreateOperation from "../api/infiltrea/sendInfiltreaCreateOperation.js";

const NewMission = ({ document, folder, orgData, onClose, onUpdate }) => {
  const [step, setStep] = useState("choice");

  const [allfields, setAllFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [path, setPath] = useState("");
  const [url, setUrl] = useState("");

  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState("");

  const [missions, setMissions] = useState([
    { value: "Mission Enveloppe", label: "Mission Enveloppe" },
    { value: "Mission Ventilation", label: "Mission Ventilation" },
  ]);
  const [mission, setMission] = useState("");

  useEffect(() => {
    const fetchOperators = async () => {
      if (orgData) {
        const users = await getArrayDocDataByRef(orgData.users);
        console.log(users);
        const operators = users.filter((user) => user.user_type === "operator");
        if (operators.length === 0) {
          console.log("No operators found");
        }
        const operators_dict = operators.map((operator) => ({
          value: operator.id,
          label: operator.display_name,
        }));
        console.log(operators_dict);
        setOperators(operators_dict);
        setOperator(operators_dict[0].value);
        setMission(missions[0].value);
      }
    };

    fetchOperators();
  }, [orgData]);

  const handleGenerateMission = async () => {
    const token = await getOrRefreshFirebaseToken();
    const folderId = folder.id;
    const operation_id = await sendInfiltreaCreateOperation(
      folderId,
      operator,
      token,
    );

    let missionid = "";
    if (mission === "Mission Enveloppe") {
      missionid = await sendInfiltreaCreateEnveloppeMission(
        folderId,
        operator,
        operation_id,
        token,
      );
    } else {
      missionid = await sendInfiltreaCreateVentilationMission(
        folderId,
        operator,
        operation_id,
        token,
      );
    }

    onUpdate();
  };

  return (
    <div className="flex flex-col p-3 gap-2">
      {step === "choice" && (
        <>
          <div className="w-c300 text-h3 text-shadow_50">
            Sélectionnez un type de mission pour créer une mission sur votre
            interface infiltrea à partir des informations du dossier.
          </div>
          <Space y={10} />

          <DropDown1
            label="Type de mission"
            className="w-full"
            value={mission}
            items={missions}
            onChange={(e) => setMission(e.target.value)}
          ></DropDown1>
          <DropDown1
            label="Opérateur"
            className="w-full"
            value={operator}
            items={operators}
            onChange={(e) => setOperator(e.target.value)}
          ></DropDown1>

          <SecondaryButton onClick={handleGenerateMission}>
            Générer Mission Infiltrea
          </SecondaryButton>
        </>
      )}

      {(step == "carbon" || step == "both") && (
        <div className="flex flex-col items-center">
          <Icon1 name="check" className="text-green-500" />
          <p>Choisir les champs</p>
          <div className="flex flex-col gap-2">
            {allfields.map((field) => (
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <p>{field.nom}</p>
              </div>
            ))}
          </div>
          <PrimaryButton onClick={generateCarbone}>Générer</PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default NewMission;
