import {
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  limit,
  orderBy,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../config/FirebaseConfig.js"; // Import the Firebase setup

export async function getDocDataById(collection, id) {
  if (!collection || !id) {
    console.error("Collection or ID is missing");
    return null;
  }
  const docRef = doc(db, collection, id);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    return { id: id, ref: docRef, ...docSnapshot.data() }; // Return plain object with document reference and ID
  }
  return null; // Return null if no document exists
}

export async function getDocDataByRef(docRef) {
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    return { id: docSnapshot.id, ref: docRef, ...docSnapshot.data() }; // Return plain object with document reference and ID
  }
  return null; // Return null if no document exists
}

export async function createDoc(collectionName, data) {
  // Create a reference to the collection
  const docRef = doc(collection(db, collectionName)); // Automatically generates a document ID
  // Write the document
  await setDoc(docRef, data);
  // Fetch the newly created document
  const docSnapshot = await getDoc(docRef);
  return { id: docSnapshot.id, ref: docRef, ...docSnapshot.data() }; // Return the document's data with its ID and reference
}

export async function deleteDocById(collection, id) {
  const docRef = doc(db, collection, id);
  await deleteDoc(docRef);
  console.log("Document deleted successfully!");
}

export async function deleteDocByRef(docRef) {
  await deleteDoc(docRef);
}

export async function getArrayDocDataByRef(listdocRef) {
  if (!listdocRef) {
    console.error("List of document references is missing");
    return [];
  }
  const docs = [];
  for (const docRef of listdocRef) {
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      docs.push({
        id: docRef.id,
        ref: docRef,
        ...docSnapshot.data(),
      }); // Include document reference and ID
    }
  }
  return docs;
}

export async function updateDocDataField(docRef, field, value) {
  await setDoc(docRef, { [field]: value }, { merge: true });
}

export async function addToArrayField(docRef, field, item) {
  await setDoc(
    docRef,
    { [field]: arrayUnion(item) }, // Use arrayUnion to add item to array
    { merge: true } // Ensure existing data is not overwritten
  );
}

export function getRefFromId(collection, id) {
  return doc(db, collection, id);
}

export async function getDocumentsByFolderRef(folderRef) {
  const docSnapshot = await getDoc(folderRef);
  const docData = docSnapshot.data();
  const docs = [];
  for (const docRef of docData.documents) {
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      docs.push({
        id: docRef.id,
        ref: docRef,
        ...docSnapshot.data(),
      }); // Include document reference and ID
    }
  }
  return docs;
}

export function TruncatedString(text, maxLength = 20) {
  const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  return truncatedText;
}

// Dynamic query function
export async function queryCollection(
  collectionName,
  filters,
  limite = 1000,
  sortingField = null,
  sortingOrder = "asc"
) {
  if (!collectionName) {
    console.error("Collection name is missing");
    return null;
  }

  // Initialize query with the collection reference
  let objectsQuery = collection(db, collectionName);

  // Apply dynamic filters if provided
  if (filters && filters.length > 0) {
    const queries = filters.map((filter) =>
      where(filter.field, filter.operator, filter.value)
    );
    objectsQuery = query(objectsQuery, ...queries);
  }

  // Apply sorting if a sortingField is provided
  if (sortingField) {
    objectsQuery = query(objectsQuery, orderBy(sortingField, sortingOrder));
  }

  // Apply the limit to the query
  objectsQuery = query(objectsQuery, limit(limite));

  try {
    // Fetch the documents that match the query
    const querySnapshot = await getDocs(objectsQuery);

    // Map the documents to an array of data with document ID and reference
    const objects = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ref: doc.ref,
      ...doc.data(),
    }));

    return objects;
  } catch (error) {
    console.error("Error querying collection:", error);
    return null;
  }
}
