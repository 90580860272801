import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function TextField1({ label = "Mot de passe", sx, value, onChange }) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{ fontSize: "14px", ...sx }} // Set full width for the TextField container
      variant="outlined"
    >
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{
          top: "-20%", // Position the label vertically centered
          //transform: "translateY(-50%)", // Vertically center the label
          left: "0px", // Adjust the left position if needed
          marginRight: "0px", // Adjust the right margin if needed
          "&.Mui-focused": {
            top: "0%", // Keep the label on top when focused or typing
            // transform: "translateY(0)", // Reset transformation when label shrinks
            fontSize: "14px", // Adjust the label size when it shrinks
          },
          "&.MuiInputLabel-shrink	": {
            top: "0%", // Keep the label on top when focused or typing
            // transform: "translateY(0)", // Reset transformation when label shrinks
            fontSize: "14px", // Adjust the label size when it shrinks
          },
        }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        value={value}
        onChange={onChange}
        inputProps={{
          autoComplete: "password", // This is where you add the autocomplete attribute
        }}
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        sx={{
          borderRadius: "12px", // Custom border radius for the input
          width: "100%", // Set width to 100% of the container
          height: "40px", // Adjust the height of the input
          fontSize: "14px", // Adjust the font size of the input
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="small"
              sx={{ color: "shadow._25" }} // Remove padding from the IconButton
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
}

export default TextField1;
