import React from "react";
import { ReactComponent as Cle } from "./icons8-clé.svg"; // Import SVG as React component
import { ReactComponent as CodeFile } from "./icons8-code-file.svg"; // Import SVG as React component
import { ReactComponent as CompteMarchand } from "./icons8-compte-marchand.svg"; // Import SVG as React component
import { ReactComponent as Content } from "./icons8-content.svg"; // Import SVG as React component
import { ReactComponent as CurseurMain } from "./icons8-curseur-main.svg"; // Import SVG as React component
import { ReactComponent as Curseur } from "./icons8-curseur.svg"; // Import SVG as React component
import { ReactComponent as Smartphone } from "./icons8-iphone-x.svg"; // Import SVG as React component
import { ReactComponent as DeuxSmartphone } from "./icons8-deux-smartphones.svg"; // Import SVG as React component
import { ReactComponent as Done } from "./icons8-done-50.svg"; // Import SVG as React component
import { ReactComponent as Download } from "./icons8-download-50.svg"; // Import SVG as React component
import { ReactComponent as Effacer } from "./icons8-effacer.svg"; // Import SVG as React component
import { ReactComponent as Email } from "./icons8-email.svg"; // Import SVG as React component
import { ReactComponent as ErreurOrdinateur } from "./icons8-erreur-d&#39;ordinateur-portable.svg"; // Import SVG as React component
import { ReactComponent as Erreur } from "./icons8-erreur.svg"; // Import SVG as React component
import { ReactComponent as Expert } from "./icons8-expert.svg"; // Import SVG as React component
import { ReactComponent as Hexagone } from "./HexagoneFav451_black.svg"; // Import SVG as React component
import { ReactComponent as Home } from "./icons8-home-50.svg"; // Import SVG as React component
import { ReactComponent as Message } from "./icons8-speech-bubble-50.svg"; // Import SVG as React component
import { ReactComponent as Folder } from "./icons8-folder-50.svg"; // Import SVG as React component
import { ReactComponent as Facture } from "./icons8-facture.svg"; // Import SVG as React component
import { ReactComponent as Client } from "./icons8-utilisateur (1).svg"; // Import SVG as React component
import { ReactComponent as Intervenant } from "./icons8-expert.svg"; // Import SVG as React component
import { ReactComponent as Settings } from "./icons8-settings-50.svg"; // Import SVG as React component
import { ReactComponent as Devis } from "./icons8-ordre-d'achat (2).svg"; // Import SVG as React component
import { ReactComponent as Tag } from "./icons8-étiquette-de-prix (2).svg"; // Import SVG as React component
import { ReactComponent as Filtre } from "./icons8-filtre (2).svg"; // Import SVG as React component
import { ReactComponent as Integration } from "./icons8-puzzle.svg"; // Import SVG as React component
import { ReactComponent as Boutique } from "./icons8-compte-marchand.svg"; // Import SVG as React component
import { ReactComponent as Facturation } from "./icons8-carte-bancaire-dollar.svg"; // Import SVG as React component
import { ReactComponent as Logout } from "./icons8-logout-50.svg"; // Import SVG as React component
import { ReactComponent as Question } from "./icons8-question.svg"; // Import SVG as React component
import { ReactComponent as FullScreen } from "./icons8-plein-écran.svg"; // Import SVG as React component
import { ReactComponent as Heure } from "./icons8-heure-de-livraison.svg"; // Import SVG as React component
import { ReactComponent as Horloge } from "./icons8-horloge.svg"; // Import SVG as React component
import { ReactComponent as Cloche } from "./icons8-rappels-de-rendez-vous.svg"; // Import SVG as React component
import { ReactComponent as Logs } from "./icons8-liste-des-transactions.svg"; // Import SVG as React component
import { ReactComponent as Search } from "./icons8-chercher.svg"; // Import SVG as React component
import { ReactComponent as Close } from "./icons8-effacer.svg"; // Import SVG as React component
import { ReactComponent as Right } from "./icons8-vers-l'avant.svg"; // Import SVG as React component
import { ReactComponent as Left } from "./icons8-arrière.svg"; // Import SVG as React component
import { ReactComponent as Menu } from "./icons8-menu.svg"; // Import SVG as React component
import { ReactComponent as Modify } from "./icons8-modifier.svg"; // Import SVG as React component
import { ReactComponent as Delete } from "./icons8-trash-can-50.svg"; // Import SVG as React component
import { ReactComponent as Plus } from "./icons8-plus-math-50.svg"; // Import SVG as React component
import { ReactComponent as Minus } from "./icons8-moins.svg"; // Import SVG as React component
import { ReactComponent as Planning } from "./icons8-calendrier.svg"; // Import SVG as React component
import { ReactComponent as PDF } from "./icons8-pdf.svg"; // Import SVG as React component
import { ReactComponent as Join } from "./icons8-trombone.svg"; // Import SVG as React component
import { ReactComponent as Restauration } from "./icons8-restauration.svg"; // Import SVG as React component
import { ReactComponent as Rapport } from "./icons8-test-results-50.svg"; // Import SVG as React component
import { ReactComponent as Share } from "./icons8-transférer.svg"; // Import SVG as React component
import { ReactComponent as Archive } from "./icons8-classeur.svg"; // Import SVG as React component
import { ReactComponent as Date } from "./icons8-calendrier-12.svg"; // Import SVG as React component
import { ReactComponent as Organisation } from "./icons8-organisation.svg"; // Import SVG as React component
import { ReactComponent as Pinceau } from "./icons8-pinceau.svg"; // Import SVG as React component
import { ReactComponent as DeuxSmartphones } from "./icons8-deux-smartphones.svg"; // Import SVG as React component
import { ReactComponent as TestResults } from "./icons8-test-results-50.svg"; // Import SVG as React component
import { ReactComponent as NouveauMessage } from "./icons8-nouveau-message.svg"; // Import SVG as React component
import { ReactComponent as Sign } from "./icons8-main-avec-stylo.svg"; // Import SVG as React component
import { ReactComponent as Send } from "./icons8-envoyé.svg"; // Import SVG as React component
import { ReactComponent as Image } from "./icons8-image.svg"; // Import SVG as React component
import { ReactComponent as Youtube } from "./icons8-youtube.svg"; // Import SVG as React component
import { ReactComponent as Education } from "./icons8-chapeau-de-diplôme.svg"; // Import SVG as React component
import { ReactComponent as Lock } from "./icons8-lock-50.svg"; // Import SVG as React component
import { ReactComponent as Down } from "./icons8-flèche-développer.svg"; // Import SVG as React component
import { ReactComponent as Up } from "./icons8-flèche-réduire.svg"; // Import SVG as React component
import { ReactComponent as Tampon } from "./icons8-tampon-encreur.svg"; // Import SVG as React component
import { ReactComponent as Warning } from "./icons8-attention.svg"; // Import SVG as React component
import { ReactComponent as Param } from "./icons8-paramètres (1).svg"; // Import SVG as React component

function Icon1({
  icon_name = "clé",
  width = "20px",
  height = "20px",
  fill = "black",
  margin = "0px",
  padding = "0px",
  sx,
}) {
  const defaultStyle = { width, height, fill, margin, padding };

  let html;

  if (icon_name === "clé") html = <Cle style={defaultStyle} sx={{ ...sx }} />;
  if (icon_name === "code-file") html = <CodeFile style={defaultStyle} />;
  if (icon_name === "compte-marchand")
    html = <CompteMarchand style={defaultStyle} />;
  if (icon_name === "content") html = <Content style={defaultStyle} />;
  if (icon_name === "curseur-main") html = <CurseurMain style={defaultStyle} />;
  if (icon_name === "curseur") html = <Curseur style={defaultStyle} />;
  if (icon_name === "deux-smartphones")
    html = <DeuxSmartphone style={defaultStyle} />;
  if (icon_name === "done") html = <Done style={defaultStyle} />;
  if (icon_name === "download") html = <Download style={defaultStyle} />;
  if (icon_name === "effacer") html = <Effacer style={defaultStyle} />;
  if (icon_name === "email") html = <Email style={defaultStyle} />;
  if (icon_name === "erreur-ordinateur")
    html = <ErreurOrdinateur style={defaultStyle} />;
  if (icon_name === "erreur") html = <Erreur style={defaultStyle} />;
  if (icon_name === "expert") html = <Expert style={defaultStyle} />;
  if (icon_name === "hexagone") html = <Hexagone style={defaultStyle} />;
  if (icon_name === "home") html = <Home style={defaultStyle} sx={{ ...sx }} />;
  if (icon_name === "message") html = <Message style={defaultStyle} />;
  if (icon_name === "folder") html = <Folder style={defaultStyle} />;
  if (icon_name === "facture") html = <Facture style={defaultStyle} />;
  if (icon_name === "client") html = <Client style={defaultStyle} />;
  if (icon_name === "intervenant") html = <Intervenant style={defaultStyle} />;
  if (icon_name === "settings") html = <Settings style={defaultStyle} />;
  if (icon_name === "devis") html = <Devis style={defaultStyle} />;
  if (icon_name === "tag") html = <Tag style={defaultStyle} />;
  if (icon_name === "filtre") html = <Filtre style={defaultStyle} />;
  if (icon_name === "integration") html = <Integration style={defaultStyle} />;
  if (icon_name === "boutique") html = <Boutique style={defaultStyle} />;
  if (icon_name === "facturation") html = <Facturation style={defaultStyle} />;
  if (icon_name === "logout") html = <Logout style={defaultStyle} />;
  if (icon_name === "question") html = <Question style={defaultStyle} />;
  if (icon_name === "full-screen") html = <FullScreen style={defaultStyle} />;
  if (icon_name === "heure") html = <Heure style={defaultStyle} />;
  if (icon_name === "horloge") html = <Horloge style={defaultStyle} />;
  if (icon_name === "cloche") html = <Cloche style={defaultStyle} />;
  if (icon_name === "logs") html = <Logs style={defaultStyle} />;
  if (icon_name === "search") html = <Search style={defaultStyle} />;
  if (icon_name === "close") html = <Close style={defaultStyle} />;
  if (icon_name === "right") html = <Right style={defaultStyle} />;
  if (icon_name === "left") html = <Left style={defaultStyle} />;
  if (icon_name === "menu") html = <Menu style={defaultStyle} />;
  if (icon_name === "modify") html = <Modify style={defaultStyle} />;
  if (icon_name === "delete") html = <Delete style={defaultStyle} />;
  if (icon_name === "plus") html = <Plus style={defaultStyle} />;
  if (icon_name === "minus") html = <Minus style={defaultStyle} />;
  if (icon_name === "planning") html = <Planning style={defaultStyle} />;
  if (icon_name === "pdf") html = <PDF style={defaultStyle} />;
  if (icon_name === "join") html = <Join style={defaultStyle} />;
  if (icon_name === "restauration")
    html = <Restauration style={defaultStyle} />;
  if (icon_name === "rapport") html = <Rapport style={defaultStyle} />;
  if (icon_name === "share") html = <Share style={defaultStyle} />;
  if (icon_name === "archive") html = <Archive style={defaultStyle} />;
  if (icon_name === "date") html = <Date style={defaultStyle} />;
  if (icon_name === "organisation")
    html = <Organisation style={defaultStyle} />;
  if (icon_name === "pinceau") html = <Pinceau style={defaultStyle} />;
  if (icon_name === "deux-smartphones")
    html = <DeuxSmartphones style={defaultStyle} />;
  if (icon_name === "test-results") html = <TestResults style={defaultStyle} />;
  if (icon_name === "nouveau-message")
    html = <NouveauMessage style={defaultStyle} />;
  if (icon_name === "sign") html = <Sign style={defaultStyle} />;
  if (icon_name === "send") html = <Send style={defaultStyle} />;
  if (icon_name === "image") html = <Image style={defaultStyle} />;
  if (icon_name === "smartphone") html = <Smartphone style={defaultStyle} />;
  if (icon_name === "youtube") html = <Youtube style={defaultStyle} />;
  if (icon_name === "education") html = <Education style={defaultStyle} />;
  if (icon_name === "lock") html = <Lock style={defaultStyle} />;
  if (icon_name === "down") html = <Down style={defaultStyle} />;
  if (icon_name === "up") html = <Up style={defaultStyle} />;
  if (icon_name === "tampon") html = <Tampon style={defaultStyle} />;
  if (icon_name === "warning") html = <Warning style={defaultStyle} />;
  if (icon_name === "param") html = <Param style={defaultStyle} />;
  return html;
}

export default Icon1;
