import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../config/FirebaseConfig"; // Import the Firebase setup
import SecondaryButton from "../buttons/SecondaryButton";

// Function to save an article document to Firestore
export async function saveArticle(articleData) {
  const docRef = doc(db, "article", articleData.id);
  await setDoc(docRef, {
    id: articleData.id,
    ref: docRef, // Include the reference
    name: articleData.name,
    pack_ref: articleData.pack_ref,
    org_ref: articleData.org_ref,
    description: articleData.description,
    base_price: articleData.base_price,
  });
}

// Fetch an article document from Firestore by reference
export async function getArticleByRef(articleRef) {
  const docSnapshot = await getDoc(articleRef);
  if (docSnapshot.exists()) {
    return { id: articleRef.id, ref: articleRef, ...docSnapshot.data() }; // Return the article data with the reference and ID
  } else {
    console.log("No such document!");
    return null;
  }
}

const forceToDouble = (value) => {
  // Remove any characters that aren't digits or commas/periods
  if (!value) return 0;
  console.log("value:", value);
  let valueStr = value.toString();
  let cleanValue = valueStr.replace(/[^0-9.,]/g, "");
  // Replace commas with periods for correct parsing
  cleanValue = cleanValue.replace(",", ".");

  // Parse the string as a floating-point number (double)
  let parsedValue = parseFloat(cleanValue);

  // Return the double value or 0 if it's not a valid number
  return isNaN(parsedValue) ? 0 : parsedValue;
};

const formatAsCurrency = (value) => {
  // Ensure the value is a number with two decimal places
  const fixedValue = value.toFixed(2);

  // Replace period with comma
  const formattedValue = fixedValue.replace(".", ",");

  // Append the euro symbol
  return `${formattedValue}€`;
};

// Fetch an article document from Firestore by ID
export async function getArticleById(articleId) {
  const docRef = doc(db, "article", articleId);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    return { id: articleId, ref: docRef, ...docSnapshot.data() }; // Return the article data with the reference and ID
  } else {
    console.log("No such document!");
    return null;
  }
}

export function find_negoce(negoces, client_ref, article_ref) {
  for (const negoce of negoces) {
    if (negoce.client_o === client_ref && negoce.article_ref === article_ref) {
      return negoce;
    }
  }
  return 0;
}

// Transform articles and related negotiation data into a format for DataGrid
export function transformArticlesToTable(
  articles,
  orgData,
  client_ref,
  onOpenTarifModal
) {
  const negoces = orgData.negoces;
  const tarifs_dict = {};

  // If there are no negotiated prices, use base prices
  if (negoces == null) {
    articles.forEach((article) => {
      tarifs_dict[article.id] = {
        tarif_ht: article.base_price,
        tarif_ttc: article.base_price * 1.2,
      };
    });
  } else {
    articles.forEach((article) => {
      tarifs_dict[article.id] = {
        tarif_ht: article.base_price,
        tarif_ttc: article.base_price * 1.2,
      };
      negoces.forEach((negoce) => {
        if (
          negoce.client_o.id === client_ref.id &&
          negoce.article_ref.id === article.ref.id
        ) {
          tarifs_dict[article.id] = {
            tarif_ht: negoce.price,
            tarif_ttc: negoce.price_ttc,
          };
        }
      });
    });
  }

  const handleModify = () => {
    console.log("Modify button clicked");
  };

  // Define columns for DataGrid, customize based on your data structure
  const columns = [
    { field: "name", headerName: "Nom", width: 200 },
    { field: "base_price", headerName: "Prix de base", width: 250 },
    {
      field: "ht_price",
      headerName: "Tarif HT",
      width: 150,
      renderCell: (params) => {
        return <div>{formatAsCurrency(forceToDouble(params.value))}</div>;
      },
    },
    {
      field: "ttc_price",
      headerName: "Tarif TTC",
      width: 150,
      renderCell: (params) => {
        return <div>{formatAsCurrency(forceToDouble(params.value))}</div>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        // Customize color based on status value

        const handleModify = (e) => {
          e.stopPropagation();
          onOpenTarifModal(params.row);
        };

        return (
          <div className="h-5">
            <SecondaryButton onClick={handleModify}>Modifier</SecondaryButton>
          </div>
        );
      },
    },
  ];

  // Create rows from the articles array
  const rows = articles.map((article, index) => ({
    id: article.id || index + 1, // Use article's ID or default index
    ref: article.ref || null, // Include the article reference
    name: article.name || "", // Use article's name or default 'Unknown'
    base_price: article.base_price || 0, // Use article's base price
    ht_price: tarifs_dict[article.id].tarif_ht || 0, // Use negotiated HT price or default
    ttc_price: tarifs_dict[article.id].tarif_ttc || 0, // Use negotiated TTC price or default
  }));

  return { rows, columns }; // Return rows and columns for DataGrid
}
