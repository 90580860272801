import React from "react";
import { Divider as MuiDivider } from "@mui/material";

function Divider({ orientation = "horizontal" }) {
  if (orientation === "vertical") {
    return <div className="border-l-w0.8 border-shadow_10 h-full"></div>;
  } else {
    return <div className=" border-t-w0.8 border-shadow_10 w-full"></div>;
  }
}

export default Divider;
