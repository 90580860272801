import React from "react";
import Row from "../layout/Row";
import Space from "../layout/Space";
import Icon1 from "../icons/Icon";
import { useTheme } from "@mui/material/styles";

function StatsCard({ title, value, icon, stat_className }) {
  const theme = useTheme();
  return (
    <div className="bg-white p-3 rounded-xl border-1 border-shadow_10 w-c250">
      <Row x={0} className="w-full">
        <div className="text-h3 flex-grow ">{title}</div>
        <Icon1 icon_name={icon} fill={theme.palette.shadow._50}></Icon1>
      </Row>
      <Space y={20} />
      <div className={stat_className}>{value}</div>
    </div>
  );
}

export default StatsCard;
