// ApiService.js

export const postData = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "POST", // Use POST, PUT, PATCH, DELETE depending on the operation
      headers: {
        "Content-Type": "application/json", // Content type is JSON
      },
      body: JSON.stringify(data), // The data to be sent in the request body
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const responseData = await response.json(); // Parse response as JSON
    return responseData;
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};
