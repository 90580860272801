import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

function SetupButton({ children, color = "primary", onClick, sx, className }) {
  const theme = useTheme();
  const buttonColor = "rgba(0,0,0,1)";
  return (
    <Button
      variant="contained"
      className={className}
      sx={{
        textTransform: "none",
        padding: "8px 16px",
        border: "0px solid",
        borderRadius: "40px",
        fontSize: "15px",
        lineHeight: 1,
        backgroundColor: buttonColor,
        color: theme.palette[color]?.contrastText,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Custom shadow control
        "&:hover": {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Custom shadow control
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default SetupButton;
