import React, { useRef } from "react";

function TextFieldEmail({
  placeholder = "Message",
  value,
  onChange,
  className = "",
}) {
  const textAreaRef = useRef(null);

  return (
    <textarea
      ref={textAreaRef}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`h-auto max-h-[200px] border-0 w-full p-2 text-sm bg-white text-black rounded-xl
                  focus:outline-none focus:ring-2 focus:ring-blue-500 
                  placeholder-gray-400 transition duration-300 ease-in-out 
                  resize-none ${className}`}
      rows={7} // Initial row
    />
  );
}

export default TextFieldEmail;
