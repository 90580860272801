import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import { Divider, Switch } from "@mui/material";
import Space from "../layout/Space";
import MenuButton from "../buttons/MenuButton";
import RoundedButton from "../buttons/RoundedButton";
import RoundedButton2 from "../buttons/RoundedButton2";
import Icon1 from "../icons/Icon";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken";
import stripeSubscriptionManager from "../api/stripe/stripeSubscriptionManager";
import { useAppContext } from "../config/AppContext";
import DropDown1 from "../dropdowns/DropDown1";
import stripeCheckoutSession from "../api/stripe/stripeCheckoutSession";
import stripeMobileSubManager from "../api/stripe/stripeMobileSubManager";
import MLTF1 from "../textfields/MLTF1";
import PrimaryButton from "../buttons/PrimaryButton";
import TextFieldChat from "../textfields/TextFieldChat";
import TextFieldEmail from "../textfields/TextFieldEmail";
import sendRequest from "../api/send/sendRequest";

const PlanLine = ({ title }) => {
  return (
    <div className="flex flex-row gap-2">
      <Icon1 icon_name="done" height="16px" width="16px" />
      <div className="text-h3">{title}</div>
    </div>
  );
};

const Support = ({ onClose }) => {
  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const { currentUser, loading } = useAuth();

  const [message, SetMessage] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const handleSendRequest = async () => {
    const token = await getOrRefreshFirebaseToken();
    await sendRequest(orgData.id, message, orgData.email, "01", prefix);
    handleClickVariant("Message envoyé avec succès", "success");
    onClose();
  };

  const handleFetchData = async () => {
    if (currentUser) {
      try {
        const user = await getUserById(currentUser.uid);
        const org = await getOrgByRef(user.org_ref);
        console.log("org:", org);

        setUserData(user);
        setOrgData(org);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData();
    }
  }, [currentUser]);

  if (loading || !currentUser || !userData) {
    return <> Loading ..</>;
  }

  return (
    <div className="bg-white flex flex-col p-4 w-c400 rounded-3xl ">
      <div className="flex flex-col gap-3 w-full">
        <div className="flex flex-row justify-between">
          {" "}
          <div className="text-h3">Message au support</div>
          <div
            className="text-h3 text-shadow_50 cursor-pointer"
            onClick={onClose}
          >
            Fermer
          </div>
        </div>
        <TextFieldEmail
          label="Message"
          value={message}
          onChange={(e) => SetMessage(e.target.value)}
        ></TextFieldEmail>
        <div className="w-full justify-end flex">
          <PrimaryButton className="w-fit" onClick={handleSendRequest}>
            Envoyer
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default Support;
