import React from "react";
import SRSecondaryButton from "./SRSecondaryButton";
import Icon1 from "../icons/Icon";
import Row from "../layout/Row";
import { useTheme } from "@mui/material/styles";

function SRSIconButton({
  icon_name = "question",
  icon_size = "20px",
  fill = null,
  children = "Aide",
  onClick,
  ...props
}) {
  const theme = useTheme();
  return (
    <SRSecondaryButton
      color="secondary"
      onClick={onClick}
      sx={{ padding: "3px 10px 3px 10px" }}
    >
      <div className="text-shadow_50 text-h3">
        <Row x={5}>
          {" "}
          <Icon1
            icon_name={icon_name}
            height={icon_size}
            width={icon_size}
            fill={fill ? fill : theme.palette.shadow._50}
          />
          {children}
        </Row>
      </div>
    </SRSecondaryButton>
  );
}

export default SRSIconButton;
