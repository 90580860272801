import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Icon1 from "../icons/Icon";
import Space from "../layout/Space";
import PrimaryButton from "../buttons/PrimaryButton";
import Row from "../layout/Row";

export default function ExpertsAM({ actionModify, actionDelete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <PrimaryButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Row x={10}>
          Actions <Icon1 icon_name="menu" fill="white"></Icon1>
        </Row>
      </PrimaryButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "200px", // Set the menu width
            backgroundColor: "white", // Set background to white
            borderRadius: "12px", // Round the corners
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)", // Add shadow for better styling
            padding: "4px", // Add padding if needed
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          color: "black",
        }}
      >
        <MenuItem onClick={actionModify}>
          <div className="">
            <Icon1 icon_name="modify"></Icon1>
          </div>
          <Space x={2} />
          <div className="flex flex-grow flex-row-reverse text-h2">
            Modifier
          </div>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <div className="">
            <Icon1 icon_name="delete"></Icon1>
          </div>
          <Space x={2} />
          <div className="flex flex-grow flex-row-reverse text-h2">
            Supprimer
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
