import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import { Divider, Switch } from "@mui/material";
import Space from "../layout/Space";
import MenuButton from "../buttons/MenuButton";
import RoundedButton from "../buttons/RoundedButton";
import RoundedButton2 from "../buttons/RoundedButton2";
import Icon1 from "../icons/Icon";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken";
import stripeSubscriptionManager from "../api/stripe/stripeSubscriptionManager";
import { useAppContext } from "../config/AppContext";
import DropDown1 from "../dropdowns/DropDown1";
import stripeCheckoutSession from "../api/stripe/stripeCheckoutSession";

const SignMarket = ({ onClose }) => {
  const [plan, setPlan] = useState("free_tier");
  const [engagement, setEngagement] = useState(true);
  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const { currentUser, loading } = useAuth();

  const options = [
    { value: 1, label: "1" },
    { value: 10, label: "10" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 500, label: "500" },
    { value: 1000, label: "1000" },
  ];

  const [number, SetNumber] = useState(1);

  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handlePlanChange = async (plan, engagement) => {
    const token = await getOrRefreshFirebaseToken();
    const url = await stripeSubscriptionManager(
      plan,
      engagement,
      token,
      prefix
    );
    if (url === "done") {
      handleClickVariant("Plan changé avec succès", "success");
      handleFetchData();
    } else {
      // open new window and launch url
      window.open(url, "_blank");
    }
  };

  const handleBuySignatures = async () => {
    const token = await getOrRefreshFirebaseToken();
    const url = await stripeCheckoutSession(orgData.id, number, token, prefix);
    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleFetchData = async () => {
    if (currentUser) {
      try {
        const user = await getUserById(currentUser.uid);
        const org = await getOrgByRef(user.org_ref);
        console.log("org:", org);

        console.log("org:", org.facturation.plan);

        setUserData(user);
        setOrgData(org);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData();
    }
  }, [currentUser]);

  if (loading || !currentUser || !userData) {
    return <> Loading ..</>;
  }

  return (
    <div className="bg-white flex flex-col p-4 w-c350 rounded-3xl ">
      <div className="flex flex-row justify-between ">
        <div className="text-h2">Acheter des signatures</div>
        <SecondaryButton onClick={onClose}>Fermer</SecondaryButton>
      </div>
      <div className="text-h3 text-shadow_50 w-c300">
        Choisissez la quantité de signatures électroniques que vous souhaitez
        ajouter.
      </div>
      <Space y={10} />
      <div className="flex flex-row justify-between items-center gap-2 ">
        <div className="w-2/5">
          <DropDown1
            items={options}
            value={number}
            onChange={(e) => SetNumber(e.target.value)}
            sx={{
              borderRadius: "30px",
            }}
          ></DropDown1>
        </div>
        <div
          className="flex flex-row w-3/5 border-2 border-primary rounded-full cursor-pointer hover:shadow-md"
          onClick={handleBuySignatures}
        >
          <div className="bg-primary px-2 text-center py-1 rounded-l-full text-white text-h2 w-1/2">
            Acheter
          </div>
          <div className="bg-white py-1 px-2 text-center rounded-r-full text-primary text-h2 w-1/2">
            {number}€ HT
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center items-center gap-4 w-c1k"></div>
    </div>
  );
};

export default SignMarket;
