import React from "react";
import Button from "@mui/material/Button";

function SecondaryButton({
  startIcon,
  disabled = false,
  children,
  onClick,
  sx,
  className,
}) {
  return (
    <Button
      variant="contained"
      color="secondary"
      className={disabled ? "" : className}
      disabled={disabled}
      sx={{
        textTransform: "none",
        padding: "6px 12px",
        border: "0px solid",
        borderRadius: "10px",
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: 1,
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
        "&:hover": {
          boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
          backgroundColor: "rgba(245, 245,245, 1)",
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <div className="flex flex-row gap-2 items-center">
        {" "}
        {startIcon}
        {children}
      </div>
    </Button>
  );
}

export default SecondaryButton;
