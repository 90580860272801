import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import addLogDocument from "../addLogDocument"; // Assuming you have this function for logging
import axios from "axios"; // For making HTTP requests

// Assuming ChampStruct has a method to convert to JSON
class ChampStruct {
  constructor(type, content, bb, pp, ww, hh, prefix) {
    this.type = type;
    this.content = content;
    this.bb = bb;
    this.pp = pp;
    this.ww = ww;
    this.hh = hh;
  }

  toJson() {
    return {
      type: this.type,
      content: this.content,
      bb: this.bb,
      pp: this.pp,
      ww: this.ww,
      hh: this.hh,
    };
  }
}

const signDevis = async (champs, urlpdf, keyid, devisid, prefix) => {
  const url = prefix + `hexasign/devis/${devisid}/sign`;

  // Modify Champs types and content as per the original logic
  champs.forEach((champ) => {
    if (champ.type === "Initiales") {
      champ.type = "Signature";
    } else if (champ.type === "Coche") {
      champ.type = "Tampon";
      champ.ww = 20;
      champ.hh = 20;
      champ.content =
        champ.content === "true"
          ? "https://firebasestorage.googleapis.com/v0/b/foundry-2dlu01.appspot.com/o/persistent%2Fcochetrue.png?alt=media&token=54b36cc7-13e9-4a07-8edb-dd87705ca982"
          : "https://firebasestorage.googleapis.com/v0/b/foundry-2dlu01.appspot.com/o/persistent%2Fcochefalse.png?alt=media&token=74043f9c-b3fe-450f-b813-8c5e48a70af2";
    }
  });

  const fields = champs;

  // Request body
  const body = {
    pdf: urlpdf,
    fields: fields,
    keyid: keyid,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  try {
    // Send POST request
    const response = await axios.post(url, body, { headers });
    // Check response
    if (response.status === 200) {
      const jsonResponse = response.data;
      return jsonResponse["pdf"];
    } else {
      console.error(`Failed to send request: ${response.status}`);
      return "";
    }
  } catch (error) {
    console.error(`Exception occurred: ${error.message}`);
    return "";
  }
};

export default signDevis;
