import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

function SRPrimaryButton({
  type,
  children,
  color = "primary",
  onClick,
  sx,
  className,
}) {
  const theme = useTheme();
  const buttonColor = theme.palette[color]?.main;
  return (
    <Button
      variant="contained"
      type={type}
      className={className}
      sx={{
        textTransform: "none",
        padding: "10px 15px",
        border: "0px solid",
        borderRadius: "60px",
        fontWeight: 300,
        fontSize: "13px",
        lineHeight: 1,
        backgroundColor: buttonColor,
        color: theme.palette[color]?.contrastText,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0)", // Custom shadow control
        "&:hover": {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0)", // Custom shadow control
          border: "0px solid white", // Custom border style
          backgroundColor: theme.palette.accent1.main,
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default SRPrimaryButton;
