import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Icon1 from "../icons/Icon";
import Space from "../layout/Space";
import PrimaryButton from "../buttons/PrimaryButton";
import Row from "../layout/Row";
import Divider from "../layout/Divider";
import { useEffect } from "react";
import changeFolderState from "../api/folder/changeFolderState";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken";
import downloadFolder from "../api/download/downloadFolder";
import setFolderIntervention from "../api/folder/setFolderIntervention";
import { DatePicker, TimePicker } from "antd";
import { set } from "date-fns";
import { format } from "date-fns";
import { useState } from "react";
import dayjs from "dayjs";
import addClientExperts from "../api/add/addClientExperts";
import { useSnackbar } from "notistack";
import modifThermal from "../api/folder/modifThermal";
import unlockRapport from "../api/folder/unlockRapport";
import NewMissionModal from "../infiltrea/NewMissionModal";
import moment from "moment";
import sendInfiltreaGetReports from "../api/infiltrea/sendInfiltreaGetReports";
import { Timestamp } from "firebase/firestore";
import { useAppContext } from "../config/AppContext";
import SecondaryButton from "../buttons/SecondaryButton";
import SRSIconButton from "../buttons/SRSIconButton";
import SuperModal from "../modals/SuperModal";
import { useNavigate, useParams } from "react-router-dom";

export default function SupportAM({
  actionModify,
  actionDelete,
  orgData,
  folder,
  forcePageReload,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { appState, setAppState } = useAppContext();
  const navigate = useNavigate(); // Hook to handle navigation

  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const open = Boolean(anchorEl);
  const [supportOpen, setSupportOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleOpenSupport = () => {
    setSupportOpen(true);
    handleClose();
  };

  const handleOpenYoutube = () => {
    const url = "https://www.youtube.com/@MAzefuhd";
    window.open(url, "_blank");
    handleClose();
  };

  const handleGoFacturation = async () => {
    navigate("/facturation");
  };

  return (
    <div>
      <SuperModal
        modal_name="support"
        isOpen={supportOpen}
        onClose={() => setSupportOpen(false)}
      ></SuperModal>
      <SRSIconButton
        icon_name="question"
        icon_size="20px"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Aide
      </SRSIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "fit-content", // Set the menu width
            backgroundColor: "white", // Set background to white
            borderRadius: "12px", // Round the corners
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)", // Add shadow for better styling
            padding: "0px", // Add padding if needed
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          color: "black",
          sx: {
            backgroundColor: "white", // Ensure the list itself is also white
            color: "black", // Ensure the text color inside the menu is black
          },
        }}
      >
        <MenuItem
          onClick={handleGoFacturation}
          sx={{
            backgroundColor: "white", // Explicitly set background for menu items
            "&:hover": {
              backgroundColor: "#f5f5f5", // Set hover background color
            },
          }}
        >
          <div className="flex flex-grow w-full  flex-row text-h3">
            <Icon1 icon_name="facturation" width="18px" height="18px"></Icon1>
            <Space x={10} />
            Facturation
          </div>
        </MenuItem>
        <MenuItem onClick={handleOpenYoutube}>
          <div className="flex flex-grow w-full  flex-row text-h3">
            <Icon1 icon_name="youtube" width="18px" height="18px"></Icon1>
            <Space x={10} />
            YouTube
          </div>
        </MenuItem>
        <MenuItem onClick={handleGoFacturation}>
          <div className="flex flex-grow w-full  flex-row text-h3">
            <Icon1 icon_name="education" width="18px" height="18px"></Icon1>
            <Space x={10} />
            Education
          </div>
        </MenuItem>
        <MenuItem onClick={handleOpenSupport}>
          <div className="flex flex-grow w-full  flex-row text-h3">
            <Icon1 icon_name="message" width="18px" height="18px"></Icon1>
            <Space x={10} />
            Message
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
