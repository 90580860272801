import React from "react";
import ReactECharts from "echarts-for-react";
import { useTheme } from "@mui/material/styles";
import { color } from "echarts";

export default function PieChart1({
  width = "200px",
  height = "300px",
  className,
  serie_label = "Client",
  data = [
    { value: 230, name: "MTB" },
    { value: 148, name: "INEA" },
    { value: 120, name: "PRODCUTIONSS " },
    { value: 120, name: "ED" },
    { value: 120, name: "DF" },
    { value: 120, name: "FD" },
  ],
  color = [
    "rgba(50, 130, 255, 1)",
    "rgba(50, 150, 255, 1)",
    "rgba(50, 170, 255, 1)",
    "rgba(50, 190, 255, 1)",
    "rgba(50, 210, 255, 1)",
    "rgba(50, 230, 255, 1)",
    "rgba(50, 250, 255, 1)",
  ],
}) {
  const theme = useTheme();

  const options = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
      padding: [15, 0, 0, 10],
      itemGap: 5,
      itemWidth: 20,
      itemHeight: 10,
      textStyle: {
        fontSize: 10,
        color: theme.palette.text.primary,
      },
    },
    series: [
      {
        name: serie_label,
        type: "pie",
        radius: ["30%", "70%"], // [innerRadius, outerRadius]
        avoidLabelOverlap: true,
        startAngle: 0,
        endAngle: 360,
        center: ["80%", "50%"], // Centering the pie
        itemStyle: {
          borderRadius: 3, // Corner radius for slices
          borderColor: "#fff", // White border for separation between slices
          borderWidth: 1,
        },
        label: {
          show: false,
          position: "outside",
          fontSize: 10, // Small label font size
          color: theme.palette.text.primary, // Text color from theme
          fontWeight: "medium",
        },
        labelLine: {
          show: true,
          length: 10, // Shorter label lines
          length2: 10,
        },
        data: data,
        color: color,
      },
    ],
  };

  return (
    <ReactECharts
      option={options}
      style={{
        width: width,
        height: height,
      }}
      className={className}
    />
  );
}
