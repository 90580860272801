import React, { useEffect, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import ConfirmationSmsModal from "../modals/ConfirmationSmsModal";
import Column from "../layout/Column";
import Row from "../layout/Row";
import Divider from "../layout/Divider";
import NavBar from "../hexagone/NavBar";
import HeaderBar from "../hexagone/HeaderBar";
import Space from "../layout/Space";
import Horloge from "../hexagone/Horloge";
import DropDown1 from "../dropdowns/DropDown1.js";
import { useTheme } from "@mui/material";
import StatsCard from "../cards/StatsCard";
import LineChart1 from "../charts/LineChart1";
import PieChart1 from "../charts/PieChart1";
import { Skeleton } from "@mui/material";
import getGraph from "../api/getGraph.js";
import { useAppContext } from "../config/AppContext";
import { set } from "date-fns";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken.js";
import { getDocDataByRef, queryCollection } from "../models/DataFunctions.js";
import { se } from "date-fns/locale";

function Home() {
  const theme = useTheme();
  const { currentUser, loading } = useAuth();

  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);

  const [graphData, setGraphData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [operatorData, setOperatorData] = useState(null);
  const [folderData, setFolderData] = useState(null);
  const [late_folder, setLateFolder] = useState(null);
  const [x_values, setXValues] = useState(null);

  // Frequency options for the dropdown menu
  const sinceItems = [
    { label: "Cette semaine", value: "week" },
    { label: "Ce mois", value: "month" },
    { label: "Ces 3 derniers mois", value: "3month" },
    { label: "Cette année", value: "year" },
  ];

  const getFiveBiggestClients = (clients) => {
    let five_biggest_clients = [];
    let clients_copy = [...clients];

    for (let i = 0; i < 9; i++) {
      if (clients_copy.length === 0) break; // In case there are fewer than 5 clients
      let max = Math.max(...clients_copy);
      let index = clients_copy.indexOf(max);
      five_biggest_clients.push(index);
      clients_copy.splice(index, 1); // Remove the max value so it's not picked again
    }

    return five_biggest_clients;
  };

  const [since, setSince] = useState(sinceItems[0]);
  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const handleGetNewGraph = async (since) => {
    setSince(since);
    if (orgData) {
      if (orgData.type !== "master") {
        return;
      }
    }

    const token = await getOrRefreshFirebaseToken();
    const graphData = await getGraph(token, since, prefix);

    const dates = graphData.dates;
    // dates are str in format "2024-09-25T08:00:49.454475+00:00"
    // we only need the date part

    // We want to display 14/02, 15/02, 16/02, etc.
    const get_x_values = (since) => {
      let x_values = [];
      for (let i = 0; i < dates.length; i++) {
        x_values.push(
          dates[i].split("T")[0].split("-").slice(1).reverse().join("/")
        );
      }
      return x_values;
    };

    setXValues(get_x_values(since));

    const clients_anmes = graphData.global_clients_activity_names;
    const clients_values = graphData.global_clients_activity_values;
    let five_biggest_clients = [];
    let five_biggest_clients_names = [];

    const five_biggest_clients_index = getFiveBiggestClients(clients_values);

    for (let i = 0; i < five_biggest_clients_index.length; i++) {
      five_biggest_clients.push(clients_values[five_biggest_clients_index[i]]);
      five_biggest_clients_names.push(
        clients_anmes[five_biggest_clients_index[i]]
      );
    }
    const others_clients =
      clients_values.reduce((a, b) => a + b, 0) -
      five_biggest_clients.reduce((a, b) => a + b, 0);

    let clients_data = [];
    for (let i = 0; i < five_biggest_clients_names.length; i++) {
      clients_data.push({
        name: five_biggest_clients_names[i],
        value: five_biggest_clients[i],
      });
    }
    clients_data.push({ name: "Autres", value: others_clients });
    setClientData(clients_data);

    const operators_anmes = graphData.global_members_activity_names;
    const operators_values = graphData.global_members_activity_values;

    let operators_data = [];
    for (let i = 0; i < operators_anmes.length; i++) {
      operators_data.push({
        name: operators_anmes[i],
        value: operators_values[i],
      });
    }
    setOperatorData(operators_data);

    let folders_data = [];
    folders_data.push({
      name: "Dossiers en cours",
      value: graphData.current_folders[graphData.current_folders.length - 1],
      color: theme.palette.chart.fill_blue,
    });
    folders_data.push({
      name: "Dossiers finalisés",
      value:
        graphData.finalised_folders[graphData.finalised_folders.length - 1],
      color: theme.palette.chart.fill_green,
    });
    setFolderData(folders_data);

    setGraphData(graphData);
  };

  const handleGoLateFolder = () => {
    if (late_folder) {
      navigate("/folder/" + late_folder.id);
    }
  };

  // Function to fetch user and organization data
  const handleFetchData = async () => {
    if (currentUser) {
      try {
        const user = await getUserById(currentUser.uid); // Fetch user data by ID
        const org = await getDocDataByRef(user.org_ref); // Fetch organization data by reference

        const token = await getOrRefreshFirebaseToken(); // Fetch Firebase token

        if (org.type === "master") {
          await handleGetNewGraph("week"); // Fetch graph data based on selected frequency
          const filters = [
            {
              field: "late",
              operator: "==",
              value: true,
            },
            {
              field: "org_ref",
              operator: "==",
              value: org.ref,
            },
          ];
          const late_folders = await queryCollection("folder", filters);
          if (late_folders) {
            if (late_folders.length > 0) {
              setLateFolder(late_folders[0]);
            }
          }

          setSince(sinceItems[0].value);
        }

        // Set the fetched user and organization data in state
        if (user && user !== userData) {
          setUserData(user);
          setOrgData(org);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData(); // Fetch user data when currentUser is available
    }
  }, [currentUser]); // Re-run when currentUser changes

  // Display loading message while checking authentication or fetching user data
  // If the data is still loading, show skeletons
  // loading || !currentUser || !userData
  if (loading || !currentUser || !userData) {
    return (
      <div className="h-full overflow-auto pl-4 flex flex-col gap-2">
        <Space y={20} />

        {/* Skeleton for greeting */}
        <Skeleton variant="rounded" width={200} height={30} />
        <Space y={5} />
        <Skeleton variant="rounded" width={400} height={70} />

        <Space y={30} />

        {/* Skeleton for stats title and dropdown */}
        <Row x={10}>
          <Skeleton variant="rounded" width={150} height={30} />
          <Skeleton variant="rounded" width={200} height={30} />
        </Row>

        <Space y={10} />

        {/* Skeleton for statistics cards */}
        <div className="flex flex-wrap w-full gap-2">
          <Skeleton variant="rounded" width={200} height={100} />
          <Skeleton variant="rounded" width={200} height={100} />
          <Skeleton variant="rounded" width={200} height={100} />
          <Skeleton variant="rounded" width={200} height={100} />
          <Skeleton variant="rounded" width={200} height={100} />
        </div>

        <Space y={5} />

        {/* Skeleton for charts */}
        <div className="flex flex-wrap w-full gap-2">
          <Skeleton variant="rounded" width={1035} height={180} />
          <Skeleton variant="rounded" width={340} height={180} />
          <Skeleton variant="rounded" width={340} height={180} />
          <Skeleton variant="rounded" width={340} height={180} />
        </div>

        <Space y={50} />
      </div>
    );
  }

  return (
    <Column align="left" className="h-full overflow-auto pl-4">
      <Space y={0} />
      <div className="text-hx font-semibold">Bonjour {orgData?.name}</div>
      <Space y={5} />
      <Horloge />
      <Space y={30} />
      <Row x={10}>
        <div className="text-h1 font-semibold">Statistiques</div>
        <DropDown1
          label="Durée"
          items={sinceItems}
          className="w-c200"
          value={since}
          onChange={(e) => handleGetNewGraph(e.target.value)} // Update selected frequency
        />
      </Row>
      <Space y={10} />

      {orgData.type === "master" && (
        <>
          <div className="flex flex-wrap w-full gap-2">
            <StatsCard
              title="Nouveaux dossiers"
              icon="tag"
              value={graphData?.new_folders[graphData?.new_folders.length - 1]}
              stat_className="text-hx3 font-semibold text-blue-300"
            />
            <StatsCard
              title="Dossiers en cours"
              icon="folder"
              value={
                graphData?.current_folders[
                  graphData?.current_folders.length - 1
                ]
              }
              stat_className="text-hx3 font-semibold text-blue-400"
            />
            <StatsCard
              title="Dossiers finalisés"
              icon="done"
              value={
                graphData?.finalised_folders[
                  graphData?.finalised_folders.length - 1
                ]
              }
              stat_className="text-hx3 font-semibold text-accent1"
            />
            <div className="flex cursor-pointer" onClick={handleGoLateFolder}>
              <StatsCard
                title="Dossiers en retard"
                icon="cloche"
                value={
                  graphData?.late_folders[graphData?.late_folders.length - 1]
                }
                stat_className="text-hx3 font-semibold text-accent2"
              />
            </div>
            <StatsCard
              title="Temps moyen de finalisation"
              icon="horloge"
              value="0"
              stat_className="text-hx3 font-semibold text-shadow_25"
            />
          </div>
          <Space y={5} />
          {/* Display charts for dossier evolution, revenues, and activity */}
          <div className="flex flex-wrap w-full gap-2">
            <div className="bg-white p-3 rounded-xl border-1 border-shadow_10 w-c600">
              <div className="text-h3 flex-grow">
                Evolution du nombre de dossiers
              </div>
              <LineChart1
                width={580}
                height={150}
                className="p-0 m-0"
                current_values={graphData.current_folders}
                finalised_values={graphData.finalised_folders}
                x_values={x_values}
              />
            </div>

            <div className="bg-white p-3 rounded-xl border-1 border-shadow_10 w-c600">
              <div className="text-h3 flex-grow">Activité par client</div>
              <PieChart1
                width={580}
                height={150}
                className="p-0 m-0"
                serie_label="Client"
                data={clientData}
              />
            </div>

            <div className="bg-white p-3 rounded-xl border-1 border-shadow_10 w-c600">
              <div className="text-h3 flex-grow">Activité par opérateur</div>
              <PieChart1
                width={580}
                height={150}
                className="p-0 m-0"
                serie_label="Opérateur"
                data={operatorData}
              />
            </div>

            <div className="bg-white p-3 rounded-xl border-1 border-shadow_10 w-c600">
              <div className="text-h3 flex-grow">Répartition des dossiers</div>
              <PieChart1
                width={580}
                height={150}
                className="p-0 m-0"
                serie_label="Dossier"
                data={folderData}
                color={["rgba(50, 130, 255, 1)", "rgba(50, 250, 255, 1)"]}
              />
            </div>
          </div>
        </>
      )}
    </Column>
  );
}

export default Home;
