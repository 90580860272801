import React from "react";
import SRSecondaryButton from "./SRSecondaryButton";
import Icon1 from "../icons/Icon";
import Row from "../layout/Row";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

function IconButton({
  icon_name = "question",
  icon_size = "20px",
  fill = null,
  className,
  type,
  sx,
  children,
  onClick,
  height,
  width,
  ...props
}) {
  const [hover, setHover] = React.useState(false);

  const theme = useTheme();
  return (
    <Button
      variant="contained"
      type={type}
      className={className}
      sx={{
        textTransform: "none",
        padding: "3px 6px 3px 6px",
        width: "fit-content",
        minWidth: "fit-content", // Ensures MUI doesn't override with a larger minWidth
        border: "0px solid",
        borderRadius: "60px",
        fontWeight: 300,
        fontSize: "13px",
        lineHeight: 1,
        backgroundColor: "rgba(0, 0, 0, 0)",
        color: theme.palette.shadow._50,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0)", // Custom shadow control
        "&:hover": {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0)", // Custom shadow control
          border: "0px solid white", // Custom border style
          backgroundColor: theme.palette.shadow._5,
        },
        ...sx,
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon1
        icon_name={icon_name}
        height={height ? height : icon_size}
        width={width ? width : icon_size}
        fill={hover ? theme.palette.shadow._50 : theme.palette.shadow._50}
      />
    </Button>
  );
}

export default IconButton;
