import React, { useState } from "react";
import TextField from "@mui/material/TextField";

function TextField2White({
  label,
  onClickChangeLabel, // New prop for changing the label
  sx,
  value,
  onChange,
  className,
  disabled = false,
  readOnly = false,
  multiline = false,
  rows = 1,
}) {
  const [currentLabel, setCurrentLabel] = useState(label); // State to manage the label

  // Handle focus to change the label when the field is selected
  const handleFocus = () => {
    if (onClickChangeLabel) {
      setCurrentLabel(onClickChangeLabel);
    }
  };

  // Handle blur to revert the label back when focus is lost
  const handleBlur = () => {
    setCurrentLabel(label); // Reset to original label on blur
  };

  return (
    <div className="bg-white rounded-xl w-full">
      <TextField
        className={className}
        value={value}
        onChange={onChange}
        label={currentLabel} // Use the current label from state
        variant="outlined"
        background="white"
        color="primary"
        disabled={disabled}
        readOnly={readOnly}
        fullWidth
        onFocus={handleFocus} // Trigger label change on focus
        onBlur={handleBlur} // Reset label on blur
        multiline={multiline}
        rows={rows}
        sx={{
          "& .MuiInputLabel-root": {
            top: "-15%", // Position the label vertically centered
            left: "0px",
            fontSize: "13px",
            color: readOnly ? "gray" : "inherit", // Label color change when readonly
          },
          "& .MuiInputLabel-shrink": {
            top: "0%", // Keep the label on top when focused or typing
            fontSize: "13px",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px", // Rounded corners

            "& fieldset": {
              borderColor: readOnly ? "gray" : "rgba(0, 0, 0, 0.23)", // Change border color when readonly
            },
            "&:hover fieldset": {
              borderColor: readOnly ? "gray" : "black", // Border on hover in readonly state
            },
            "&.Mui-focused fieldset": {
              borderColor: readOnly ? "gray" : "blue", // Blue border on focus
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: "0px", // Adjust input padding
            color: readOnly ? "gray" : "inherit", // Change text color when readonly
            minHeight: "auto", // Allow the height to grow based on content
            overflowY: "auto", // Enable vertical scrolling if text exceeds maxRows
          },
          ...sx, // Add custom styles if needed
        }}
        InputProps={{
          style: {
            fontSize: "13px",
            padding: "10px", // More padding inside the input area for comfort
          },
        }}
      />
    </div>
  );
}

export default TextField2White;
