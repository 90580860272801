import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBarButton from "../buttons/NavBarButton";
import Column from "../layout/Column";
import Space from "../layout/Space";
import Image1 from "../images/Image1";
import { useAuth } from "../auth/FirebaseAuth";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import PrimaryButton from "../buttons/PrimaryButton";
import Icon1 from "../icons/Icon";
import { Switch } from "@mui/material";
import { useAppContext } from "../config/AppContext";
import TextField2 from "../textfields/TextField2";
import TextFieldMicro from "../textfields/TextFieldMicro";

function NavBar({ actualpage }) {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate(); // Hook to handle navigation
  const { appState, setAppState } = useAppContext();

  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [url, setUrl] = useState("http://127.0.0.1:8000/");

  const handleFetchData = async () => {
    if (currentUser) {
      const user = await getUserById(currentUser.uid); // Fetch user by ID using function
      const org = await getOrgByRef(user.org_ref); // Fetch org by ref using function
      if (user && user !== userData) {
        setUserData(user);
        setOrgData(org);
      }
    }
  };

  const handleChangeUrl = (e) => {
    setUrl(e.target.value);
    setAppState((prevState) => ({
      ...prevState,
      dev_url: e.target.value,
    }));
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData(); // Fetch user data when currentUser is available
      setUrl(appState.dev_url);
    }
  }, [currentUser]); // Dependency array ensures this runs when currentUser changes

  if (loading || !currentUser || !userData) {
    return <div>Loading...</div>; // Show loading state while checking auth or if currentUser is null
  }

  return (
    <Column
      spacing={0}
      align="left"
      className="shrink-0 grow-0 max-w-44 bg-white pl-5"
    >
      <Space y={20} />
      <Image1 height="40px" width="100px" src={orgData.logo} />
      <Space y={5}></Space>
      <div className="text-h2 font-medium">{orgData.name}</div>
      <Space y={20}></Space>
      {orgData.type === "master" && (
        <div className="text-h2 font-semibold pb-5 items-center">
          <PrimaryButton onClick={() => navigate("/newfolder")}>
            <div className="pl-0 flex flex-row gap-1 items-center">
              Créer dossier
            </div>
          </PrimaryButton>
        </div>
      )}
      {orgData.type === "client" && (
        <div className="text-h2 font-semibold pb-5 items-center">
          <PrimaryButton onClick={() => navigate("/newdemand")}>
            <div className="pl-0 flex flex-row gap-1 items-center">
              Créer dossier
            </div>
          </PrimaryButton>
        </div>
      )}

      {/* Navigation buttons */}
      <NavBarButton
        icon="home"
        actualpage={actualpage}
        onClick={() => navigate("/home")}
      >
        Accueil
      </NavBarButton>
      {orgData.type === "master" && (
        <>
          <NavBarButton
            icon="client"
            actualpage={actualpage}
            onClick={() => navigate("/clients")}
          >
            Clients
          </NavBarButton>
          <NavBarButton
            icon="expert"
            actualpage={actualpage}
            onClick={() => navigate("/experts")}
          >
            Intervenants
          </NavBarButton>
        </>
      )}

      <NavBarButton
        icon="folder"
        actualpage={actualpage}
        onClick={() => navigate("/folders")}
      >
        Dossiers
      </NavBarButton>

      {orgData.type !== "expert" && (
        <>
          {" "}
          <NavBarButton
            icon="devis"
            actualpage={actualpage}
            onClick={() => navigate("/invoices")}
          >
            Devis
          </NavBarButton>
          <Space y={30}></Space>
          <NavBarButton
            icon="planning"
            actualpage={actualpage}
            onClick={() => navigate("/planning")}
          >
            Planning
          </NavBarButton>
        </>
      )}

      <NavBarButton icon="settings" onClick={() => navigate("/parameters")}>
        Paramètres
      </NavBarButton>
      <Space y={30}></Space>

      {/* Admin-specific buttons */}
      {userData.admin && (
        <div>
          <NavBarButton icon="logs" onClick={() => navigate("/logs")}>
            Logs
          </NavBarButton>
          <NavBarButton icon="client" onClick={() => navigate("/users")}>
            Utilisateurs
          </NavBarButton>
          <NavBarButton icon="boutique" onClick={() => navigate("/entree")}>
            Stripe
          </NavBarButton>
          <Space y={30}></Space>

          <div className="flex flex-row items-center gap-2 p-0 m-0">
            <div className="text-h3 text-shadow_50 font-medium">Local</div>
            <Switch
              checked={appState.dev}
              onChange={(e) =>
                setAppState((prevState) => ({
                  ...prevState,
                  dev: e.target.checked,
                }))
              }
            />
          </div>
          {appState.dev && (
            <div className="pr-5">
              <TextFieldMicro
                label="url"
                value={url}
                onChange={handleChangeUrl}
                className="text-h3 text-shadow_50"
                sx={{
                  fontSize: "10px",
                }}
              ></TextFieldMicro>
            </div>
          )}
        </div>
      )}
    </Column>
  );
}

export default NavBar;
