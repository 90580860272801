import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import addLogDocument from "../addLogDocument.js"; // Custom logging function

const extractXmlBoth = async (
  url,
  token,
  orgData,
  phone,
  folderId,
  email,
  address,
  name,
  oid,
  prefix
) => {
  let urlInsulator = "";

  try {
    let result;
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
      return null;
    }
    // Download the file from the URL
    const response = await fetch(url);
    if (response.ok) {
      const fileBytes = await response.arrayBuffer(); // Using arrayBuffer() for file binary data
      result = {
        files: [
          {
            name: "downloaded_file.xml",
            bytes: fileBytes,
          },
        ],
      };
    } else {
      console.log("Error downloading file:", response.status);
      return { list: [], path: "", urlInsulator: "" };
    }

    const file = result.files[0];
    const fileBlob = new Blob([file.bytes], { type: "application/xml" }); // Create Blob for the file data

    // Create multipart request for insulator
    const formDataInsulator = new FormData();
    formDataInsulator.append("file", fileBlob, file.name);
    formDataInsulator.append("type", "insulator");

    const insulatorUrl = prefix + `folders/${folderId}/xml/extract`;

    const responseInsulator = await fetch(insulatorUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formDataInsulator,
    });

    if (responseInsulator.ok) {
      const jsonResponse = await responseInsulator.json();
      const path = jsonResponse.path;

      const pdfUrl = prefix + `folders/${folderId}/xml/generate_pdf`;

      // Prepare request body for PDF generation
      const body = {
        footer: `${name} - ${address}\nTéléphone : ${phone} - Mail : ${email}`,
        type: "insulator",
        oid: oid,
        batch: null,
        path: path,
        logo: orgData.logo || "https://i.ibb.co/8Nc2tS4/Placeholder.png",
      };

      // Make POST request for PDF generation
      const pdfResponse = await fetch(pdfUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      const currentUser = getAuth().currentUser;
      if (!currentUser) return;

      const userRef = doc(getFirestore(), "user", currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const orgRef2 = userSnapshot.get("org_ref");
      const responseText = await pdfResponse.text();

      // Log the document
      await addLogDocument(
        url,
        userRef,
        orgRef2,
        pdfResponse.status.toString(),
        "",
        "",
        JSON.stringify(body),
        responseText,
        "POST"
      );

      if (pdfResponse.ok) {
        const clean = (await responseText).trim().replace(/"/g, "");
        console.log("response.body CLEANED is " + clean);
        urlInsulator = clean;
      } else {
        throw new Error("Failed to generate PDF for insulator");
      }
    } else {
      throw new Error("Failed to process insulator XML");
    }

    // Now handle the carbon XML similarly
    const formDataCarbon = new FormData();
    formDataCarbon.append("file", fileBlob, file.name);
    formDataCarbon.append("type", "carbon");

    const responseCarbon = await fetch(insulatorUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formDataCarbon,
    });

    const userRef2 = doc(getFirestore(), "user", currentUser.uid);
    const userSnapshot2 = await getDoc(userRef2);
    const orgRef3 = userSnapshot2.get("org_ref");
    const responseText = await responseCarbon.text();

    // Log the document
    await addLogDocument(
      url,
      userRef2,
      orgRef3,
      responseCarbon.status.toString(),
      "",
      "",
      "",
      responseText,
      "POST"
    );

    if (responseCarbon.ok) {
      const jsonResponse = JSON.parse(responseText);
      const dictString = jsonResponse.dict;
      const path = jsonResponse.path;

      const myList = dictString.split("#").map((lotString) => {
        const myFields = lotString.split("@");
        return {
          X: myFields[0],
          num: myFields[1],
          nom: myFields[2],
          id: myFields[3],
          qt: myFields[4],
          un: myFields[5],
        };
      });

      return { list: myList, path: path, urlInsulator: urlInsulator };
    } else {
      throw new Error("Failed to process carbon XML");
    }
  } catch (error) {
    console.log("Exception occurred:", error);
    return { list: [], path: "", urlInsulator: "" };
  }
};

export default extractXmlBoth;
