import JSZip from "jszip"; // Use JSZip for zipping files
import { saveAs } from "file-saver"; // FileSaver.js for saving files
import { getDocDataByRef } from "../../models/DataFunctions";

const downloadFolder = async (folderRef, nom) => {
  console.log("downloadFolder");
  const names = [];
  try {
    console.log("1");

    // Get folder object from reference
    const folderData = await getDocDataByRef(folderRef);

    console.log("1.2");

    const documents = folderData.documents;
    const pdfList = [];

    // Extract pdfUrl and filename from each document
    console.log("1.3");
    for (const document of documents) {
      const documentData = await getDocDataByRef(document);
      const state = documentData.state;

      if (state !== "locked") {
        const pdfUrls = [];
        const filenames = [];
        const files = documentData.files || [];

        files.forEach((file) => {
          pdfUrls.push(file.url);
          filenames.push(file.name);
        });

        console.log("1.4");

        if (pdfUrls.length > 0 && filenames.length > 0) {
          for (let i = 0; i < pdfUrls.length; i++) {
            if (names.includes(filenames[i])) {
              filenames[i] = addZeroToFileName(filenames[i]);
            }
            pdfList.push({ storage_path: pdfUrls[i], file_name: filenames[i] });
            names.push(filenames[i]);
          }
        }
      }
    }

    console.log("1");

    // Add photos to the list
    try {
      const photos = folderData.photos || [];
      for (const photoRef of photos) {
        const photoSnapshot = await photoRef.get();
        const photoData = photoSnapshot.data();

        if (photoData.file) {
          const file = photoData.file;
          if (names.includes(file.name)) {
            file.name = addZeroToFileName(file.name);
          }
          pdfList.push({
            storage_path: file.url.toString(),
            file_name: file.name.toString(),
          });
          names.push(photoData.file_name);
        }
      }
    } catch (e) {
      console.error("ERROR PHOTOS:", e);
    }

    console.log("2");

    // Add thermal storage paths to the list
    try {
      const thermalFiles = folderData.thermal?.files || [];
      const modifThermalFiles = folderData.modif_thermal?.files || [];

      const thermalStoragePaths = [
        ...thermalFiles.map((e) => e.url),
        ...modifThermalFiles.map((e) => e.url),
      ];
      const thermalFileNames = [
        ...thermalFiles.map((e) => e.name),
        ...modifThermalFiles.map((e) => e.name),
      ];

      for (let i = 0; i < thermalStoragePaths.length; i++) {
        if (names.includes(thermalFileNames[i])) {
          thermalFileNames[i] = addZeroToFileName(thermalFileNames[i]);
        }
        pdfList.push({
          storage_path: thermalStoragePaths[i],
          file_name: thermalFileNames[i] || "sanstitre.pdf",
        });
        names.push(thermalFileNames[i]);
      }
    } catch (e) {
      console.error("ERROR THERMAL:", e);
    }

    console.log("3");

    // Add XML storage paths to the list
    try {
      const xmlStoragePath = folderData.xml?.file?.url || null;
      const xmlFileName = folderData.xml?.file?.name || null;

      if (xmlStoragePath && xmlFileName) {
        if (names.includes(xmlFileName)) {
          xmlFileName = addZeroToFileName(xmlFileName);
        }
        pdfList.push({ storage_path: xmlStoragePath, file_name: xmlFileName });
        names.push(xmlFileName);
      }
    } catch (e) {
      console.error("ERROR XML:", e);
    }

    console.log("4");

    // Create a zip file containing all PDFs
    const zip = new JSZip();
    for (const pdfInfo of pdfList) {
      if (pdfInfo.storage_path) {
        const response = await fetch(pdfInfo.storage_path); // Fetch the file from the URL
        const pdfData = await response.arrayBuffer(); // Read the file as binary data
        zip.file(pdfInfo.file_name, pdfData); // Add the file to the zip
      }
    }

    // Generate and save the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `Dossier_${nom}.zip`); // Save the zip file with the specified name
  } catch (e) {
    console.error("ERROR IN DOWNLOAD", e);
  }
};

// Helper function to avoid duplicate filenames
const addZeroToFileName = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex !== -1) {
    const namePart = fileName.substring(0, lastDotIndex);
    const extensionPart = fileName.substring(lastDotIndex);
    return `${namePart}0${extensionPart}`;
  } else {
    return `${fileName}0`;
  }
};

export default downloadFolder;
