import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextField1 from "../textfields/TextField1";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Password } from "@mui/icons-material";
import TextFieldBorderLess from "../textfields/TextFieldBorderLess";

import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordField from "../textfields/PasswordField";
import EmailField from "../textfields/EmailField";

import MLTF1 from "../textfields/MLTF1";
import TextField2 from "../textfields/TextField2";

function Bordel({ text, action }) {
  return (
    <div>
      <Button variant="outlined">Outlined</Button>
      <TextField id="filled-basic" label="Filled" variant="filled" />
      <FormGroup>
        <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
        <FormControlLabel required control={<Checkbox />} label="Required" />
        <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
      </FormGroup>
      <FormGroup>
        <FormControlLabel control={<Switch defaultChecked />} label="Label" />
        <FormControlLabel required control={<Switch />} label="Required" />
        <FormControlLabel disabled control={<Switch />} label="Disabled" />
      </FormGroup>
      <Box
        component="form"
        sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
        noValidate
        autoComplete="off"
      >
        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
        <TextField id="filled-basic" label="Filled" variant="filled" />
        <TextField id="standard-basic" label="Standard" variant="standard" />
      </Box>
      <h1>{text}</h1>
      <button
        className="animate-pulse px-2 py-1 text-xs bg-primary rounded-md text-f_white m-3 shadow-md"
        onClick={action}
      >
        Click me
      </button>

      <span class="relative flex h-3 w-3">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
      </span>
      <div className="justify-center space-x-2 p-3">
        <PrimaryButton>Accepter</PrimaryButton>
        <SecondaryButton>Accepter</SecondaryButton>
        <div className="text-h1 font-semibold p-3 gap-2">TextFields</div>
        <TextField1 label="Email"></TextField1>
        <TextField2 label="Email"></TextField2>
        <EmailField label="Email"></EmailField>
        <PasswordField label="Email"></PasswordField>
        <MLTF1 label="Email"></MLTF1>
        <TextFieldBorderLess label="Email"></TextFieldBorderLess>
      </div>
    </div>
  );
}

export default Bordel;
