import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
} from "@mui/material";
import styled from "styled-components";
import Box2 from "../boxs/Box2";
import TextField1 from "../textfields/TextField1";
import Space from "../layout/Space";
import SecondaryButton from "../buttons/SecondaryButton";
import Row from "../layout/Row";
import PrimaryButton from "../buttons/PrimaryButton";
import SmallRoundedButton from "../buttons/SRSecondaryButton";

const ConfirmationSmsModal = ({
  open,
  onClose,
  onValidate,
  value,
  onChange,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "rgba(255,255,255,0)",
          borderRadius: "20px", // Rounded corners
          boxShadow: "0 8px 16px rgba(0,0,0,0)", // Nice shadow effect
        },
      }}
    >
      <Box2 sx={{ width: "250px", fontSize: "14px" }}>
        Entrez le code reçu par SMS
        <Space y={10} />
        <TextField1
          label="Code"
          sx={{ flexGrow: 1 }}
          value={value}
          onChange={onChange}
        ></TextField1>
        <Row sx={{ flexGrow: 1 }}>
          <SmallRoundedButton onClick={onClose} color="secondary">
            Annuler
          </SmallRoundedButton>
          <Space y={20} />
          <SmallRoundedButton onClick={onValidate} sx={{ flexGrow: 1 }}>
            Valider
          </SmallRoundedButton>
        </Row>
      </Box2>
    </Dialog>
  );
};

export default ConfirmationSmsModal;
