import React, { useState, useImperativeHandle, forwardRef } from "react";

const CustomAccordion = forwardRef(({ title, children }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Expose the toggleAccordion method to parent components
  useImperativeHandle(ref, () => ({
    toggleAccordion,
    openAccordion: () => setIsOpen(true), // Optional: Open the accordion externally
    closeAccordion: () => setIsOpen(false), // Optional: Close the accordion externally
  }));

  return (
    <div className="h-fit w-full">
      <div
        style={{
          width: "100%",
          border: "1px solid #e0e0e0",
          borderRadius: "5px",
          overflow: "hidden",
          margin: "0px",
          padding: "0px",
          boxSizing: "border-box",
          boxShadow: isHovered ? "0px 1px 5px rgba(0, 0, 0, 0.1)" : "none",
        }}
      >
        {/* Accordion Header */}
        <div
          style={{
            backgroundColor: isHovered ? "rgba(0,0,0,0.02)" : "transparent",
            padding: "5px",
            cursor: "pointer",
            borderBottom: "1px solid #e0e0e0",
            fontWeight: 500,
          }}
          onClick={toggleAccordion}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div>{title}</div>
        </div>

        {/* Accordion Content */}
        <div
          style={{
            display: isOpen ? "block" : "none",
            padding: "5px",
            backgroundColor: "transparent",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
});

export default CustomAccordion;
