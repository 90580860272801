import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import { Divider, Switch } from "@mui/material";
import Space from "../layout/Space";
import MenuButton from "../buttons/MenuButton";
import RoundedButton from "../buttons/RoundedButton";
import RoundedButton2 from "../buttons/RoundedButton2";
import Icon1 from "../icons/Icon";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { getUserById } from "../models/UserFunctions";
import { getOrgByRef } from "../models/OrgFunctions";
import { getOrRefreshFirebaseToken } from "../config/getFirebaseToken";
import stripeSubscriptionManager from "../api/stripe/stripeSubscriptionManager";
import { useAppContext } from "../config/AppContext";
import DropDown1 from "../dropdowns/DropDown1";
import stripeCheckoutSession from "../api/stripe/stripeCheckoutSession";
import stripeMobileSubManager from "../api/stripe/stripeMobileSubManager";
import MLTF1 from "../textfields/MLTF1";
import PrimaryButton from "../buttons/PrimaryButton";
import TextFieldChat from "../textfields/TextFieldChat";
import TextFieldEmail from "../textfields/TextFieldEmail";
import sendRequest from "../api/send/sendRequest";
import TextField2 from "../textfields/TextField2";
import { getDocDataById, updateDocDataField } from "../models/DataFunctions";

const Tarifs = ({ onClose, extra }) => {
  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const { currentUser, loading } = useAuth();

  const [tarif, setTarif] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const { appState, setAppState } = useAppContext();
  const prefix = appState.dev ? appState.dev_url : appState.prod_url;

  const handleSetTarif = async () => {
    console.log("tarif:", tarif);
    console.log("extra:", extra);
    console.log("extra.params.id:", extra.params.id);
    console.log("extra.id:", extra.id);
    console.log("params:", extra.params);
    console.log("orgData:", orgData);
    let article = await getDocDataById("article", extra.params.id);
    let client = await getDocDataById("org", extra.id);
    let negoces = orgData.negoces;
    for (let negoce of negoces) {
      console.log("negoces:", negoce);
      console.log("negoce.article_ref.id:", negoce.article_ref.id);

      console.log("negoce.client_o?.id:", negoce.client_o?.id);
    }

    let current_negoces = negoces.filter(
      (negoce) =>
        negoce.article_ref.id === extra.params.id &&
        negoce.client_o?.id === extra.id
    );
    if (current_negoces.length === 0) {
      console.log("no tarif found, creating new one");
      negoces.push({
        article_ref: article.ref,
        client_o: client.ref,
        master_o: orgData.ref,
        price: tarif,
        price_ttc: tarif * 1.2,
      });
    } else {
      console.log("tarif found, updating it");
      current_negoces[0].price = tarif;
      current_negoces[0].price_ttc = tarif * 1.2;
      negoces = negoces.filter(
        (negoce) =>
          negoce.article_ref.id !== extra.params.id ||
          negoce.client_o?.id !== extra.id
      );
      negoces.push(current_negoces[0]);
    }

    await updateDocDataField(orgData.ref, "negoces", negoces);
    handleClickVariant("Tarif enregistré", "success");
    onClose();
  };

  const forceToDouble = (value) => {
    // Remove any characters that aren't digits or commas/periods
    let cleanValue = value.replace(/[^0-9.,]/g, "");

    // Replace commas with periods for correct parsing
    cleanValue = cleanValue.replace(",", ".");

    // Parse the string as a floating-point number (double)
    let parsedValue = parseFloat(cleanValue);

    // Return the double value or 0 if it's not a valid number
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const formatAsCurrency = (value) => {
    // Ensure the value is a number with two decimal places
    const fixedValue = value.toFixed(2);

    // Replace period with comma
    const formattedValue = fixedValue.replace(".", ",");

    // Append the euro symbol
    return `${formattedValue}€`;
  };

  const handleNewValueEuros = (value) => {
    // Convert the value to a double

    const doubleValue = forceToDouble(value);

    let centimes = tarif % 1;
    let new_tarif = doubleValue + centimes;

    console.log("doubleValue:", new_tarif);
    // Format the double as currency
    setTarif(new_tarif); // Update state with the formatted value
    // Update parent component with the formatted value
  };

  const handleNewValueCentimes = (value) => {
    // Convert the value to a double

    const doubleValue = forceToDouble(value);
    console.log("doubleValue:", doubleValue);
    // Format the double as currency
    let removed_centimes = tarif - (tarif % 1);
    let centimes = doubleValue / 100;
    let new_tarif = removed_centimes + centimes;
    setTarif(new_tarif); // Update state with the formatted value
    // Update parent component with the formatted value
  };

  const handleFetchData = async () => {
    if (currentUser) {
      try {
        const user = await getUserById(currentUser.uid);
        const org = await getOrgByRef(user.org_ref);
        console.log("org:", org);

        setTarif(extra.params.ht_price); // Set the default value to the base price

        setUserData(user);
        setOrgData(org);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchData();
    }
  }, [currentUser]);

  if (loading || !currentUser || !userData) {
    return <> Loading ..</>;
  }

  return (
    <div className="bg-white flex flex-col p-4 w-c400 rounded-3xl ">
      <div className="flex flex-col gap-3 w-full">
        <div className="flex flex-row justify-between">
          {" "}
          <div className="text-h3">Tarif négocié HT</div>
          <div
            className="text-h3 text-shadow_50 cursor-pointer"
            onClick={onClose}
          >
            Fermer
          </div>
        </div>
        <div className="text-h3 text-shadow_50">
          Entrez un nombre décimal sans unité ex: 455.89
        </div>
        <div className="flex flex-row gap-2 items-center">
          <TextField2
            label="Euros"
            value={tarif - (tarif % 1)}
            onChange={(e) => handleNewValueEuros(e.target.value)}
          ></TextField2>
          ,{" "}
          <TextField2
            label="Centimes"
            value={((tarif % 1) * 100).toFixed(0)}
            onChange={(e) => handleNewValueCentimes(e.target.value)}
          ></TextField2>
          <div className="min-w-c40">
            {" "}
            <Icon1
              icon_name="right"
              fill="grey"
              height="20px"
              width="20px"
            ></Icon1>
          </div>
          <div className="bg-neutral-200 rounded-md p-2 text-shadow_50 text-h3 w-full">
            {formatAsCurrency(tarif)}
          </div>
        </div>

        <div className="w-full justify-end flex">
          <PrimaryButton className="w-fit" onClick={handleSetTarif}>
            Envoyer
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default Tarifs;
