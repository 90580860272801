import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextField1 from "../textfields/TextField1";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import HexagoneTitle from "../hexagone/HexagoneTitle";
import Icon from "../icons/Icon";
import Box1 from "../boxs/Box1";
import Row from "../layout/Row";
import MenuButton from "../buttons/MenuButton";
import StackBottomRight from "../layout/StackBottomRight";

import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordField from "../textfields/PasswordField";
import EmailField from "../textfields/EmailField";

import GreyButton from "../buttons/GreyButton";
import RoundedButton from "../buttons/RoundedButton";
import Space from "../layout/Space";
import HexagoneHeader from "../hexagone/HexagoneHeader";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Column from "../layout/Column";
import { loginUser } from "../auth/FirebaseAuth";
import { useSnackbar } from "notistack";
import ConfirmationSmsModal from "../modals/ConfirmationSmsModal";
import { sendSmsVerificationCall } from "../config/ApiCalls";
import { VerifySmsCodeCall } from "../config/ApiCalls";
import { gatherDeviceInfo } from "../config/GatheringInfos";
import CheckBoxGrey from "../textfields/CheckBoxGrey";

function Login({ text, action }) {
  const navigate = useNavigate(); // Hook to handle navigation

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true); // Open modal
  const handleClose = () => setOpen(false); // Close modal

  const [loading, setLoading] = React.useState(false);

  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleModal = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (!email || !password) {
        setLoading(false);
        handleClickVariant("Entrez votre email et votre mot de passe", "error"); // Error notification
        throw new Error("Email and password are required");
      }
      if (email === "william.bresso@gmail.com" && password === "123456") {
        handleLogin(event);
        const infos = gatherDeviceInfo();
        //  sendSmsVerificationCall("SzXNMl9eEkQRTgZjH7TnXkalXng2", infos);
        //   handleOpen();
      } else {
        handleLogin(event);
      }
    } catch (error) {
      handleClickVariant("Identifiants erronés", "error"); // Error notification
      console.error("Error logging in:", error.message);
      setError(error.message); // Handle and display the error
    } finally {
      // Ensure that loading is stopped no matter what happens
      setLoading(false);
    }
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    try {
      let success = await VerifySmsCodeCall(
        "user",
        "SzXNMl9eEkQRTgZjH7TnXkalXng2",
        code
      );
      if (success) {
        handleLogin(event);
      } else {
        handleClickVariant("Code SMS incorrect", "error"); // Error notification
      }
    } catch (error) {
      handleClickVariant("Code SMS incorrect", "error"); // Error notification
      console.error("Error logging in:", error.message);
      setError(error.message); // Handle and display the error
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const user = await loginUser(email, password); // Call Firebase login function
      navigate("/home"); // Navigate to a protected route after successful login
      handleClickVariant("Connexion réussie", "success"); // Success notification
    } catch (error) {
      handleClickVariant("Identifiants erronés", "error"); // Error notification

      console.error("Error logging in:", error.message);
      setError(error.message); // Handle and display the error
    }
  };

  const goPage = (page) => {
    navigate(page);
  };

  return (
    <div className="h-screen">
      <div className="h-full bg-slate-50 items-center flex justify-center flex-col">
        <Box1>
          <HexagoneHeader />

          <Space y={10} />

          <form onSubmit={handleModal}>
            <div className="flex flex-col gap-1 items-center">
              <EmailField
                label={"Email"}
                sx={{ width: "300px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Space y={5} />

              <PasswordField
                sx={{ width: "300px" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Space y={20} />

              {/* Submit the form by pressing this button or pressing "Enter" */}
              <RoundedButton
                type="submit"
                color="black"
                loading={loading}
                className="w-fit "
              >
                Se Connecter
              </RoundedButton>
            </div>
          </form>
          <Space y={10} />

          <Row x={5}>
            <GreyButton>Mot de passe oublié</GreyButton>
            <GreyButton onClick={() => goPage("/entree")}>
              Pas de compte
            </GreyButton>
            <GreyButton>Support</GreyButton>
          </Row>
        </Box1>
        <StackBottomRight>
          <div className="bg-black px-3 py-1 rounded-full text-f_white text-sm">
            Beta
          </div>
        </StackBottomRight>
        <ConfirmationSmsModal
          open={open}
          onClose={handleClose}
          onValidate={handleVerifyCode}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        ></ConfirmationSmsModal>
      </div>
    </div>
  );
}

export default Login;
