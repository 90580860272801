import React, { useRef } from "react";

function TextFieldChat({
  placeholder = "Message",
  value,
  onChange,
  className = "",
}) {
  const textAreaRef = useRef(null);

  const handleInput = (e) => {
    // Reset the height to auto to calculate the correct scrollHeight
    textAreaRef.current.style.height = "auto";
    // Set the height based on scrollHeight, but limit it to 200px
    if (textAreaRef.current.scrollHeight <= 200) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      textAreaRef.current.style.overflowY = "hidden"; // Hide vertical scroll
    } else {
      textAreaRef.current.style.height = "200px"; // Set max height
      textAreaRef.current.style.overflowY = "scroll"; // Add vertical scroll when max height is reached
    }
    // Trigger the passed onChange event
    onChange(e);
  };

  return (
    <textarea
      ref={textAreaRef}
      value={value}
      onChange={handleInput}
      placeholder={placeholder}
      className={`h-auto max-h-[200px] border-0 w-full p-2 text-sm bg-white text-black rounded-md 
                  focus:outline-none focus:ring-2 focus:ring-blue-500 
                  placeholder-gray-400 transition duration-300 ease-in-out 
                  resize-none ${className}`}
      rows={1} // Initial row
    />
  );
}

export default TextFieldChat;
