import React from "react";
import { Box } from "@mui/material";

export const gatherDeviceInfo = () => {
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;

  return {
    // Browser Information
    userAgent: navigator.userAgent, // Browser and OS info
    appName: navigator.appName, // Browser name
    appVersion: navigator.appVersion, // Browser version
    platform: navigator.platform, // OS platform
    languages: navigator.languages, // Array of user's preferred languages
    language: navigator.language, // User's preferred language
    online: navigator.onLine, // Whether the browser is online or offline

    // Screen Information
    screenWidth: window.screen.width, // Screen width
    screenHeight: window.screen.height, // Screen height
    availableScreenWidth: window.screen.availWidth, // Usable screen width (without toolbars, etc.)
    availableScreenHeight: window.screen.availHeight, // Usable screen height
    colorDepth: window.screen.colorDepth, // Screen color depth
    pixelDepth: window.screen.pixelDepth, // Pixel depth

    // Device Information
    deviceMemory: navigator.deviceMemory || "Unknown", // Approximate device memory in GB
    hardwareConcurrency: navigator.hardwareConcurrency || "Unknown", // Number of logical processors

    // Network Information
    connectionType: connection ? connection.effectiveType : "Unknown", // Network connection type (e.g., '4g', '3g')
    downlinkSpeed: connection ? connection.downlink : "Unknown", // Network downlink speed
    rtt: connection ? connection.rtt : "Unknown", // Round-trip time estimate in milliseconds
    saveData: connection ? connection.saveData : false, // Whether user has set data-saving mode

    // Battery Information (may not work on all devices)
    battery: navigator.getBattery
      ? navigator.getBattery().then((battery) => ({
          charging: battery.charging,
          level: battery.level * 100 + "%",
          chargingTime: battery.chargingTime,
          dischargingTime: battery.dischargingTime,
        }))
      : Promise.resolve("Battery info not available"),
  };
};

// Example usage:
gatherDeviceInfo().battery.then((batteryInfo) => {
  const deviceInfo = gatherDeviceInfo();
  deviceInfo.battery = batteryInfo;
  console.log(deviceInfo);
});
