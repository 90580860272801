import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";

const Spinner = ({ loading, size = 25, margin = 5 }) => {
  const [animationData, setAnimationData] = useState(null);

  // Fetch the Lottie animation from an external URL
  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(
          "https://lottie.host/79ca3dae-9f64-4d8f-bd4a-b72775176228/cnERsvQVZj.json"
        ); // Replace with your Lottie animation URL
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error("Error fetching Lottie animation:", error);
      }
    };

    fetchAnimationData();
  }, []);

  // If the animation data hasn't loaded yet, return null or a fallback
  if (!animationData) {
    return <div>Loading...</div>;
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: `${size}px`,
            width: `${size}px`,
            margin: `${margin}px`,
          }}
        >
          <Lottie options={defaultOptions} height={size} width={size} />
        </div>
      )}
    </>
  );
};

export default Spinner;
