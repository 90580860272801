import React from "react";
import ListView from "../listviews/ListView";
import TextField2 from "../textfields/TextField2";
import SecondaryButton from "../buttons/SecondaryButton";
import Row from "../layout/Row";
import Space from "../layout/Space";
import Divider from "../layout/Divider";

function MemberManager({ title, users, removable }) {
  return (
    <div>
      <div className="text-h1 font-semibold text-pr">{title}</div>
      <Divider orientation="horizontal" />
      <Space y={10} />
      <Row align="left" className="w-full">
        <TextField2 label="Email"></TextField2>
        <Space x={0} />
        <SecondaryButton>Inviter</SecondaryButton>
      </Row>
      <Space y={10} />
      <div className="w-c300">
        <ListView users={users} removable={removable}></ListView>
      </div>
    </div>
  );
}

export default MemberManager;
