import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";

function CheckBoxGrey({ label, checked, handleChange }) {
  const theme = useTheme();
  return (
    <Checkbox
      defaultChecked
      checked={checked}
      onChange={handleChange}
      sx={{
        margin: "0px",
        padding: "0px",
        "& .MuiSvgIcon-root": {
          borderRadius: "20px", // Apply border radius to the checkbox icon
          color: theme.palette.shadow._25,
          padding: "0px",
          margin: "0px",
        },
      }}
    />
  );
}

export default CheckBoxGrey;
