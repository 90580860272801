import React from "react";
import TextField from "@mui/material/TextField";

function TextFieldMicro({ label, sx, value, onChange, className }) {
  return (
    <TextField
      className={className}
      value={value}
      onChange={onChange}
      label={label}
      variant="outlined"
      color="primary"
      fullWidth // Makes the TextField take all available width
      sx={{
        "& .MuiInputLabel-root": {
          top: "-40%", // Position the label vertically centered
          //transform: "translateY(-50%)", // Vertically center the label
          left: "0px", // Adjust the left position if needed
          fontSize: "10px",
        },
        "& .MuiInputLabel-shrink": {
          top: "0%", // Keep the label on top when focused or typing
          // transform: "translateY(0)", // Reset transformation when label shrinks
          fontSize: "10px", // Adjust the label size when it shrinks
        },
        "& .MuiOutlinedInput-root": {
          height: "25px", // Adjust the height of the TextField
          borderRadius: "10px",
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px", // Adjust input padding as needed
        },
        ...sx, // Add custom styles if needed
      }}
      InputProps={{
        style: {
          fontSize: "10px",
        },
      }}
    />
  );
}

export default TextFieldMicro;
